import axios from "axios";
import { Message, Loading } from "tsa-ui";
import store from '../store'
import {set, get } from '@/utils/localStorage'


var baseURL;
if (process.env.NODE_ENV === 'development') {
    baseURL = '/api/api';
} else {
    baseURL = '/opus/api';
}
let loading;
let duration = 2 * 1000;

function startLoading() {
    loading = Loading.service({
        lock: true,
        text: "数据加载中...",
        background: "rgba(0,0,0,.7)"
    });
}

function stopLoading() {
    loading.close();
}
//取消重复接口
function removeCommonPending(config) {
    for(let i in store.state.axiosCancel.axiosPromiseCancel){
        if(store.state.axiosCancel.axiosPromiseCancel[i].u===config.url+'&'+config.method){
            store.commit('axiosCancel/clearCommonAxiosPromiseCancelArr', i);
        }
    }
}

export const ExcludeRequest400 = ['/opus/generateStsUploadSignMessage','/aigc'] // 排除的请求



// axios.defaults.headers = {}
axios.defaults.headers.get['Pragma'] = 'no-cache';
axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';

const service = axios.create({
    baseURL,
    timeout: 86400000,
    headers: {}
    // headers: {
    //     // 'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
    //     'content-type': "application/json;charset=UTF-8"
    // }
});


service.interceptors.request.use(
    config => {
        if (!config.stopLoading) {
            startLoading();
        }
        if(get("language") && get("language")==='en'){
            config.headers['Accept-Language']  = 'en-US'
        }else{
            config.headers['Accept-Language']  = 'zh-CN'
        }
        //取消当前重复未响应的接口
        removeCommonPending(config)
        // 发起请求时保存当前页面的所有请求
        config.cancelToken=new axios.CancelToken((cancel)=>{
            store.commit('axiosCancel/setAxiosPromiseCancelArr', {u:config.url+'&'+config.method,f:cancel});
        })
        return config;
    },
    error => {
        console.log(error); // for debug
        loading&&stopLoading();
        return Promise.reject(error);
    }
);


service.interceptors.response.use(
    response => {
        loading&&stopLoading();
        const { status, data } = response;
        if (response instanceof Blob) {
            return response;
        }
        if (status !== 200) {
            Message({
                message: data.message || "Error",
                type: "error",
                duration
            });
            return false;
        } else {
            return data
        }
    },
    error => {
        loading&&stopLoading();
        // 防止上一页的error在新页弹出
        if(axios.isCancel(error)){
            return new Promise(()=>{});
        }
        return Promise.reject(error.response);
    }
);

export default service;