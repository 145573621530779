export const footerContent={
    navList:[
        {
            name:'关于我们',
            childen:[
                {
                    name: '时间戳服务中心',
                    link: process.env.VUE_APP_TSAPLUS+'/about.html',
                    target: '_target',
                },
                // {
                //     name: '国家授时中心',
                //     link: process.env.VUE_APP_TSAPLUS+'/about/timeservice.html',
                //     target: '_target',
                // },
                {
                    name: '荣誉资质',
                    link: process.env.VUE_APP_TSAPLUS+'/about/honor.html',
                    target: '_target',
                },
                {
                    name: '联系我们',
                    link: process.env.VUE_APP_TSAPLUS+'/about/relate.html',
                    target: '_target',
                },
                {
                    name: '典型用户',
                    link: process.env.VUE_APP_TSAPLUS+'/about/typical.html',
                    target: '_target',
                },
                {
                    name: '商标声明',
                    link: process.env.VUE_APP_TSAPLUS+'/about/brand.html',
                    target: '_target',
                }
            ]
        },
        {
            name:'产品服务',
            childen:[
                {
                    name: '权利卫士',
                    link: process.env.VUE_APP_QLWS,
                    target: '_self',
                },
                {
                    name: '电子证据平台',
                    link: process.env.VUE_APP_EV,
                    target: '_self',
                },
                {
                    name: '知识产权',
                    link: process.env.VUE_APP_COPYRIGHT,
                    target: '_self',
                },
                {
                    name: '电子签约',
                    link: process.env.VUE_APP_SIGN,
                    target: '_blank',
                    nofollow: true
                },
                {
                    name: '邮件认证',
                    link: process.env.VUE_APP_EMAIL,
                    target: '_self',
                },
                {
                    name: 'AIGC-PAS',
                    link: process.env.VUE_APP_AIGC,
                    target: '_self',
                },
                {
                    name: '监测服务平台',
                    link: process.env.VUE_APP_MONITOR,
                    target: '_self',
                }
            ]
        },
        {
            name:'快速入口',
            childen:[
                {
                    name: '首页',
                    link: process.env.VUE_APP_TSAPLUS,
                    target: '_self',
                },
                {
                    name: '开放平台',
                    link: process.env.VUE_APP_TSAPLUS+'/openservice.html',
                    target: '_blank',
                },
                {
                    name: '验证中心',
                    link: process.env.VUE_APP_VERIFY,
                    target: '_blank',
                },
                {
                    name: '帮助中心',
                    link: process.env.VUE_APP_TSAPLUS+'/wiki.html',
                    target: '_blank',
                },
                {
                    // target: 'event',
                    // methods:'onLogin'
                    name: '立即取证',
                    target: '_blank',
                    link: '/api/login?redirectUrl='+process.env.VUE_APP_EV+'/vm_fetch',
                },
            ]
        },
        {
            name:'服务支持',
            childen:[
                {
                    name: '可信时间戳证书样例',
                    link: process.env.VUE_APP_TSAPLUS+'/sample.html',
                    target: '_blank',
                },
                {
                    name: '相关下载',
                    link: process.env.VUE_APP_TSAPLUS+'/download.html',
                    target: '_blank',
                },
                {
                    name: '帮助中心',
                    link: '/wiki.html',
                    target: '_blank',
                },
            ]
        },
    ],
    qrCodeList:[
        {
            show: true,
            qrCode: 'https://tsa-content.oss-cn-beijing.aliyuncs.com/rightsTV/download.jpg',
            icon: 'https://tsa-content.oss-cn-beijing.aliyuncs.com/rightsTV/downloadIcon.png',
            name: '权利卫士APP',
        },
        {
            show: false,
            qrCode: 'https://tsa-content.oss-cn-beijing.aliyuncs.com/weChatOfficialAccount/weChatOfficialAccount.png',
            icon: 'https://tsa-content.oss-cn-beijing.aliyuncs.com/weChatOfficialAccount/weChatOfficialAccountIcon.png',
            name: '微信公众号'
        },
        {
            show: false,
            qrCode: 'https://tsa-content.oss-cn-beijing.aliyuncs.com/customerService/home-footer.png',
            icon: 'https://tsa-content.oss-cn-beijing.aliyuncs.com/customerService/home-footer-icon.png',
            name: '客服微信'
        },
        {
            show: false,
            qrCode: 'https://tsa-content.oss-cn-beijing.aliyuncs.com/payCourses/payCourses.png',
            icon: 'https://tsa-content.oss-cn-beijing.aliyuncs.com/payCourses/payCoursesIcon.png',
            name: '付费课程',
        }
    ],
    agreementList:[
        {
            name: '用户注册协议',
            link: process.env.VUE_APP_USERCENTERUI+'/agreement/registration',
            target: '_blank',
            currentNav:true
        },
        {
            name: '隐私协议',
            link: process.env.VUE_APP_USERCENTERUI+'/agreement/privacy',
            target: '_blank',
        }
    ],
    friendsLinkList:[
        {
            show: true,
            tabTitle:'友情链接',
            linkList:[
                // {
                //     title:'国家授时中心',
                //     url:'http://www.ntsc.ac.cn/?LMCL=HcSri1'
                // },
                {
                    title:'北斗卫星导航',
                    url:'http://www.beidou.gov.cn/'
                },
                {
                    title:'北大法宝',
                    url:'https://www.pkulaw.com/'
                },
                {
                    title:'联合信任时间戳',
                    url:'https://www.tsa.cn'
                },
                {
                    title:'电子证据平台',
                    url:'https://ev.tsa.cn'
                },
                {
                    title:'邮件认证平台',
                    url:'https://mail.tsa.cn'
                },
                {
                    title:'监测服务平台',
                    url:'https://monitor.tsa.cn'
                },
                {
                    title:'软著保护平台',
                    url:'https://scp.tsa.cn'
                },
                {
                    title:'媒体版权固证平台',
                    url:'http://media.tsa.cn'
                },
                {
                    title:'时间戳验证平台',
                    url:'https://v.tsa.cn'
                },
                {
                    title:'联合信任时间戳移动端',
                    url:'https://m.tsa.cn'
                },
                {
                    title:'知识产权保护移动平台',
                    url:'https://ipr-m.tsa.cn'
                },
                {
                    title:'知识产权保护平台',
                    url:'https://ipr.tsa.cn'
                },
                {
                    title:'联合信任经销商',
                    url:'https://bd.tsa.cn'
                },
                {
                    title:'电子签名移动平台',
                    url:'http://1sign-m.tsa.cn'
                },
                {
                    title:'时间戳平台登录中心',
                    url:'https://usercenter.tsa.cn'
                },
                {
                    title:'电子取证知识',
                    url:'https://www.tsa.cn/baike-dianziquzheng.html'
                },
                {
                    title:'电子证据知识',
                    url:'https://www.tsa.cn/baike-dianzizhengju.html'
                },
                {
                    title:'知识产权保护知识',
                    url:'https://www.tsa.cn/baike-zhishichanquanbaohu.html'
                },
                {
                    title:'邮件认证知识',
                    url:'https://www.tsa.cn/baike-youjianrenzheng.html'
                },
                {
                    title:'电子合同认证知识',
                    url:'https://www.tsa.cn/baike-dianzihetong.html'
                },
                {
                    title:'侵权取证知识',
                    url:'https://www.tsa.cn/baike-qinquanquzheng.html'
                },
                {
                    title:'版权保护知识',
                    url:'https://www.tsa.cn/baike-banquanbaohu.html'
                },
                {
                    title:'著作权保护知识',
                    url:'https://www.tsa.cn/baike-zhuzuoquanbaohu.html'
                },
                {
                    title:'手机取证知识',
                    url:'https://www.tsa.cn/baike-shoujiquzheng.html'
                },
                {
                    title:'违法取证',
                    url:'https://www.tsa.cn/baike-weifaquzheng.html'
                }
            ]
        },
        {
            show: false,
            tabTitle:'精选服务',
            linkList:[
                {
                    title:'联合信任时间戳',
                    url:'https://www.tsa.cn'
                },
                {
                    title:'电子证据平台',
                    url:'https://ev.tsa.cn'
                },
                {
                    title:'邮件认证平台',
                    url:'https://mail.tsa.cn'
                },
                {
                    title:'软著保护平台',
                    url:'https://scp.tsa.cn'
                },
                {
                    title:'媒体版权固证平台',
                    url:'http://media.tsa.cn'
                },
                {
                    title:'时间戳验证平台',
                    url:'https://v.tsa.cn'
                },
                {
                    title:'联合信任时间戳移动端',
                    url:'https://m.tsa.cn'
                },
                {
                    title:'知识产权保护移动平台',
                    url:'https://ipr-m.tsa.cn'
                },
                {
                    title:'知识产权保护平台',
                    url:'https://ipr.tsa.cn'
                },
                {
                    title:'联合信任经销商',
                    url:'https://bd.tsa.cn'
                },
                {
                    title:'电子签名移动平台',
                    url:'http://1sign-m.tsa.cn'
                },
                {
                    title:'时间戳平台登录中心',
                    url:'https://usercenter.tsa.cn'
                },
            ]
        },
        {
            show: false,
            tabTitle:'最新知识',
            linkList:[
                {
                    title:'电子取证知识',
                    url:'https://www.tsa.cn/baike-dianziquzheng.html'
                },
                {
                    title:'电子证据知识',
                    url:'https://www.tsa.cn/baike-dianzizhengju.html'
                },
                {
                    title:'知识产权保护知识',
                    url:'https://www.tsa.cn/baike-zhishichanquanbaohu.html'
                },
                {
                    title:'邮件认证知识',
                    url:'https://www.tsa.cn/baike-youjianrenzheng.html'
                },
                {
                    title:'电子合同认证知识',
                    url:'https://www.tsa.cn/baike-dianzihetong.html'
                },
                {
                    title:'侵权取证知识',
                    url:'https://www.tsa.cn/baike-qinquanquzheng.html'
                },
                {
                    title:'版权保护知识',
                    url:'https://www.tsa.cn/baike-banquanbaohu.html'
                },
                {
                    title:'著作权保护知识',
                    url:'https://www.tsa.cn/baike-zhuzuoquanbaohu.html'
                },
                {
                    title:'手机取证知识',
                    url:'https://www.tsa.cn/baike-shoujiquzheng.html'
                },
                {
                    title:'违法取证',
                    url:'https://www.tsa.cn/baike-weifaquzheng.html'
                }
            ]
        }
    ]
}