import Vue from "vue";
import router from "@/router";
import store from "@/store";
import request from "@/api/index";
import { MessageBox } from 'tsa-ui';
import qs from 'qs'
import {set, get } from '@/utils/localStorage'
import i18n from '@/lang'

router.beforeEach((to, from, next) => {
    store.commit('axiosCancel/clearAxiosPromiseCancelArr')
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.matched.length === 0) {
        //如果未匹配到路由
        from.name ? next({ name: from.name }) : next('/');
        //如果上级也未匹配到路由则跳转登录页面，如果上级能匹配到则转上级路由
    }
    setTimeout(() => {
        let _hmt = _hmt || [];
        (() => {
            document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
            let hm = document.createElement('script');
            hm.src = "https://hm.baidu.com/hm.js?2427da48bd3bcc0b913373f46cff3df9";
            hm.id = 'baidu_tj';
            let s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(hm, s);
        })();
    }, 50);

    if (!window['__PRERENDER_INJECTED']) {
        zhuge.track('浏览知识产权保护平台');
    }

    //拿当前参数存入缓存
    let queryData = to.query;
    if(queryData.language && !(queryData.language=='null')){
        set("language", queryData.language);
    }
    if(!get("language")){
        set("language", 'zh');
    }
    i18n.locale=get('language')?get('language'):'zh';
    let obj = Object.keys(queryData)
    if (obj.length !== 0) {
        queryData = Object.assign({}, get('queryData'), queryData);
        set("queryData", queryData);
    }

    const getMe=()=>{
        request({
            method: 'get',
            url: '/me?hidden=true',
            stopLoading: true
        }).then((res) => {
            store.commit('userInfo/setMe', res);
            request({
                method: 'get',
                url: '/user',
                stopLoading: true
            }).then(async(res) => {
                if (res.forbidden) {
                    MessageBox.alert('您无权使用该服务，请与主账号管理员联系授权', '提示', {
                        confirmButtonText: '确定',
                        callback: () => {
                            window.location.href = process.env.VUE_APP_ACCOUNTUI
                        }
                    });
                    return false
                }
                store.commit('userInfo/setUserInfo', res);
                if (to.path == '/time/ApplyResult') {
                    let serialNos = sessionStorage.getItem("serialNos");
                    if (!serialNos) {
                        next({
                            path: '/time/ApplyTime',
                        });
                    }
                }
                next();
            })
        }).catch((err) => {
            let params={};
            if(to.path=='/time/Aigc'){
                params = Object.assign(to.query, { redirectUrl: encodeURIComponent(window.location.origin + '/time/Aigc?'+qs.stringify(to.query))})
            }else{
                params = Object.assign(to.query, { redirectUrl: encodeURIComponent(window.location.origin + '/time/ApplyTime?'+qs.stringify(to.query))})
            }
            localStorage.removeItem('queryData')
            if (to.query.channelCode !== undefined) {
                params.channelCode = to.query.channelCode ? to.query.channelCode  : 'bdipr'
            }
            if (process.env.NODE_ENV === 'development') {
                window.location.href = '/api/api/login?' + qs.stringify(params)
            } else {
                window.location.href = '/opus/api/login?' + qs.stringify(params)
            }
        })
    }
    

    const loginPath = [
        '/',
        '/sem',
    ];
    if (loginPath.indexOf(to.path) !== -1) {
        next();
    } else {
        getMe()
    }
})