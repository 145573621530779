const state = {
    userInfo: '',
    me:'',
}
const mutations = {
    setUserInfo(state, model) {
        state.userInfo = model
    },
    setMe(state, model) {
        state.me = model
    },
}
const actions = {

}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};