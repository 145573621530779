import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Index from "../views/layout/Index.vue";
Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "Home",
        meta: {
            title: '可信时间戳知识产权保护平台_联合信任时间戳服务中心',
        },
        component: Home,
    },
    {
        path: "/sem",
        name: "Home2",
        meta: {
            title: '可信时间戳知识产权保护平台_联合信任时间戳服务中心',
        },
        component: Home,
    },
    {
        path: "/time",
        name: "Index",
        component: Index,
        redirect:'/time/ApplyTime',
        children: [
            {
                path: 'ApplyTime',
                name: 'ApplyTime',
                meta: {
                    title: '申请时间戳',
                    titleEn:'Apply for timestamp',
                },
                component: () =>import ( /* webpackChunkName: "ApplyTime" */ '../views/time/ApplyTime.vue'),
            },
            {
                path: 'Aigc',
                name: 'Aigc',
                meta: {
                    title: '创作过程认证',
                },
                component: () =>import ( /* webpackChunkName: "Aigc" */'../views/time/Aigc.vue'),
            },
            {
                path: 'ApiAccess',
                name: 'ApiAccess',
                meta: {
                    title: '定制化API',
                },
                component: () =>import (/* webpackChunkName: "ApiAccess" */'../views/time/ApiAccess.vue'),
            },
            {
                path: 'ApplyResult',
                name: 'ApplyResult',
                meta: {
                    title: '申请结果',
                    titleEn:'Result of the application'
                },
                component: () =>import (/* webpackChunkName: "ApplyResult" */'../views/time/ApplyResult.vue'),
            },
            {
                path: 'AigcResult',
                name: 'AigcResult',
                meta: {
                    title: '申请结果',
                    titleEn:'Result of the application'
                },
                component: () =>import (/* webpackChunkName: "AigcResult" */'../views/time/AigcResult.vue'),
            },
            {
                path: 'ProtectFile',
                name: 'ProtectFile',
                meta: {
                    title: '已保护文件',
                    titleEn:'My protected files',
                },
                component: () =>import (/* webpackChunkName: "ProtectFile" */'../views/time/ProtectFile.vue'),
            },
            {
                path: 'FileDetail',
                name: 'FileDetail',
                meta: {
                    title: '文件详情',
                    titleEn:'File details'
                },
                component: () =>import (/* webpackChunkName: "FileDetail" */'../views/time/FileDetail.vue'),
            },
            {
                path: 'Rules',
                name: 'Rules',
                meta: {
                    title: '计费规则',
                    titleEn:'Billing rules',
                },
                component: () =>import (/* webpackChunkName: "Rules" */'../views/time/Rules.vue'),
            },
        ],
    },
];
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    console.log(targetPath);
    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }
});

export default router;