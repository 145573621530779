<template>
    <el-aside class="my-aside">
        <el-scrollbar class="el-scrollbar" ref="scrollbar">
            <TsaSlideMenu 
                v-if="flag" 
                ref="TsaSlideMenu" 
                :navList="navList" 
                :moreList="moreList" 
                :timeStampShow="false" 
                logo="https://tsa-content.oss-cn-beijing.aliyuncs.com/logo/ipr_whole_dark_logo.png"
            >
                <div slot="timeStampInfo" :class="$getlocalStorage('language')==='en'?'service-num service-num-en':'service-num'">
                    <p v-if="userInfo.userType=='AUTHORIZATION_ACCOUNT'">
                        {{ $t('sidebar.accountBalance') }}
                        <i>：</i>
                        <b>{{ balance.tsCount }}</b>
                    </p>
                    <p v-else>
                        {{ $t('sidebar.balance') }}
                        <i>：</i>
                        <b>{{ balance.tsCount }}</b>
                    </p>
                    <template v-if="userInfo.userType=='AUTHORIZATION_ACCOUNT'">
                        <template v-if="balance.consumeLimitTsCount!==-1">
                            <p>
                                {{ $t('sidebar.ceiling') }}
                                <i>：</i>
                                <b>{{ balance.consumeLimitTsCount }}</b>
                            </p>
                            <p>
                                {{ $t('sidebar.alreadyUse') }}
                                <i>：</i>
                                <b>{{ balance.currentConsumeTsCount }}</b>
                            </p>
                        </template>
                    </template>
                    <p class="buy" v-if="userInfo.userType!=='AUTHORIZATION_ACCOUNT'">
                        <span class="gobuy" @click="gobuy">{{ $t('sidebar.gobuy') }}</span>
                    </p>
                    <p class="rules" @click="goRules"><a>{{ $t('sidebar.rules') }}</a></p>
                </div>
            </TsaSlideMenu>
        </el-scrollbar>
    </el-aside>
</template>

<script>
import {findBalance} from "@/api/service";
export default {
    data () {
        return {
            flag:true,
        }
    },
    components: {},
    watch:{
        '$i18n.locale' (val){
            this.flag = false;
            this.$nextTick(()=>{
                this.flag = true;
            })
        }
    },
    computed: {
        balance() {
            return this.$store.state.balance.balance;
        },
        userInfo(){
            return this.$store.state.userInfo.userInfo;
        },
        navList(){
            if( this.$i18n.locale==='en' || this.$getlocalStorage('language')==='en'){
                return [
                    {
                        icon: 'tsa-icon-wangyequzheng',
                        title: 'Apply for timestamp',
                        color: '#545454',
                        link: '/time/ApplyTime',
                    },
                    {
                        icon: 'el-icon-document',
                        color: '#545454',
                        title: 'My protected files',
                        link: '/time/ProtectFile',
                    },
                    {
                        icon: 'tsa-icon-kexinshijianchuoyanzheng',
                        color: '#545454',
                        title: 'Verify Center',
                        onClick: function () {
                            window.open(process.env.VUE_APP_VERIFY)
                        }
                    },
                ]
            }else{
                return [
                    {
                        icon: 'tsa-icon-wangyequzheng',
                        title: '申请时间戳',
                        color: '#545454',
                        link: '/time/ApplyTime',
                    },
                    {
                        icon: 'tsa-icon-lupingquzheng',
                        title: 'AIGC溯源与认证',
                        color: '#545454',
                        children: [
                            {
                                title: '创作过程认证',
                                icon: 'tsa-icon-piliangwangyequzheng',
                                link: '/time/Aigc'
                            },
                            {
                                title: '定制化API',
                                icon: 'tsa-icon-danwangyequzheng',
                                link: '/time/ApiAccess'
                            }
                        ]
                    },
                    {
                        icon: 'el-icon-document',
                        color: '#545454',
                        title: '已保护文件',
                        link: '/time/ProtectFile',
                    },
                    {
                        icon: 'tsa-icon-kexinshijianchuoyanzheng',
                        color: '#545454',
                        title: '可信时间戳验证',
                        onClick: function () {
                            window.open(process.env.VUE_APP_VERIFY)
                        }
                    },
                ]
            }
        },
        moreList(){
            if( this.$i18n.locale==='en' || this.$getlocalStorage('language')==='en'){
                return [
                    {
                        icon: 'tsa-icon-monitor',
                        title: 'Monitoring service',
                        link: process.env.VUE_APP_MONITOR,
                    },
                    {
                        icon: 'tsa-icon-diianzizhengju',
                        title: 'Electronic evidence',
                        link: process.env.VUE_APP_EV + '/vm_fetch',
                    },
                    {
                        icon: 'tsa-icon-dianziyoujianrenzheng',
                        title: 'Mail authentication',
                        link: process.env.VUE_APP_EMAIL + '/emailProof/witnessEmail',
                    },
                    {
                        icon: 'tsa-icon-dianziqianming',
                        title: 'Electronic signing',
                        link: process.env.VUE_APP_SIGN_CONTRACT,
                    },
                ]
            }else{
                return [
                    {
                        icon: 'tsa-icon-monitor',
                        title: '监测服务平台',
                        link: process.env.VUE_APP_MONITOR,
                    },
                    {
                        icon: 'tsa-icon-diianzizhengju',
                        title: '电子证据平台',
                        link: process.env.VUE_APP_EV + '/vm_fetch',
                    },
                    {
                        icon: 'tsa-icon-dianziyoujianrenzheng',
                        title: '电子邮件认证',
                        link: process.env.VUE_APP_EMAIL + '/emailProof/witnessEmail',
                    },
                    {
                        icon: 'tsa-icon-dianziqianming',
                        title: '电子签名',
                        link: process.env.VUE_APP_SIGN_CONTRACT,
                    },
                ]
            }
        }
    },
    created() {},
    mounted() {},
    methods: {
        gobuy() {
            window.open(process.env.VUE_APP_ACCOUNTUI+'/userAccount/buy?sourceType=952310&clientId=952310&partnerType=' + (this.$getlocalStorage('queryData')?.partnerType == 1 ? 1 : 0))
            this.$alert(this.$t('haveRecharged'), this.$t('timestampText.tipsText'), {
                confirmButtonText: this.$t('haveRechargedBtn'),
                callback: async (action) => {
                    let balance = await findBalance(0);
                    this.$store.commit('balance/setBalance', balance);
                }
            });
        },
        goRules(){
            this.$refs.TsaSlideMenu.update('/time/Rules')
            this.$router.push({path:'/time/Rules'})
        }
    },
}

</script>
<style scoped lang='less'>
.my-aside{
    &::-webkit-scrollbar {
        display: none;
    }
    /deep/.tsa-sildeMenu .tsa-sildeMenu-content .menu-container .menu .menu-item .title{
        .tsa-icon-arrow-up{
            margin-left: 0 !important;
        }
    }
    .service-num{
        p{
            font-size: 12px;
            color: #999;
            margin-bottom: 8px;
            b{
                font-size: 18px;
                color: #666;
                font-weight: bold;
            }
            i{
                font-size: 12px;
                font-style: normal;
            }
            .gobuy{
                display: inline-block;
                width: 90px;
                height: 26px;
                line-height: 26px;
                text-align: center;
                background: #fff;
                border-radius: 6px 6px 6px 6px;
                font-size: 14px;
                color: #8b9eff;
                cursor: pointer;
                &:hover {
                    background-color: #8B9EFF;
                    color: #FFFFFF;
                }
            }
        }
        .rules,.buy{
            text-align: center;
            margin-top:15px;
        }
        .rules{
            a:hover{
                color: #8B9EFF;
            }
        }
    }
    .service-num-en{
        p{
            text-align: center;
            i{
                display: none;
            }
            b{
                margin-top: 8px;
                display: block;
            }
        }
    }
    /deep/.tsa-sildeMenu {
        .tsa-sildeMenu-content {
            .menu-container {
                .menu {
                    .menu-item {
                        .content-child {
                            .sub-title {
                                p {
                                    color: #666666!important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .other-module {
            .other-module-content {
                .item  {
                    p,.iconfont {
                        color: #666666!important;
                    }
                }
            }
        }
    }
}
</style>