export default{
    header:{
        login:'登录 / 注册',
        nav:{
            home:'首页',
            prodect:'产品服务',
            ev:'电子取证',
            ipr:'知识产权',
            mail:'邮件认证',
            aigc:'AIGC PAS',
            monitor: '监测服务平台',
            sign:'电子签约',
            qlws:'权利卫士APP',
            timeStamp:'时间戳公共服务',
            open:'开放平台',
            interface1:'可信时间戳®接口',
            interface2:'电子证据取证接口',
            interface3:'知识产权保护接口',
            interface4:'电子签名接口',
            interface5:'PDF专用接口',
            interface6:'可信区块链接口',
            verify:'验证中心',
            help:'帮助中心',
            guide:'操作指引',
            cases:'典型案例',
            qusetion:'常见问题',
            aboutUs:'关于我们',
            timeStampCenter:'时间戳服务中心',
            // timeServiceCentre:'国家授时中心',
            timeServiceCentre: '',
            honer:'荣誉资质',
            contact:'联系我们',
            user:'典型用户',
            trademark:'商标声明',
        },
        newNav:{
            qlws:'权利卫士APP',
            ev:'电子取证',
            ipr:'知识产权保护',
            footerIpr:'知识产权',
            mail:'邮件认证',
            sign:'电子签约',
        },
        lawer:'找律师',
        userMenu:{
            usercenter:'个人中心',
            recharge:'立即充值',
            logOut:'退出登录',
            msg:'消息中心'
        },
    },
    banner:{
        sec0:{
            title: "可信时间戳<sup>®</sup> ·  数据知识产权存证",
            text1:'《北京市数据知识产权登记管理办法（试行）》\n',
            text2: "《浙江省数据知识产权登记办法（试行）》\n",
            slogan1:'为数据提供知识产权存证服务',
            slogan2:'满足数据资产登记确权要求',
            btnInfo: "立即存证",
        },
        sec1:{
            title: "可信时间戳<sup>®</sup> ·  保护商业秘密和知识产权",
            text1:'商业秘密和知识产权都是企业的核心竞争力\n',
            text2: "对研发、生产、经营过程中的各类数据、文件及时申请可信时间戳<sup>®</sup>\n",
            text3: "安全、保密、快捷地获得权属证明",
            text4: "用法律保护您的合法权益",
            btnInfo: "立即保护",
        },
        sec2:{
            title: "可信时间戳<sup>®</sup> ·  保护原创者的每个创意和作品",
            text1:'所有创作都是有价值的\n',
            text2: "从创意开始到作品完成，随时申请可信时间戳<sup>®</sup>认证，获得司法认可的原创证明\n",
            text3: "用法律保护您的智慧成果",
            btnInfo: "立即保护",
        }
    },
    display:{
        sec1:{
            title:"司法认可",
            info: "使用可信时间戳生效判决80000件",
        },
        sec2:{
            title: "世界知识产权组织、国家版权局 版权金奖",
            info: "荣获2018年“中国版权金奖”保护奖",
        },
        sec3:{
            title: "安全保密",
            info: "公安等保三级，脱敏认证不上传原文",
        }
    },
    iprWork:{
        btnInfo: "立即保护",
        iprWorkInfo:{
            title:'可信时间戳<sup>®</sup>能做什么',
            listInfo:{
                sec1:{
                    leftTitle: "商业秘密保护",
                    leftInfo:"商业秘密是企业的核心竞争力,直接关系到企业的生存发展与竞争优势。",
                    rightTitle:"因企业员工泄密或他人以窃取、利诱、胁迫等不正当手段获取企业商业秘密的行为屡屡发生，其有着隐蔽性强、证据搜集难、诉讼难度大等特点。可信时间戳<sup>®</sup>知识产权保护平台从企业商业秘密的诞生之时起即可对其进行脱敏认证，不用将文件上传到平台，最大限度的保护企业商业秘密的隐私性与安全性。一旦商业秘密被侵犯，能做到维权有据，保护企业的财产与声誉不被侵害。",
                    rightList1: {
                        list1:'软件程序',
                        list2:'设计方案',
                        list3:'设计图',
                        list4:'模型',
                        list5:'产品配方',
                        list6:'工艺流程',
                        list7:'设计样板',
                        list8:'电子数据',
                        list9:'测试记录',
                        list10:'采购技术信息',
                        list11:'采购型号',
                        list12:'技术参数',
                        list13:'定制价格',
                        list14:'涉密生产设备',
                        list15:'仪器',
                        list16:'模具',
                        title: "技术类商业秘密",
                    },
                    rightList2: {
                        list1:'管理文件',
                        list2:'决策信息',
                        list3:'管理方法',
                        list4:'研发信息',
                        list5:'招标书',
                        list6:'合同内容',
                        list7:'客户信息',
                        list8:'供应商名单',
                        list9:'销售信息',
                        list10:'营销策划方案',
                        list11:'营销手册',
                        list12:'物流信息',
                        list13:'财务报表',
                        list14:'财务分析',
                        list15:'工资信息',
                        title: "经营类商业秘密",
                    },
                    btnInfo: "立即保护",
                },
                sec2:{
                    leftTitle: "著作权（版权）保护",
                    leftInfo: "从创意开始，所有创作都是有价值的。",
                    rightTitle:"凭什么说一个创意或作品是你创作的？如何证明是你最先想到的？一旦作品被侵权你又如何证明作品的权属与创作时间呢？现在，立即申请可信时间戳<sup>®</sup>认证，为您的作品申请一张“电子出生证”，同时获得司法认可的权属证明，有效保护您的知识产权！",
                    rightList1: {
                        list1:'美术作品',
                        list2:'文字作品',
                        list3:'模型作品',
                        list4:'摄影作品',
                        list5:'软件应用',
                        list6:'设计图',
                        list7:'音乐作品',
                        list8:'视频作品',
                        list9:'游戏作品',
                        list10:'研究成果',
                        list11:'学术论文',
                        list12:'口述作品',
                        title: "应用场景",
                    },
                    btnInfo: "立即保护",
                },
                sec3:{
                    leftTitle: "商标/专利保护",
                    leftInfo: "保护商标就是保护企业信誉，保护专利就是保护企业财产。",
                    rightTitle:"<span style='color: #3f7df7'>商标</span>是一家公司最重要的无形资产，是企业信誉与商业价值的体现。一旦遭遇侵权，如何进行证据保全？仅凭一张商标注册证就够了吗？现在，您可以将商标的创意、设计理念、管理、使用情况、再到其影响力申请可信时间戳<sup>®</sup>认证，获得司法认可的在先性证明，全方位保护好企业品牌的市场竞争优势。",
                    rightTitle2:"<span style='color: #3f7df7'>专利</span>制度的核心是“公开换保护”，但是专利公开前又如何保护呢？一旦被“自己人”泄密或者他人窃取，很可能就丧失了专利的新颖性，甚至被拿去抢先申请，将给企业造成无法估量的损失。在专利立项之初，就需要对专利文档、设计方案、模型、设计图、技术参数等信息申请可信时间戳<sup>®</sup>认证，获得一个专利“诞生“全过程的在先性证明，有效保障了企业的合法权益。",
                    rightList1: {
                        list1:'商标设计',
                        list2:'商标管理',
                        list3:'注册情况',
                        list4:'使用情况',
                        list5:'维护情况',
                        list6:'商标影响力',
                        title: "应用场景",
                    },
                    rightList2: {
                        list1:'专利文档',
                        list2:'设计图',
                        list3:'模型',
                        list4:'设计方案',
                        list5:'技术参数',
                        list6:'测试记录',
                        list7:'论文',
                        title: "应用场景",
                    },
                    btnInfo: "立即保护",
                },
                sec4:{
                    leftTitle: "数据知识产权存证",
                    leftInfo: "为数据提供知识产权存证服务、满足数据资产登记确权要求。",
                    rightTitle:"根据《中共中央国务院关于构建数据基础制度更好发挥数据要素作用的意见》《北京市数据知识产权登记管理办法（试行）》《浙江省数据知识产权登记办法（试行）》等文件要求， 数据知识产权登记前需要对数据进行存证，否则不予登记。<br>可信时间戳为数据持有者提供快捷、有效的第三方数据知识产权存证服务， 能满足数据知识产权登记平台、交易平台对数据存证的要求，更好的发挥数据要素的作用。",
                    rightList1: {
                        list1:'交易数据',
                        list2:'行为数据',
                        list3:'内容数据',
                        list4:'身份信息数据',
                        // list5:'电子档案',
                        // list6:'云服务',
                        // list8:'BI系统',
                        // list9:'游戏装备',
                        // list10:'NFT数字藏品',
                        title: "应用场景",
                    },
                    btnInfo: "立即存证",
                },
            }
        }
    },
    solutionInfo:{
        title:'定制化解决方案',
        infoBtn: "获取方案",
        solutionList:{
            sec1:{
                title: "互联网科技行业",
                infoTitleList:{
                    list1:'商业秘密',
                    list2:'原画设计',
                    list3:'产品设计',
                    list4:'商业策划案',
                    list5:'广告方案',
                },
                infoMain:"随着中国互联网企业间的竞争日趋激烈，员工跳槽到竞品公司的现象已成为常态。有数据显示，在涉及商业秘密的案件中，有80%来自",
                infoMain1: "于员工泄密。",
                infoMain2:"如何有效保护企业的商业秘密，防止离职员工泄密或对手恶性竞争，已成各互联网企业都需要认真面对的重要课题。企业可以使用可信",
                infoMain3:"时间戳<sup>®</sup>知识产权保护平台，从商业秘密诞生之初就开始对文件进行确权。即使被员工泄密，也能拿出权属清晰、司法认可的证据，将",
                infoMain4: " 泄密损失降到最低。",
            },
            sec2:{
                title: "软件行业",
                infoTitleList:{
                    list1:'程序代码',
                    list2:'UI设计图',
                    list3:'需求说明书',
                    list4:'测试用例',
                    list5:'产品说明书',
                    list6:'数据库',
                    list7:'软件流程图',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"软件产品是企业重要的核心竞争力和智力资产。企业往往投入大量资金进行开发，但这些具有自主知识产权的数字资产长期存在着",
                infoMain1: "内部员工泄密、竞争对手抄袭等给企业带来的不法侵害，企业在维权过程中经常因为原始证据不足导致维权失败，给企业带来巨大",
                infoMain2:"损失。",
                infoMain3:"可信时间戳<sup>®</sup>可有效解决软件行业确权难、举证难的问题，实现对软件产品知识产权的实质性保护。",
            },
            sec3:{
                title: "科研机构",
                infoTitleList:{
                    list1:'理论研究成果',
                    list2:'技术研究成果',
                    list3:'发明专利',
                    list4:'技术秘密',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"科研机构是国家创新体系的重要组成部分，是科技创新成果产出的重要源头。随着我国创新能力不断提升，科研机构普遍储备了",
                infoMain1: "大量专利等知识产权。",
                infoMain2:"可信时间戳<sup>®</sup>可对科研机构及实验室科研成果进行全方位保护，避免付出多年心血的创新成果被他人免费使用。",
            },
            sec4:{
                title: "快消行业",
                infoTitleList:{
                    list1:'商业秘密',
                    list2:'商标',
                    list3:'设计图',
                    list4:'外观设计专利',
                    list5:'广告宣传',
                    list6:'说明书',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"随着中国消费者消费意愿的增强，消费升级加快，同品类的消费品竞争也日益激烈。山寨品牌泛滥，制假售假、傍品牌等侵权不法行为频",
                infoMain1:"发，严重侵害了企业的声誉及合法权益。",
                infoMain2:"使用可信时间戳<sup>®</sup>知识产权保护平台，可对消费商品的设计、制造、宣传、销售等全流程进行保护，为企业挽回了经济损失，减少了维权",
                infoMain3: "成本。",
            },
            sec5:{
                title: "文化娱乐业",
                infoTitleList:{
                    list1:'视频直播',
                    list2:'影视创作',
                    list3:'剧本',
                    list4:'小说',
                    list5:'文创IP',
                    list6:'歌曲',
                    list7:'商标',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"2022年，随着短视频/直播领域的爆发式增长，文化娱乐业已成为世界第二大经济体。同时，文娱行业的知识产权法律纠纷也频 ",
                infoMain1:"频发生，如视频盗播、直播侵权、剧本抄袭、盗版山寨文创产品及IP、设计侵权等行为，更有某电影因侵权七个字判赔十四万的",
                infoMain2:"典型案例。",
                infoMain3:"您可以使用可信时间戳<sup>®</sup>，以著作权及商业秘密为核心，对剧本、视频创作、文创IP能内容进行在先性证明，构建完善的知识产权",
                infoMain4: "保护体系，有效提升企业的商业价值与竞争力。",
            },
            sec6:{
                title: "生物科技业",
                infoTitleList:{
                    list1:'商标',
                    list2:'专利',
                    list3:'商业秘密',
                    list4:'研究成果',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"2022年，国家发改委印发的《“十四五”生物经济发展规划》提出，要加强知识产权保护，培育高价值专利，促进知识产权价值实现与",
                infoMain1: "科技成果的转化实施等。",
                infoMain3:"一个企业的研发机构是创新的灵魂，当有技术成果产生时，使用可信时间戳<sup>®</sup>立即对成果进行保护，当产品走向市场后，能有效及时",
                infoMain4: "制止可能的侵权行为或者进行维权，维护企业的合法权益。",
            },
            sec7:{
                title: "制造业",
                infoTitleList:{
                    list1:'商业秘密',
                    list2:'研究成果',
                    list3:'发明专利',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"“十三五”期间，工业和信息化部会同国家知识产权局出台了《制造业知识产权行动计划》，重点强调了制造业要从知识产权创造、保护、运",
                infoMain1:"用等方面进行部署和推动。",
                infoMain2:"制造业中产生的技术秘密通常具有实用性，能为权利人带来直接利益，一旦被窃取将造成重大损失。可信时间戳<sup>®</sup>从源头保护制造业的技术",
                infoMain3:"秘密与发明专利，真正做到发明有证据，维权有依据，全面提升企业的知识产权保护的意识与能力。",
            },
            sec8:{
                title: "汽车行业",
                infoTitleList:{
                    list1:'商业秘密',
                    list2:'专利设计',
                    list3:'商标',
                    list4:'数字资产',
                    list5:'电路设计图',
                    list6:'原型设计',
                    list7:'操作手册',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"创新是汽车行业的关键驱动力，同时也是典型的知识产权密集型产业。随着互联网等科技巨头纷纷入局汽车行业，无人驾驶技术、芯片制造、",
                infoMain1:"电池技术、安全驾乘等技术不断的升级，汽车行业正经历着前所未有的发展盛况与挑战。与此同时，很多车企却因为离职员工泄密或竞争对手",
                infoMain2:" 不正当竞争带来了巨大损失。",
                infoMain3:"可信时间戳<sup>®</sup>可以帮助各大车企将商业秘密，专利设计，商标以及数字资产等进行及时有效的确权，有力的保障车企的合法权益不被侵犯。",
            },
            sec9:{
                title: "游戏行业",
                infoTitleList:{
                    list1:'故事情节',
                    list2:'游戏代码',
                    list3:'美术UI',
                    list4:'视频动画',
                    list5:'关卡设计',
                    list6:'软件代码',
                    list7:'商业秘密',
                    list8:'商标',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"近年来伴随着我国游戏产业的蓬勃发展，与之密切相关的知识产权问题也层出不穷。游戏行业一直是侵权盗版现象频发的重灾区。游戏中的  ",
                infoMain1:"各种元素，如游戏标识、故事情节、人物角色、视频动画、美术UI、关卡设计、软件代码等涉及到著作权、商标权、专利权、商业秘密权等",
                infoMain2:"多种知识产权。由于游戏中包含元素的特殊性，企业遭遇侵权后往往会面对权利证据归属不清，维权成本高等问题。",
                infoMain3:"可信时间戳<sup>®</sup>可从一个游戏的想法和创意开始，对游戏的整个设计和开发过程进行保护，在面对侵权行为时，可以对一个游戏诞生的全过",
                infoMain4: "程进行举证，有效的维护自身的合法权益。",
            },
            sec10:{
                title: "餐饮业",
                infoTitleList:{
                    list1:'商标',
                    list2:'外观专利',
                    list3:'配方工艺',
                    list4:'产品设计',
                    list5:'广告宣传',
                },
                infoTitle:"商业秘密，原画设计，产品设计，商业策划案，广告方案",
                infoMain:"一直以来，餐饮业侵权案件屡见不鲜，旷日持久的“凉茶大战”，近些年的“掉渣烧饼”纠纷，再到最近的“胡辣汤”之争。商标冒用、配方 ",
                infoMain1:"抄袭、山寨店铺等一系列的侵权行为让各餐饮企业头疼不已。",
                infoMain2:"如何能有效打击山寨仿冒，挽回企业应得的利益？使用可信时间戳<sup>®</sup>可对餐饮企业的注册商标，配方工艺，外观专利等进行保护，即时",
                infoMain3:"获得在先性证明，遇到侵权，使用高效的利器维护自己的合法权益。",
            },
        }
    },
    principleTitle:'可信时间戳<sup>®</sup>知识产权保护平台工作原理',
    videoPlay:'查看视频',
    videoPlay1:'平台介绍',
    timestampCase: {
        title: '时间戳典型判例'
    },
    commonProblems: {
        title: '常见问题'
    },
    trait:{
        title:'平台特点',
        item1:{
            title:'司法认可',
            des:{
                node1:'生效判例{precedentTotal}件',
                node2:'（来源：中国裁判文书网）'
            }
        },
        item2:{
            title:'国家金奖',
            des:{
                node1:'中国版权金奖-保护奖',
                node2:'国家版权局 世界知识产权组织'
            }
        },
        item3:{
            title:'便捷高效',
            des:{
                node1:'支持电脑端、手机端',
                node2:'API接口申请认证'
            }
        },
        item4:{
            title:'安全保密',
            des:{
                node1:'作品、数据可不上传',
                node2:'脱敏认证，隐私安全'
            }
        }
    },
    partner:{
        title:'典型客户与合作伙伴'
    },
    footer:{
        address1:'北京市朝阳区酒仙桥北路甲10号',
        address2:'电子城IT产业园204号楼2层',
        sec1:'快速入口',
        sec2:'服务支持',
        sec3:'相关声明',
        login:'立即登录',
        question:'热门问题',
        show:'操作演示',
        sample:'可信时间戳证书样例',
        download:'相关下载',
        regDoc:'注册协议',
        privacyDoc:'隐私协议',
        trademark:'注册商标',
        qlws:'权利卫士客户端',
        publicNumber:'微信公众号',
        wechat:'客服微信',
        course:'付费课程',
        linkTitle:'友情链接：',
        // link1:'国家授时中心',
        link1: '',
        link2:'北斗卫星导航',
        link3:'北大法宝',
        link4:'Veer',
        evidence:'立即取证',
        textFooter:{
            node1:'版权所有：北京联合信任技术服务有限公司',
            node2:'京ICP备13046264号-2',
            node3:'京公网安备11010502045493号',
            node4:'Copyright © 2005- {currentYear} 联合信任',
        }
    }

}