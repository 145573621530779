import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { get } from "@/utils/localStorage";
import enLocale from 'tsa-ui/lib/locale/lang/en'; //导入element ui中的默认英文配置
import zhLocale from 'tsa-ui/lib/locale/lang/zh-CN';//导入element ui中的默认中文配置
import locale from 'tsa-ui/lib/locale';
import en from './en/en';
import zh from './zh/zh';
import zhIndex from './zh/index';
import enIndex from './en/index';
import zhFile from './zh/zhFile';
import enFile from './en/enFile';
import enApplyTime from './en/enApplyTime';
import zhApplyTime from './zh/zhApplyTime';
Vue.use(VueI18n)

const messages = {
  en: {
    message: 'hello',
    ...enLocale,
    ...en,
    ...enIndex,
    ...enFile,
    ...enApplyTime,
  },
  zh: {
    message: '你好',
    ...zhLocale,
    ...zh,
    ...zhIndex,
    ...zhFile,
    ...zhApplyTime,
  }
}
const i18n = new VueI18n({
  locale: get("language")? get("language") : "zh",
  silentFallbackWarn:true,
  messages, // set locale messages
})

locale.i18n((key, value) => i18n.t(key, value))
export default i18n 