<template>
    <div class="footer-wrap">
        <div :class="$i18n.locale==='en'?'en-footer footer':'footer'">
            <div class="top">
                <div :class="$i18n.locale==='en'?'en-logo logo':'logo'">
                    <h2>
                        <img class="lazy" src="./images/logo2.png" alt="联合信任">
                    </h2>
                    <p class="address">
                        {{$t('footer.address1')}}{{$t('footer.address2')}}
                        <!-- 北京市朝阳区酒仙桥北路甲10号电子城IT产业园204号楼2层 -->
                    </p>
                </div>
                <div class="link-map ">
                    <ul>
                        <li v-for="item in footerCnt.navList" :key="item.name" :class="{ linkEn: $i18n.locale==='en'}">
                            <a class="title" :title="item.name" style="cursor: auto;"><strong>{{ item.name }}</strong></a>
                            <template v-for="nav in item.childen">
                                <a href="javascript:;" :title="nav.name" :key="nav.name" @click="getMethodName(nav.methods)" v-if="nav.target=='event'">
                                    {{ nav.name }}
                                </a>
                                <template v-else>
                                    <a :href="nav.link" v-if="nav.nofollow" rel="nofollow" :title="nav.name" :key="nav.name+'1'" :target="nav.target">
                                        {{ nav.name }}
                                    </a>
                                    <a :href="nav.link" v-else :title="nav.name" :key="nav.name+'1'" :target="nav.target">
                                        {{ nav.name }}
                                    </a>
                                </template>
                                
                            </template>
                        </li>
                    </ul>
                </div>
                <div class="qrcodes">
                    <ul>
                        <li v-for="item in footerCnt.qrCodeList" :key="item.name" @mouseover="mouseover(item)" :class="item.show?'active':''">
                            <img class="lazy" :src="item.icon" :alt="item.name">
                            <strong>{{ item.name }}</strong>
                            <div class="qrcode-container" v-show="item.show">
                                <img class="lazy" :src="item.qrCode" :alt="item.name"> 
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="bot">              
                <!-- <div class="friendship">
                    {{ $t('footer.linkTitle') }}：
                        <p>
                            <a href="http://www.ntsc.ac.cn/?LMCL=HcSri1" target="_blank">{{$t('footer.link1')}}</a>
                            <span> |</span>
                        </p>
                        <p>
                            <a href="http://www.beidou.gov.cn/" target="_blank">{{$t('footer.link2')}}</a>
                            <span> |</span>
                        </p>
                        <p>
                            <a href="https://www.pkulaw.com/" target="_blank">{{$t('footer.link3')}}</a>
                        </p>
                </div>                 -->
                <ul class="link-tab">
                    <li :class="item.show?'active':''" v-for="item in footerContent.friendsLinkList" :key="item.tabTitle" @click="changeLinkTab(item)">{{item.tabTitle}}</li>
                </ul>
                <div class="link-content">
                    <div :class="item.show?'friendship active':'friendship'" v-for="item in footerContent.friendsLinkList" :key="item.title">
                        <p v-for="linkItem in item.linkList" :key="linkItem.title">
                            <a :href="linkItem.url" target="_blank">{{ linkItem.title }}</a>
                        </p>
                    </div>
                </div>


                <div class="copyright-container">
                    <div class="infor">
                        <p>{{$t('footer.textFooter.node1')}}</p>
                        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{$t('footer.textFooter.node2')}}</a>
                        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502045493" target="_blank">{{$t('footer.textFooter.node3')}}</a>
                        <p>Copyright 2005-<span class="current-year">{{ currentYear }}</span> 联合信任 版权所有</p>
                        <a v-for="item in footerCnt.agreementList" :key="item.name" :href="item.link" :target="item.target">{{ item.name }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { footerContent } from './config/footerContent'
export default {
    props: {
        currentTime:{
            type: Number,
            default:new Date().getTime(),
        },
        footerContent: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data () {
        return {
            
        }
    },
    components: {},
    watch: {
        footerContent: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                this.footerCnt=newVal;
                console.log(newVal)
            }
        }
    },
    computed: {
        currentYear(){
            let dateTime=this.currentTime;
            return new Date(dateTime).getFullYear()
        },
        footerCnt:{
            get(){
                if(JSON.stringify(this.footerContent) == "{}"){
                    return footerContent;
                }else{
                    return this.footerContent;
                }
            },
			set(value){} 
        } 
    },
    created() {
    },
    mounted() {},
    methods: {
        mouseover (item) {
            this.footerCnt.qrCodeList.map(item => {
                item.show = false
            })
            item.show = true
            this.$forceUpdate();
        },
        changeLinkTab(item){
            this.footerCnt.friendsLinkList.map(item => {
                item.show = false
            })
            item.show = true
            this.$forceUpdate();
        },
        getMethodName(methodName) {
            this[methodName]();
        },
        onLogin () {
            this.$emit('on-onLogin')
        },
    },
}

</script>
<style src="./css/footer.less" lang="less" scoped></style>