<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  //灰化处理
  // watch: {
  //     //监听相同路由下参数变化的时候，从而实现异步刷新
  //     '$route'(to,from) {
  //         if(this.$route.path!=='/'){//灰化处理解除
  //             setTimeout(() => {
  //               document.querySelector('html').removeAttribute('style')
  //             }, 1000);
  //         };
  //     },
  // },
}
</script>

<style>

</style>
