<template>
  <div class="wrap">
    <Header 
      :navContent="navContent"
      :currentTime="currentTime" 
      :userInfo="userInfo" 
      :messageInfo="messageInfo" 
      :userMenu="userMenu" 
      @on-onLogin="onLogin" 
      @get-time="getTime" 
    />

    <div class="bannerWrap">
      <Carousel @onLogin="onLogin"></Carousel>
    </div>
    <div class="address">
        <div class="addressMain">
            <el-row type="flex" justify="space-between" class="">
                <el-col
                    :class="$i18n.locale==='zh'?'el-col':'el-col2'"
                    @click.native="addressClick(index)"
                    v-for="(item, index) in addressInfo"
                    :key="index"
                    :span="index === 0? 7: index === 1? 9: index === 2? 6: ''"
                >
                    <div class="left bgCommon" :style="{backgroundImage: 'url(' + item.img + ')'}"></div>
                    <div class="addressTitle" v-html="item.title" />
                    <div class="addressText" v-if="$i18n.locale=='zh'">
                        <p v-html="item.info" v-if="index !== 0"></p>
                        <p v-else>
                            使用可信时间戳证据的裁判文书{{accountNum.precedentTotal}}件
                        </p>
                    </div>
                    <div class="addressBot" v-if="index === 0 && $i18n.locale==='zh'">
                        (数据来源：中国裁判文书网)
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
    <div class="iprWork">
        <div class="workMain">
            <div class="workTitle" v-html="iprWorkInfo.title"></div>

            <div class="principleVideo">
              <div class="videoBtn" @click="playVideo(1)">
                <img src="@/assets/images/home/playIcon.png">
                <span>{{$t('videoPlay1')}}</span>
              </div>
            </div>

            <div class="workList">
                <el-row type="flex" justify="space-between" class="aCol">
                    <el-col class="left" :span="8">
                        <div
                            v-for="(item, index) in iprWorkInfo.listInfo"
                            :key="index"
                            @click="workClick(item, index)"
                            :class="(workActive === index)? ($i18n.locale==='zh'?'workListLeft workLeftActive' :'workLeftEnActive workListLeft'):'workListLeft'"
                            @mouseover="mouseOver()"
                            @mouseleave="mouseLeave()"
                        >
                            <div class="leftTitle" v-html="item.leftTitle" :class="{leftTitleActive: workActive === index}">
                            </div>
                            <div
                                class="leftInfo"
                                v-html="item.leftInfo"
                                :class="{baseBreak:workActive !== index? 'baseBreak': '',}"
                            ></div>
                            <div
                                class="leftImg bgCommon"
                                :style="{backgroundImage:'url(' + item.leftImg + ')'}"
                                v-if="workActive === index"
                            ></div>
                            <el-progress
                                :percentage="percentage"
                                color="#3F7DF7"
                                class="line"
                                v-if="workActive === index"
                            ></el-progress>
                        </div>
                    </el-col>
                    <el-col :span="16">
                        <div
                            class="workListRight"
                            v-for="(item, index) in iprWorkInfo.listInfo"
                            :key="index"
                            :class="{workRightActive: workActive === index,}"
                            @mouseover="mouseOver()"
                            @mouseleave="mouseLeave()"
                        >
                            
                            <div v-if="workActive === index">
                                <div class="rightTitle" v-html="item.rightTitle"></div>
                                <div class="rightListMain">
                                    <div class="rightListTitle">
                                        {{ item.rightList1.title || "" }}
                                    </div>
                                    <div class="rightList1" v-for="(item2) in item.rightList1.list" :key="item2">
                                        {{ item2 }}
                                    </div>
                                    <p>......</p>
                                    <div class="col" v-if="workActive === 2"></div>
                                    <div class="rightTitle rightTitleN" v-html="item.rightTitle2" v-if="item.rightTitle2"></div>
                                    <div class=" rightListTitle rightListTitle2" v-if="item.rightList2">
                                        {{ item.rightList2.title || "" }}
                                    </div>
                                    <div v-if="item.rightList2" style="width: 90%">
                                        <div class="rightList1" v-for="(item2) in item.rightList2.list" :key="item2">
                                            {{ item2 }}
                                        </div>
                                        <p>......</p>
                                    </div>
                                    <div class="workBtn" @click="onLogin">
                                        {{item.btnInfo}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <div class="workLogo bgCommon" :style="{backgroundImage:'url(' + iprWorkInfo.workLogo + ')'}" @mouseover="mouseOver()" @mouseleave="mouseLeave()">
                </div>
            </div>
        </div>
    </div>
    <div
        class="solution bgCommon"
        :style="{backgroundImage:'url(' +solutionInfo.solutionList[solutionActive].solutionIcon +')',}"
    >
        <div class="solutionBC"></div>
        <div class="solutionMain">
            <div class="solutionTitle">{{ $t('solutionInfo.title') }}</div>
            <div class="solutionNav">
                <p
                    @click="prevBtn"
                    class="bgCommon leftIcon"
                    :style="{backgroundImage:'url(' + solutionInfo.leftIcon + ')'}"
                    v-if="solutionActive !== 0"
                />
                <div class="navScroll">
                    <swiper :options="swiperOption" ref="solutionSwiper" class="swiperInfo">
                        <swiper-slide class="navText" v-for="(item, index) in solutionInfo.solutionList" :key="index">
                            <p :class="{solutionActive:solutionActive === index}" @click="solutionClick(item, index)">
                                {{ item.title }}
                            </p>
                        </swiper-slide>
                    </swiper>
                </div>
                <p
                    @click="nextBtn"
                    class="bgCommon rightIcon"
                    :style="{backgroundImage:'url(' + solutionInfo.rightIcon + ')',}"
                    v-if="solutionActive !==solutionInfo.solutionList.length - 1"
                />
            </div>
            <div class="solutionInfo">
                <div
                    class="infoText"
                    v-for="(item2, index2) in solutionInfo.solutionList[solutionActive].infoTitleList"
                    :key="index2"
                    :class="{solutionActive: solutionActive === index2}"
                >
                    <p>{{ item2 }}</p>
                    <span v-if="index2 <solutionInfo.solutionList[solutionActive].infoTitleList.length -1">|</span>
                </div>
                <div class="infoMain infoMain1" v-html="solutionInfo.solutionList[solutionActive].infoMain"></div>
                <div class="infoMain" v-html="solutionInfo.solutionList[solutionActive].infoMain1"></div>
                <div class="infoMain" v-html="solutionInfo.solutionList[solutionActive].infoMain2"></div>
                <div class="infoMain" v-html="solutionInfo.solutionList[solutionActive].infoMain3"></div>
                <div class="infoMain" v-html="solutionInfo.solutionList[solutionActive].infoMain4"></div>
                <div class="infoBtn" @click="dockModal=true">{{ $t('solutionInfo.infoBtn') }}</div>
            </div>
        </div>
    </div>
    <div class="principle">
        <div class="principleTitle" v-html="$t('principleTitle')"></div>
        <div class="principleVideo">
          <div class="videoBtn" @click="playVideo">
            <img src="@/assets/images/home/playIcon.png">
            <span>{{$t('videoPlay')}}</span>
          </div>
        </div>
        <div class="principleImg">
            <img src="@/assets/images/newIndex/principleImgEN.png" alt="" v-if="$i18n.locale==='en'">
            <img src="@/assets/images/newIndex/principleImgCN.png" alt="" v-else>
        </div>
    </div>
    <div class="precedents">
        <div class="precedents_cnt">
            <div class="pre-left">
                <h3 class="title">{{ $t('timestampCase.title') }}</h3>
                <div class="tab">
                    <div class="tab-name">
                        <ul>
                            <li 
                                :class="tabactive == item.id ? 'active' : ''"
                                v-for="item in courtRank"
                                :key="item.id"
                                @click="tabCourt(item)"
                            >
                                {{ item.name }}
                                <div class="active-line"></div>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-cnt">
                        <ul>
                            <li
                                v-for="item in conditionList"
                                :key="item.id"
                                @click="goTsa('case',item.id)"
                            >
                                <p class="introduce" v-html="item.title"></p>
                                <p class="caseNo">
                                    案号：{{ item.precedentNo }}
                                </p>
                            </li>
                        </ul>
                        <div class="more">
                            <span @click="goTsa('case')"
                                >查看更多 <i class="el-icon-right"></i
                            ></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pre-right">
                <h3 class="title">{{ $t('commonProblems.title') }}</h3>
                <div class="qusion">
                    <!-- <h5>在线疑问解答</h5> -->
                    <div class="ipt-wrap">
                        <el-input
                            type="text"
                            placeholder="请输入您想要了解的问题或关键字"
                            v-model="keywords"
                            class="ipt"
                        >
                            <i
                                class="el-icon-search el-input__icon"
                                slot="suffix"
                                @click="onSearch"
                            >
                            </i>
                        </el-input>
                    </div>
                    <ul class="cnt">
                        <li
                            v-for="item in questionsList"
                            :key="item.id"
                            @click="goTsa('wiki',item.id)"
                        >
                            <p v-html="item.question"></p>
                        </li>
                    </ul>
                    <div class="more">
                        <span @click="goTsa('wiki')">查看更多 <i class="el-icon-right"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="trait">
      <h3 class="title">
        <p>{{ $t('trait.title') }}</p>
        <span></span>
      </h3>
      <ul>
        <li>
          <div class="trait-icon">
            <img
                src="@/assets/images/newHome/trait-icon4.png"
                alt=""
            />
          </div>
          <div class="trait-title">
            <h4>{{$t('trait.item1.title')}}</h4>
            <span class="line2"></span>
          </div>
          <div class="introduce">
            <h5>{{$t('trait.item1.des.node1', { 'precedentTotal': accountNum.precedentTotal })}}</h5>
            <p>{{$t('trait.item1.des.node2')}}</p>
          </div>
        </li>
        <li>
          <div class="trait-icon">
            <img
                src="@/assets/images/newHome/trait-icon1.png"
                alt=""
            />
          </div>
          <div class="trait-title">
            <h4>{{$t('trait.item2.title')}}</h4>
            <span class="line2"></span>
          </div>
          <div class="introduce">
            <h5>{{$t('trait.item2.des.node1')}}</h5>
            <p>{{$t('trait.item2.des.node2')}}</p>
          </div>
        </li>
        <li>
          <div class="trait-icon">
            <img
                src="@/assets/images/newHome/trait-icon2.png"
                alt=""
            />
          </div>
          <div class="trait-title">
            <h4>{{$t('trait.item3.title')}}</h4>
            <span class="line2"></span>
          </div>
          <div class="introduce">
            <h5>{{$t('trait.item3.des.node1')}}</h5>
            <h5>{{$t('trait.item3.des.node2')}}</h5>
          </div>
        </li>
        <li>
          <div class="trait-icon">
            <img
                src="@/assets/images/newHome/trait-icon3.png"
                alt=""
            />
          </div>
          <div class="trait-title">
            <h4>{{$t('trait.item4.title')}}</h4>
            <span class="line2"></span>
          </div>
          <div class="introduce">
            <h5>{{$t('trait.item4.des.node1')}}</h5>
            <h5>{{$t('trait.item4.des.node2')}}</h5>
          </div>
        </li>
      </ul>
    </div>
    <div class="honor">
      <div class="honor_cnt">
        <swiper
            class="swiper-cnt"
            :options="swiperOptions"
            ref="mySwiper"
        >
          <swiper-slide class="swiper-slide">
            <ul>
              <li>
                <div class="pic">
                  <img
                      height="268"
                      src="@/assets/images/newHome/honor1.png"
                      alt=""
                  />
                </div>
                <p>世界知产组织WIPO：</p>
                <p style="margin-top: 10px">中国版权金奖——保护奖</p>
              </li>
              <li>
                <div class="pic">
                  <img
                      height="268"
                      src="@/assets/images/newHome/honor2.png"
                      alt=""
                  />
                </div>
                <p>国家版权局： 中国版权金奖——保护奖</p>
              </li>
              <li>
                <div class="pic">
                  <img
                      height="268"
                      src="@/assets/images/newHome/honor3.png"
                      alt=""
                  />
                </div>
                <p>国家版权局查处侵权盗版案例三等奖</p>
              </li>
            </ul>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <ul>
              <li>
                <div class="pic">
                  <img
                      height="197"
                      src="@/assets/images/newHome/honor4.jpg"
                      alt=""
                  />
                </div>
                <p>国家版权局研究推广站点</p>
              </li>
              <li>
                <div class="pic">
                  <img
                      height="197"
                      src="@/assets/images/newHome/honor5.png"
                      alt=""
                  />
                </div>
                <p>高新技术证书</p>
              </li>
              <li>
                <div class="pic">
                  <img
                      height="197"
                      src="@/assets/images/newHome/honor6.jpg"
                      alt=""
                  />
                </div>
                <p>中关村高新技术企业</p>
              </li>
            </ul>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <ul>
              <li>
                <div class="pic">
                  <img
                      height="197"
                      src="@/assets/images/newHome/honor7.jpg"
                      alt=""
                  />
                </div>
                <p>中国软件行业协会理事单位</p>
              </li>
              <li>
                <div class="pic">
                  <img
                      height="197"
                      src="@/assets/images/newHome/honor8.jpg"
                      alt=""
                  />
                </div>
                <p>中国版权协会常务理事单位</p>
              </li>
              <li>
                <div class="pic">
                  <img
                      height="197"
                      src="@/assets/images/newHome/honor9.jpg"
                      alt=""
                  />
                </div>
                <p>中国互联网金融会员</p>
              </li>
            </ul>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <ul>
              <li>
                <div class="pic">
                  <img
                      height="197"
                      src="@/assets/images/newHome/honor10.jpg"
                      alt=""
                  />
                </div>
                <p>国家版权交易中心联盟理事单位</p>
              </li>
              <li>
                <div class="pic">
                  <img
                      height="197"
                      src="@/assets/images/newHome/honor11.jpg"
                      alt=""
                  />
                </div>
                <p>首都知产服务协会</p>
              </li>
              <li>
                <div class="pic">
                  <img
                      height="197"
                      src="@/assets/images/newHome/honor12.jpg"
                      alt=""
                  />
                </div>
                <p>系统安全备案</p>
              </li>
            </ul>
          </swiper-slide>
          <div
              class="swiper-pagination"
              slot="pagination"
          ></div>
        </swiper>
      </div>
    </div>
    <!-- <div class="flow">
      <h3 class="title">
        <p>知产保护 仅需三步</p>
        <span></span>
      </h3>
      <div class="flow-cnt">
        <div class="sec-title">
          <div class="tab">
            <p
                :class="active == 0 ? 'active' : ''"
                @click="tab(0)"
            >网站申请</p>
            <p
                :class="active == 1 ? 'active' : ''"
                @click="tab(1)"
            >接口对接</p>
          </div>
        </div>
        <div class="flow-img">
          <img
              v-if="active == 0"
              src="@/assets/images/newHome/flow1.png"
              alt=""
          />
          <img
              v-else
              src="@/assets/images/newHome/flow2.png"
              alt=""
          />
        </div>
      </div>
    </div> -->
    
    <!-- <div class="bl-bg"></div> -->
    <div class="partner">
      <div class="partner-cnt">
        <h3 class="title">
          <p>{{$t('partner.title')}}</p>
          <span></span>
        </h3>
        <div class="logos">
          <ul>
            <li
                v-for="(item, index) in logosList"
                :key="index"
            >
              <img
                  :src="item.img"
                  alt=""
              />
            </li>
          </ul>
        </div>
        <div class="introduce">
          <p></p>
          <p>
            业务指导单位：中国版权协会 &nbsp &nbsp &nbsp 学术指导单位：中国法学会知识产权法学研究会
          </p>
          <p style="margin-bottom: 0px">
            联合共建：中国软件行业协会 中国摄影著作权协会 中国文字著作权协会
            中国音像著作权集体管理协会 国家版权交易中心联盟（部分成员）
          </p>
        </div>
      </div>
    </div>
    <Footer :currentTime="currentTime" :footerContent="footerContent" @on-onLogin="onLogin" />
    <div
        class="notice"
        v-if="zheShow"
    >
      <div class="zhe">
        <div class="contentImage">
          <!-- <span class="close" @click="zheShow=false"><i class="el-icon-circle-close"></i></span> -->
          <img
              @click="gobuy"
              src="@/assets/images/newHome/opusIndex.png"
              alt=""
          />
          <img
              src="@/assets/images/newHome/temClose.png"
              alt=""
              class="temClose"
              @click="zheShow = false"
          />
        </div>
      </div>
    </div>
    <div
        class="videoZhe"
        v-if="isShowVideo"
    >
      <div class="vid">
        <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
        ></video-player>
        <span
            class="close"
            @click="closeVideo"
        >
          <i class="el-icon-close"></i>
        </span>
        <!-- <div class="shar-wrap">
          <div
              class="shar"
              :style="shareActive ? 'background:rgba(255,255,255,1)' : ''"
          >
            <i
                class="el-icon-share"
                @click="shareActive = !shareActive"
            ></i>
            <span
                class="arrow"
                v-show="shareActive"
            ></span>
            <div
                class="share-cnt"
                v-show="shareActive"
            >
              <div class="left">
                <div><img
                    :src="qrcode"
                    alt=""
                /></div>
                <p>扫码分享视频</p>
              </div>
              <div class="right">
                <span
                    class="closeShare"
                    @click="shareActive = !shareActive"
                ><i class="el-icon-close"></i></span>
                <h5>视频地址</h5>
                <div
                    class="url"
                    :title="videoPath"
                >
                  {{ videoPath }}
                </div>
                <div class="copy">
                  <span
                      @click="copy()"
                      :data-clipboard-text="videoPath"
                      class="copybtn"
                  >复制</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- <TsaDialog ref="tsaDialog"/> -->
    <Docking :show="dockModal" @onClose="dockModal=false" />
  </div>
</template>

<script>
import {channelLink} from "@/config/channel.js";
import Header from '@/components/Header/index.vue';
import Footer from "@/components/Footer/index.vue";
import {
  questionAnswer,
  precedent,
  precedentStatistics,
  precedentSearchByCondition,
  getCurrentTime
} from "@/api/tsaService";
import {me} from "@/api/service";
import { unreadCount } from "@/api/accountService";
import {swiper, swiperSlide} from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";
import qs from "qs";
import Carousel from './HomeCom/index.vue'
import {hrefOpen} from '@/utils/filter'
import Docking from "@/components/Docking";

let timer;
export default {
  metaInfo: {
    title: "知识产权保护平台-司法认可的商业秘密和知识产权及原创证明平台",
    meta: [
      {
        name: "keyWords",
        content:
            "知识产权保护,知识产权保护平台",
      },
      {
        name: "description",
        content:
            "知识产权保护平台为数据、文件、创作提供知识产权存证服务，包含商业秘密保护、著作权保护、版权保护、商标保护、专利保护、数据知识产权存证等内容，证据权属清晰、司法认可。",
      },
    ],
  },
  data() {
    return {
      currentTime:null,
      videoUrl: channelLink(
          process.env.VUE_APP_TSA + "/guide/detail/1040",
          "bdipr"
      ),
      bannerbg: {
        backgroundImage:
            "url(" + require("@/assets/images/newHome/banner.png") + ")",
        backgroundRepeat: "no-repeat",
      },
      active: 0,
      courtRank: [
        {
          id: 1001549,
          name: "商业秘密",
          type: "precedent_usage",
        },
        {
          id: 970753,
          name: "商标",
          type: "precedent_column",
        },
        {
          id: 971777,
          name: "专利",
          type: "precedent_column",
        },
        {
          id: 972801,
          name: "著作权",
          type: "precedent_column",
        },
        {
          id: 1001548,
          name: "数字资产",
          type: "precedent_usage",
        },
      ],
      conditionList: [],
      questionsList: [],
      keywords: "",
      tabactive: 1001549,
      swiperOptions: {
        notNextTick: true,
        //循环
        loop: false,
        loopAdditionalSlides: 0,
        //设定初始化时slide的索引
        initialSlide: 0,
        //自动播放
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        //滑动速度
        speed: 1500,
        //滑动方向
        direction: "horizontal",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        //小手掌抓取滑动
        // grabCursor : true,
      },

      logosList: [
        {
          img: require("@/assets/images/partner/1.png"),
        },
        {
          img: require("@/assets/images/partner/2.png"),
        },
        {
          img: require("@/assets/images/partner/3.png"),
        },
        {
          img: require("@/assets/images/partner/4.png"),
        },
        {
          img: require("@/assets/images/partner/5.png"),
        },
        {
          img: require("@/assets/images/partner/6.png"),
        },
        {
          img: require("@/assets/images/partner/7.png"),
        },
        {
          img: require("@/assets/images/partner/8.png"),
        },
        {
          img: require("@/assets/images/partner/9.png"),
        },
        {
          img: require("@/assets/images/partner/10.png"),
        },
        {
          img: require("@/assets/images/partner/11.png"),
        },
        {
          img: require("@/assets/images/partner/12.png"),
        },
      ],
      zheShow: false,
      accountNum: {},
      userInfo: {},
      verifyUi: {
        url: process.env.VUE_APP_VERIFY,
        target: "_blank",
      },
      
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
      shareActive: false,
      videoPath: "",
      videoTSAPath:
          "https://evidence-public.oss-cn-beijing.aliyuncs.com/content/banner/40cde858e2ec420cb0115d29d378ac0c/%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%83%E5%B9%B3%E5%8F%B0%E6%93%8D%E4%BD%9C%E6%8C%87%E5%BC%95.mp4",
      qrcode: "",
      isShowVideo: false,
      workActive: -1,
      solutionActive: 0,
      percentage: 0,
      swiperOption: {
        slidesPerView: 6,
        spaceBetween: 0,
        notNextTick: true,
        loop: false,
        autoplay: 3000,
        direction: "horizontal",
        grabCursor: true,
        setWrapperSize: true,
        autoHeight: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        navigation: {
          prevEl: ".swiper-button-prev", //上一张
          nextEl: ".swiper-button-next", //下一张
        },
        scrollbar: ".swiper-scrollbar", //滚动条
        mousewheelControl: true,
        observeParents: true,
        hideOnClick: true,
      },
      dockModal:false,
      messageInfo:{}
    };
  },

  components: {
    Header,
    // TsaTop,
    // TsaNavBar,
    Footer,
    swiper,
    swiperSlide,
    Carousel,
    // TsaDialog,
    Docking
  },
  watch: {
    workActive(newVal, oldVal) {
      if (newVal !== oldVal) {
        timer && clearInterval(timer);
        timer = null;
        this.percentage = 0;
        timer = setInterval(() => {
          if (this.percentage < 100) {
            this.percentage = this.percentage + 2;
          }
        }, 200);
      }
    },
    percentage(newVal, oldVal) {
      if (newVal === 100) {
        setTimeout(() => {
          if (this.workActive == this.iprWorkInfo.listInfo.length - 1) {
            this.workActive = 0;
          } else {
            this.workActive = this.workActive + 1;
          }
        }, 500)
      }
    },
  },
  computed: {

    navContent(){
        return{
          topNavBar:[
              {
                  name: this.$t('header.nav.aboutUs'),
                  link: process.env.VUE_APP_TSAPLUS+'/about.html',
                  target: '_blank',
              },
              // {
              //     name: '网站导航',
              //     link: process.env.VUE_APP_TSAPLUS,
              //     target: '_blank',
              // },
              // {
              //     name: '网站地图',
              //     link: process.env.VUE_APP_TSAPLUS,
              //     target: '_blank',
              // },
              // {
              //     name: 'XML地图',
              //     link: process.env.VUE_APP_TSAPLUS,
              //     target: '_blank',
              // },
          ],
          bigNavBar:[
              {
                  name: this.$t('header.nav.home'),
                  link: process.env.VUE_APP_TSAPLUS,
                  target: '_self',
              },
              {
                  name: this.$t('header.newNav.qlws'),
                  link: process.env.VUE_APP_TSAPLUS+'/quanliweishi.html',
                  target: '_self',
              },
              {
                  name: this.$t('header.newNav.ev'),
                  link: process.env.VUE_APP_EV,
                  target: '_self',
              },
              {
                  name: this.$t('header.newNav.ipr'),
                  link: process.env.VUE_APP_COPYRIGHT,
                  target: '_self',
                  currentNav:true
              },
              {
                  name: this.$t('header.newNav.sign'),
                  link: process.env.VUE_APP_SIGN,
                  target: '_blank',
                  nofollow: true
              },
              {
                  name: this.$t('header.newNav.mail'),
                  link: process.env.VUE_APP_EMAIL,
                  target: '_self',
              },
              {
                  name: this.$t('header.nav.aigc'),
                  link: process.env.VUE_APP_AIGC,
                  target: '_self',
              },
          ],
          samllNavBar:[
              {
                  name: '知产鉴定',
                  link: process.env.VUE_APP_CAPITAL+'/identification',
                  target: '_blank',
                  showLang:'zh'
              },
              {
                  name: '验证中心',
                  link: process.env.VUE_APP_VERIFY,
                  target: '_blank',
              },
          ],
        }
    },

    footerContent(){
        return{
          navList:[
              {
                  name:this.$t('header.nav.aboutUs'),
                  childen:[
                      {
                          name: this.$t('header.nav.timeStampCenter'),
                          link: process.env.VUE_APP_TSAPLUS+'/about.html',
                          target: '_target',
                      },
                      {
                          name: this.$t('header.nav.timeServiceCentre'),
                          link: process.env.VUE_APP_TSAPLUS+'/about/timeservice.html',
                          target: '_target',
                      },
                      {
                          name: this.$t('header.nav.honer'),
                          link: process.env.VUE_APP_TSAPLUS+'/about/honor.html',
                          target: '_target',
                      },
                      {
                          name: this.$t('header.nav.contact'),
                          link: process.env.VUE_APP_TSAPLUS+'/about/relate.html',
                          target: '_target',
                      },
                      {
                          name: this.$t('header.nav.user'),
                          link: process.env.VUE_APP_TSAPLUS+'/about/typical.html',
                          target: '_target',
                      },
                      {
                          name: this.$t('header.nav.trademark'),
                          link: process.env.VUE_APP_TSAPLUS+'/about/brand.html',
                          target: '_target',
                      }
                  ]
              },
              {
                  name:this.$t('header.nav.prodect'), 
                  childen:[
                      {
                          name: this.$t('header.newNav.qlws'), 
                          link: process.env.VUE_APP_QLWS,
                          target: '_self',
                      },
                      {
                          name: this.$t('header.nav.ev'), 
                          link: process.env.VUE_APP_EV,
                          target: '_self',
                      },
                      {
                          name: this.$t('header.newNav.footerIpr'), 
                          link: process.env.VUE_APP_COPYRIGHT,
                          target: '_self',
                      },
                      {
                          name: this.$t('header.nav.sign'), 
                          link: process.env.VUE_APP_SIGN,
                          target: '_blank',
                          nofollow: true
                      },
                      {
                          name: this.$t('header.nav.mail'), 
                          link: process.env.VUE_APP_EMAIL,
                          target: '_self',
                      },
                      {
                          name: this.$t('header.nav.aigc'), 
                          link: process.env.VUE_APP_AIGC,
                          target: '_self',
                      },
                      {
                          name: this.$t('header.nav.monitor'), 
                          link: process.env.VUE_APP_MONITOR,
                          target: '_self',
                      },
                  ]
              },
              {
                  name:this.$t('footer.sec1'),
                  childen:[
                      {
                          name: this.$t('header.nav.home'),
                          link: process.env.VUE_APP_TSAPLUS,
                          target: '_self',
                      },
                      {
                          name: this.$t('header.nav.open'), 
                          link: process.env.VUE_APP_TSAPLUS+'/openservice.html',
                          target: '_blank',
                      },
                      {
                          name: this.$t('header.nav.verify'), 
                          link: process.env.VUE_APP_VERIFY,
                          target: '_blank',
                      },
                      {
                          name: this.$t('footer.evidence'),
                          link: process.env.NODE_ENV == 'development' ? '/api/api/login?redirectUrl='+process.env.VUE_APP_EV+'/vm_fetch':'/api/login?redirectUrl='+process.env.VUE_APP_EV+'/vm_fetch',
                          target: '_blank',
                      },
                  ]
              },
              {
                  name:this.$t('footer.sec2'),
                  childen:[
                      {
                          name: this.$t('footer.sample'), 
                          link: process.env.VUE_APP_TSAPLUS+'/sample.html',
                          target: '_blank',
                      },
                      {
                          name: this.$t('footer.download'),
                          link: process.env.VUE_APP_TSAPLUS+'/download.html',
                          target: '_blank',
                      },
                      {
                          name: this.$t('header.nav.help'), 
                          link: process.env.VUE_APP_TSAPLUS+'/wiki.html',
                          target: '_blank',
                      },
                  ]
              },
          ],
          qrCodeList:[
              {
                  show: true,
                  qrCode: require('@/components/Footer/images/footerTsa.jpg'),
                  icon: 'https://tsa-content.oss-cn-beijing.aliyuncs.com/rightsTV/downloadIcon.png',
                  name: this.$t('header.nav.qlws'),
              },
              {
                  show: false,
                  qrCode: require('@/components/Footer/images/footerWx.png'),
                  icon: 'https://tsa-content.oss-cn-beijing.aliyuncs.com/weChatOfficialAccount/weChatOfficialAccountIcon.png',
                  name: this.$t('footer.publicNumber'), 
              },
              {
                  show: false,
                  qrCode: 'https://evidence-public.oss-cn-beijing.aliyuncs.com/customerImages/2.png',
                  icon: 'https://tsa-content.oss-cn-beijing.aliyuncs.com/customerService/home-footer-icon.png',
                  name: this.$t('footer.wechat'),  
              },
              {
                  show: false,
                  qrCode: require('@/components/Footer/images/buy.png'),
                  icon: 'https://tsa-content.oss-cn-beijing.aliyuncs.com/payCourses/payCoursesIcon.png',
                  name: this.$t('footer.course'),   
              }
          ],
          agreementList:[
              {
                  name: this.$t('footer.regDoc'),   
                  link: process.env.VUE_APP_USERCENTERUI+'/agreement/registration',
                  target: '_blank',
                  currentNav:true
              },
              {
                  name: this.$t('footer.privacyDoc'),  
                  link: process.env.VUE_APP_USERCENTERUI+'/agreement/privacy',
                  target: '_blank',
              }
          ],
          friendsLinkList:[
              {
                  show: true,
                  tabTitle:'友情链接',
                  linkList:[
                      // {
                      //     title:'国家授时中心',
                      //     url:'http://www.ntsc.ac.cn/?LMCL=HcSri1'
                      // },
                      {
                          title:'北斗卫星导航',
                          url:'http://www.beidou.gov.cn/'
                      },
                      {
                          title:'北大法宝',
                          url:'https://www.pkulaw.com/'
                      },
                      {
                          title:'联合信任时间戳',
                          url:'https://www.tsa.cn'
                      },
                      {
                          title:'电子证据平台',
                          url:'https://ev.tsa.cn'
                      },
                      {
                          title:'邮件认证平台',
                          url:'https://mail.tsa.cn'
                      },
                      {
                          title:'监测服务平台',
                          url:'https://monitor.tsa.cn'
                      },
                      {
                          title:'软著保护平台',
                          url:'https://scp.tsa.cn'
                      },
                      {
                          title:'媒体版权固证平台',
                          url:'http://media.tsa.cn'
                      },
                      {
                          title:'时间戳验证平台',
                          url:'https://v.tsa.cn'
                      },
                      {
                          title:'联合信任时间戳移动端',
                          url:'https://m.tsa.cn'
                      },
                      {
                          title:'知识产权保护移动平台',
                          url:'https://ipr-m.tsa.cn'
                      },
                      {
                          title:'联合信任经销商',
                          url:'https://bd.tsa.cn'
                      },
                      {
                          title:'电子签名移动平台',
                          url:'http://1sign-m.tsa.cn'
                      },
                      {
                          title:'时间戳平台登录中心',
                          url:'https://usercenter.tsa.cn'
                      },
                      {
                          title:'电子取证知识',
                          url:'https://www.tsa.cn/baike-dianziquzheng.html'
                      },
                      {
                          title:'电子证据知识',
                          url:'https://www.tsa.cn/baike-dianzizhengju.html'
                      },
                      {
                          title:'知识产权保护知识',
                          url:'https://www.tsa.cn/baike-zhishichanquanbaohu.html'
                      },
                      {
                          title:'邮件认证知识',
                          url:'https://www.tsa.cn/baike-youjianrenzheng.html'
                      },
                      {
                          title:'电子合同认证知识',
                          url:'https://www.tsa.cn/baike-dianzihetong.html'
                      },
                      {
                          title:'侵权取证知识',
                          url:'https://www.tsa.cn/baike-qinquanquzheng.html'
                      },
                      {
                          title:'版权保护知识',
                          url:'https://www.tsa.cn/baike-banquanbaohu.html'
                      },
                      {
                          title:'著作权保护知识',
                          url:'https://www.tsa.cn/baike-zhuzuoquanbaohu.html'
                      },
                      {
                          title:'手机取证知识',
                          url:'https://www.tsa.cn/baike-shoujiquzheng.html'
                      },
                      {
                          title:'违法取证',
                          url:'https://www.tsa.cn/baike-weifaquzheng.html'
                      }
                  ]
              },
              {
                  show: false,
                  tabTitle:'精选服务',
                  linkList:[
                      {
                          title:'联合信任时间戳',
                          url:'https://www.tsa.cn'
                      },
                      {
                          title:'电子证据平台',
                          url:'https://ev.tsa.cn'
                      },
                      {
                          title:'邮件认证平台',
                          url:'https://mail.tsa.cn'
                      },
                      {
                          title:'软著保护平台',
                          url:'https://scp.tsa.cn'
                      },
                      {
                          title:'媒体版权固证平台',
                          url:'http://media.tsa.cn'
                      },
                      {
                          title:'时间戳验证平台',
                          url:'https://v.tsa.cn'
                      },
                      {
                          title:'联合信任时间戳移动端',
                          url:'https://m.tsa.cn'
                      },
                      {
                          title:'知识产权保护移动平台',
                          url:'https://ipr-m.tsa.cn'
                      },
                      {
                          title:'联合信任经销商',
                          url:'https://bd.tsa.cn'
                      },
                      {
                          title:'电子签名移动平台',
                          url:'http://1sign-m.tsa.cn'
                      },
                      {
                          title:'时间戳平台登录中心',
                          url:'https://usercenter.tsa.cn'
                      },
                  ]
              },
              {
                  show: false,
                  tabTitle:'最新知识',
                  linkList:[
                      {
                          title:'电子取证知识',
                          url:'https://www.tsa.cn/baike-dianziquzheng.html'
                      },
                      {
                          title:'电子证据知识',
                          url:'https://www.tsa.cn/baike-dianzizhengju.html'
                      },
                      {
                          title:'知识产权保护知识',
                          url:'https://www.tsa.cn/baike-zhishichanquanbaohu.html'
                      },
                      {
                          title:'邮件认证知识',
                          url:'https://www.tsa.cn/baike-youjianrenzheng.html'
                      },
                      {
                          title:'电子合同认证知识',
                          url:'https://www.tsa.cn/baike-dianzihetong.html'
                      },
                      {
                          title:'侵权取证知识',
                          url:'https://www.tsa.cn/baike-qinquanquzheng.html'
                      },
                      {
                          title:'版权保护知识',
                          url:'https://www.tsa.cn/baike-banquanbaohu.html'
                      },
                      {
                          title:'著作权保护知识',
                          url:'https://www.tsa.cn/baike-zhuzuoquanbaohu.html'
                      },
                      {
                          title:'手机取证知识',
                          url:'https://www.tsa.cn/baike-shoujiquzheng.html'
                      },
                      {
                          title:'违法取证',
                          url:'https://www.tsa.cn/baike-weifaquzheng.html'
                      }
                  ]
              }
          ]
        }
    },
    userMenu(){
      return[
        {
          name: this.$t('header.userMenu.usercenter'),
          link: process.env.VUE_APP_ACCOUNTUI,
          target: "_blank",
        },
        {
          name: this.$t('header.userMenu.recharge'),
          link: process.env.VUE_APP_ACCOUNTUI+'/userAccount/buy?clientId=952310',
          target: '_blank',
        },
        {
          name: this.$t('header.userMenu.msg'),
          link: process.env.VUE_APP_ACCOUNTUI+'/messageCenter/history?clientId=952310',
          target: '_blank',
          isMes:true,
        },
        {
          name: this.$t('header.userMenu.logOut'),
          click:true,
          link:
              process.env.NODE_ENV === "development"
                  ? "/api/api/logout"
                  : "/opus/api/logout",
          target: "_self",
        },
      ]
    },
    addressInfo(){
        return[
            {
                img: require("@/assets/images/newIndex/address1.png"),
                title: this.$t("display.sec1.title"),
                info: this.$t("display.sec1.info"),
            },
            {
                img: require("@/assets/images/newIndex/address2.png"),
                title: this.$t("display.sec2.title"),
                info: this.$t("display.sec2.info"),
            },
            {
                img: require("@/assets/images/newIndex/address3.png"),
                title: this.$t("display.sec3.title"),
                info: this.$t("display.sec3.info"),
            },
        ]
    },
    iprWorkInfo(){
        return{
            title:  this.$t("iprWork.iprWorkInfo.title"),
            workLogo: require("@/assets/images/newIndex/workLogo.png"),
            listInfo: [
                {
                    leftTitle:this.$t("iprWork.iprWorkInfo.listInfo.sec1.leftTitle"),
                    leftInfo:this.$t("iprWork.iprWorkInfo.listInfo.sec1.leftInfo"),
                    leftImg: require("@/assets/images/newIndex/workLeft1.png"),
                    rightTitle:this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightTitle"),
                    rightList1: {
                        list: [
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list1"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list2"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list3"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list4"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list5"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list6"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list7"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list8"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list9"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list10"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list11"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list12"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list13"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list14"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.list15"),
                        ],
                        title: this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList1.title"),
                    },
                    rightList2: {
                        list: [
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list1"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list2"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list3"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list4"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list5"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list6"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list7"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list8"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list9"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list10"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list11"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list12"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list13"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list14"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.list15"),
                        ],
                        title:this.$t("iprWork.iprWorkInfo.listInfo.sec1.rightList2.title"),
                    },
                    btnInfo:this.$t("iprWork.iprWorkInfo.listInfo.sec1.btnInfo"),
                },
                {
                    leftTitle:this.$t("iprWork.iprWorkInfo.listInfo.sec2.leftTitle"),
                    leftInfo:this.$t("iprWork.iprWorkInfo.listInfo.sec2.leftInfo"),
                    leftImg: require("@/assets/images/newIndex/workLeft2.png"),
                    rightTitle:this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightTitle"),
                    rightList1: {
                        list: [
                            this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list1"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list2"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list3"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list4"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list5"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list6"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list7"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list8"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list9"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list10"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list11"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.list12"),
                        ],
                        title: this.$t("iprWork.iprWorkInfo.listInfo.sec2.rightList1.title"),
                    },
                    btnInfo:this.$t("iprWork.iprWorkInfo.listInfo.sec2.btnInfo"),
                },
                {
                    leftTitle:this.$t("iprWork.iprWorkInfo.listInfo.sec3.leftTitle"),
                    leftInfo:this.$t("iprWork.iprWorkInfo.listInfo.sec3.leftInfo"),
                    leftImg: require("@/assets/images/newIndex/workLeft3.png"),
                    rightTitle:this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightTitle"),
                    rightTitle2:this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightTitle2"),
                    rightList1: {
                        list: [
                            this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList1.list1"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList1.list2"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList1.list3"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList1.list4"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList1.list5"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList1.list6"),
                        ],
                        title: this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList1.title"),
                    },
                    rightList2: {
                        list: [
                            this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList2.list1"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList2.list2"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList2.list3"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList2.list4"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList2.list5"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList2.list6"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList2.list7"),
                        ],
                        title: this.$t("iprWork.iprWorkInfo.listInfo.sec3.rightList2.title"),
                    },
                    btnInfo:this.$t("iprWork.iprWorkInfo.listInfo.sec3.btnInfo"),
                },
                {
                    leftTitle:this.$t("iprWork.iprWorkInfo.listInfo.sec4.leftTitle"),
                    leftInfo:this.$t("iprWork.iprWorkInfo.listInfo.sec4.leftInfo"),
                    leftImg: require("@/assets/images/newIndex/workLeft4.png"),
                    rightTitle:this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightTitle"),
                    rightList1: {
                        list: [
                            this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list1"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list2"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list3"),
                            this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list4"),
                            // this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list5"),
                            // this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list6"),
                            // this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list8"),
                            // this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list9"),
                            // this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.list10"),
                        ],
                        title: this.$t("iprWork.iprWorkInfo.listInfo.sec4.rightList1.title"),
                    },
                    btnInfo:this.$t("iprWork.iprWorkInfo.listInfo.sec4.btnInfo"),
                },
            ],
        }
    },
    solutionInfo(){
        return{
            leftIcon: require("@/assets/images/newIndex/leftIcon.png"),
            rightIcon: require("@/assets/images/newIndex/rightIcon.png"),
            solutionList: [
                {
                    title: this.$t("solutionInfo.solutionList.sec1.title"),
                    infoTitleList: [
                        this.$t("solutionInfo.solutionList.sec1.infoTitleList.list1"),
                        this.$t("solutionInfo.solutionList.sec1.infoTitleList.list2"),
                        this.$t("solutionInfo.solutionList.sec1.infoTitleList.list3"),
                        this.$t("solutionInfo.solutionList.sec1.infoTitleList.list4"),
                        this.$t("solutionInfo.solutionList.sec1.infoTitleList.list5"),
                    ],
                    infoMain:this.$t("solutionInfo.solutionList.sec1.infoMain"),
                    infoMain1: this.$t("solutionInfo.solutionList.sec1.infoMain1"),
                    infoMain2:this.$t("solutionInfo.solutionList.sec1.infoMain2"),
                    infoMain3:this.$t("solutionInfo.solutionList.sec1.infoMain3"),
                    infoMain4: this.$t("solutionInfo.solutionList.sec1.infoMain4"),
                    infoBtn: this.$t("solutionInfo.solutionList.sec1.infoBtn"),
                    solutionIcon: require("@/assets/images/newIndex/solution1.png"),
                },
                {
                    title: this.$t("solutionInfo.solutionList.sec2.title"),
                    infoTitleList: [
                        this.$t("solutionInfo.solutionList.sec2.infoTitleList.list1"),
                        this.$t("solutionInfo.solutionList.sec2.infoTitleList.list2"),
                        this.$t("solutionInfo.solutionList.sec2.infoTitleList.list3"),
                        this.$t("solutionInfo.solutionList.sec2.infoTitleList.list4"),
                        this.$t("solutionInfo.solutionList.sec2.infoTitleList.list5"),
                        this.$t("solutionInfo.solutionList.sec2.infoTitleList.list6"),
                        this.$t("solutionInfo.solutionList.sec2.infoTitleList.list7"),
                    ],
                    infoTitle:this.$t("solutionInfo.solutionList.sec2.infoTitle"),
                    infoMain:this.$t("solutionInfo.solutionList.sec2.infoMain"),
                    infoMain1:this.$t("solutionInfo.solutionList.sec2.infoMain1"),
                    infoMain2:this.$t("solutionInfo.solutionList.sec2.infoMain2"),
                    infoMain3:this.$t("solutionInfo.solutionList.sec2.infoMain3"),
                    solutionIcon: require("@/assets/images/newIndex/solution2.png"),
                },
                {
                    title: this.$t("solutionInfo.solutionList.sec3.title"),
                    infoTitleList: [
                        this.$t("solutionInfo.solutionList.sec3.infoTitleList.list1"),
                        this.$t("solutionInfo.solutionList.sec3.infoTitleList.list2"),
                        this.$t("solutionInfo.solutionList.sec3.infoTitleList.list3"),
                        this.$t("solutionInfo.solutionList.sec3.infoTitleList.list4"),
                    ],
                    infoTitle:this.$t("solutionInfo.solutionList.sec3.infoTitle"),
                    infoMain:this.$t("solutionInfo.solutionList.sec3.infoMain"),
                    infoMain1:this.$t("solutionInfo.solutionList.sec3.infoMain1"),
                    infoMain2:this.$t("solutionInfo.solutionList.sec3.infoMain2"),
                    solutionIcon: require("@/assets/images/newIndex/solution3.png"),
                },
                {
                    title: this.$t("solutionInfo.solutionList.sec4.title"),
                    infoTitleList: [
                        this.$t("solutionInfo.solutionList.sec4.infoTitleList.list1"),
                        this.$t("solutionInfo.solutionList.sec4.infoTitleList.list2"),
                        this.$t("solutionInfo.solutionList.sec4.infoTitleList.list3"),
                        this.$t("solutionInfo.solutionList.sec4.infoTitleList.list4"),
                        this.$t("solutionInfo.solutionList.sec4.infoTitleList.list5"),
                        this.$t("solutionInfo.solutionList.sec4.infoTitleList.list6"),
                    ],
                    infoTitle:this.$t("solutionInfo.solutionList.sec4.infoTitle"),
                    infoMain:this.$t("solutionInfo.solutionList.sec4.infoMain"),
                    infoMain1:this.$t("solutionInfo.solutionList.sec4.infoMain1"),
                    infoMain2:this.$t("solutionInfo.solutionList.sec4.infoMain2"),
                    infoMain3:this.$t("solutionInfo.solutionList.sec4.infoMain3"),
                    solutionIcon: require("@/assets/images/newIndex/solution4.png"),
                },
                {
                    title: this.$t("solutionInfo.solutionList.sec5.title"),
                    infoTitleList: [
                        this.$t("solutionInfo.solutionList.sec5.infoTitleList.list1"),
                        this.$t("solutionInfo.solutionList.sec5.infoTitleList.list2"),
                        this.$t("solutionInfo.solutionList.sec5.infoTitleList.list3"),
                        this.$t("solutionInfo.solutionList.sec5.infoTitleList.list4"),
                        this.$t("solutionInfo.solutionList.sec5.infoTitleList.list5"),
                        this.$t("solutionInfo.solutionList.sec5.infoTitleList.list6"),
                        this.$t("solutionInfo.solutionList.sec5.infoTitleList.list7"),
                    ],
                    infoTitle:this.$t("solutionInfo.solutionList.sec5.infoTitle"),
                    infoMain:this.$t("solutionInfo.solutionList.sec5.infoMain"),
                    infoMain1:this.$t("solutionInfo.solutionList.sec5.infoMain1"),
                    infoMain2:this.$t("solutionInfo.solutionList.sec5.infoMain2"),
                    infoMain3:this.$t("solutionInfo.solutionList.sec5.infoMain3"),
                    infoMain4:this.$t("solutionInfo.solutionList.sec5.infoMain4"),
                    solutionIcon: require("@/assets/images/newIndex/solution5.png"),
                },
                {
                    title: this.$t("solutionInfo.solutionList.sec6.title"),
                    infoTitleList: [
                        this.$t("solutionInfo.solutionList.sec6.infoTitleList.list1"),
                        this.$t("solutionInfo.solutionList.sec6.infoTitleList.list2"),
                        this.$t("solutionInfo.solutionList.sec6.infoTitleList.list3"),
                        this.$t("solutionInfo.solutionList.sec6.infoTitleList.list4"),
                    ],
                    infoTitle:this.$t("solutionInfo.solutionList.sec6.infoTitle"),
                    infoMain:this.$t("solutionInfo.solutionList.sec6.infoMain"),
                    infoMain1:this.$t("solutionInfo.solutionList.sec6.infoMain1"),
                    infoMain3:this.$t("solutionInfo.solutionList.sec6.infoMain3"),
                    infoMain4:this.$t("solutionInfo.solutionList.sec6.infoMain4"),
                    solutionIcon: require("@/assets/images/newIndex/solution6.png"),
                },
                {
                    title: this.$t("solutionInfo.solutionList.sec7.title"),
                    infoTitleList: [
                        this.$t("solutionInfo.solutionList.sec7.infoTitleList.list1"),
                        this.$t("solutionInfo.solutionList.sec7.infoTitleList.list2"),
                        this.$t("solutionInfo.solutionList.sec7.infoTitleList.list3"),
                    ],
                    infoTitle:this.$t("solutionInfo.solutionList.sec7.infoTitle"),
                    infoMain:this.$t("solutionInfo.solutionList.sec7.infoMain"),
                    infoMain1:this.$t("solutionInfo.solutionList.sec7.infoMain1"),
                    infoMain2:this.$t("solutionInfo.solutionList.sec7.infoMain2"),
                    infoMain3:this.$t("solutionInfo.solutionList.sec7.infoMain3"),
                    solutionIcon: require("@/assets/images/newIndex/solution7.png"),
                },
                {
                    title: this.$t("solutionInfo.solutionList.sec8.title"),
                    infoTitleList: [
                        this.$t("solutionInfo.solutionList.sec8.infoTitleList.list1"),
                        this.$t("solutionInfo.solutionList.sec8.infoTitleList.list2"),
                        this.$t("solutionInfo.solutionList.sec8.infoTitleList.list3"),
                        this.$t("solutionInfo.solutionList.sec8.infoTitleList.list4"),
                        this.$t("solutionInfo.solutionList.sec8.infoTitleList.list5"),
                        this.$t("solutionInfo.solutionList.sec8.infoTitleList.list6"),
                        this.$t("solutionInfo.solutionList.sec8.infoTitleList.list7"),
                    ],
                    infoTitle:this.$t("solutionInfo.solutionList.sec8.infoTitle"),
                    infoMain:this.$t("solutionInfo.solutionList.sec8.infoMain"),
                    infoMain1:this.$t("solutionInfo.solutionList.sec8.infoMain1"),
                    infoMain2:this.$t("solutionInfo.solutionList.sec8.infoMain2"),
                    infoMain3:this.$t("solutionInfo.solutionList.sec8.infoMain3"),
                    solutionIcon: require("@/assets/images/newIndex/solution8.png"),
                },
                {
                    title: this.$t("solutionInfo.solutionList.sec9.title"),
                    infoTitleList: [
                        this.$t("solutionInfo.solutionList.sec9.infoTitleList.list1"),
                        this.$t("solutionInfo.solutionList.sec9.infoTitleList.list2"),
                        this.$t("solutionInfo.solutionList.sec9.infoTitleList.list3"),
                        this.$t("solutionInfo.solutionList.sec9.infoTitleList.list4"),
                        this.$t("solutionInfo.solutionList.sec9.infoTitleList.list5"),
                        this.$t("solutionInfo.solutionList.sec9.infoTitleList.list6"),
                        this.$t("solutionInfo.solutionList.sec9.infoTitleList.list7"),
                        this.$t("solutionInfo.solutionList.sec9.infoTitleList.list8"),
                    ],
                    infoTitle:this.$t("solutionInfo.solutionList.sec9.infoTitle"),
                    infoMain:this.$t("solutionInfo.solutionList.sec9.infoMain"),
                    infoMain1:this.$t("solutionInfo.solutionList.sec9.infoMain1"),
                    infoMain2:this.$t("solutionInfo.solutionList.sec9.infoMain2"),
                    infoMain3:this.$t("solutionInfo.solutionList.sec9.infoMain3"),
                    infoMain4: this.$t("solutionInfo.solutionList.sec9.infoMain4"),
                    solutionIcon: require("@/assets/images/newIndex/solution9.png"),
                },
                {
                    title: this.$t("solutionInfo.solutionList.sec10.title"),
                    infoTitleList: [
                        this.$t("solutionInfo.solutionList.sec10.infoTitleList.list1"),
                        this.$t("solutionInfo.solutionList.sec10.infoTitleList.list2"),
                        this.$t("solutionInfo.solutionList.sec10.infoTitleList.list3"),
                        this.$t("solutionInfo.solutionList.sec10.infoTitleList.list4"),
                        this.$t("solutionInfo.solutionList.sec10.infoTitleList.list5"),
                    ],
                    infoTitle:this.$t("solutionInfo.solutionList.sec10.infoTitle"),
                    infoMain:this.$t("solutionInfo.solutionList.sec10.infoMain"),
                    infoMain1:this.$t("solutionInfo.solutionList.sec10.infoMain1"),
                    infoMain2:this.$t("solutionInfo.solutionList.sec10.infoMain2"),
                    infoMain3:this.$t("solutionInfo.solutionList.sec10.infoMain3"),
                    solutionIcon: require("@/assets/images/newIndex/solution10.png"),
                },
            ]
        }
    }
  },

  created() {
    this.getPrecedentList();
    this.getQuestionList();
    this.getprecedentStatistics();
    this.initWork();
    this.getTime();
    me().then((res) => {
      this.userInfo = res;
      this.token=res.accessToken;
      if(res.parentId!=='0'){
        this.userMenu.splice(1, 1)
      }
      //头部消息
      unreadCount().then((res2)=>{
          this.showRead =res2.sourceTypeUnreadList.some((item)=>{
              return item.unreadCount>0
          })
          let unIndex = 0
          for (var i = 0; i < res2.sourceTypeUnreadList.length; i++) {
            if (res2.sourceTypeUnreadList[i].unreadCount > 0) {
              unIndex = i
              break
            }
          }
          let link='';
          if (this.showRead) {
            link=process.env.VUE_APP_ACCOUNTUI + `/messageCenter/history?clientId=700012&sysRosterId=${res2.sourceTypeUnreadList[unIndex].sysRosterId}`
          } else {
            link=process.env.VUE_APP_ACCOUNTUI + `/messageCenter/history?clientId=700012`
          }
          this.messageInfo={
            showRead:this.showRead,
            link,
          }
      }).catch((err) => {
          console.log(err)
      });
    }).catch((err) => {
      console.log(err);
    });
    if (this.$store.state.userInfo) {
      this.token = this.$store.state.userInfo.me.accessToken;
      console.log(this.token);
    }

    
    
    // let endTime=new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1);
    // let currentTime=new Date().getTime();
    // if(!this.$getlocalStorage('isShow',(endTime-currentTime))){
    //     this.zheShow=true;
    //     this.$setlocalStorage('isShow',true);
    // }

  },

  mounted() {
    if (window['__PRERENDER_INJECTED']) {
        return
    }else{
        zhuge.track("进入知识产权平台");
    }
    
  },

  //灰化处理
  // beforeCreate() {
  //     document.querySelector('html').setAttribute('style', 'filter: grayscale(1)')
  // },
  // beforeDestroy() {
  //     document.querySelector('html').removeAttribute('style')
  // },

  methods: {

    getTime(){
      getCurrentTime().then(res => {
          this.currentTime=res;
      });
    },
    onLogin() {
      let params = Object.assign(this.$route.query, {
        redirectUrl: encodeURIComponent(window.location.origin + "/time/ApplyTime"),
      });
      if (this.$route.query.channelCode !== undefined) {
        params.channelCode = this.$route.query.channelCode
            ? this.$route.query.channelCode
            : "bdipr";
      }
      if (process.env.NODE_ENV === "development") {
        window.location.href = "/api/api/login?" + qs.stringify(params);
      } else {
        window.location.href = "/opus/api/login?" + qs.stringify(params);
      }
    },
    onLogout(){
        localStorage.removeItem('queryData')
        localStorage.removeItem('popups');
        if (process.env.NODE_ENV === 'development') {
            window.location.href = '/api/api/logout'
        } else {
            window.location.href = '/opus/api/logout'
        }
    },
    tab(n) {
      this.active = n;
    },
    async getprecedentStatistics() {
      let res = await precedentStatistics();
      if (res) {
        this.accountNum = res;
        console.log(res);
      }
    },
    getPrecedentList() {
      let that = this;
      precedentSearchByCondition({
        size: 3,
        usageDictionaryIds: 1001549,
        page: 1,
        sort: 1,
      })
          .then((res) => {
            console.log(res);
            that.conditionList = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getQuestionList() {
      let that = this;
      questionAnswer({
        size: 5,
        sourceType: 952310,
        page: 1,
        sort: 1,
      })
          .then((res) => {
            console.log(res);
            that.questionsList = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    tabCourt(data) {
      console.log(data)
      let httpUrl =
          data.id === 1001549 ? precedentSearchByCondition :
              data.id === 1001548 ? precedentSearchByCondition :
                  precedent
      let paramsObj =
          data.id === 1001549 ? {usageDictionaryIds: 1001549} :
              data.id === 1001548 ? {usageDictionaryIds: 1001548} :
                  {dictionaryId: data.id}
      paramsObj.size = 3
      paramsObj.sort = 1
      paramsObj.page = 1
      this.tabactive = data.id;
      let that = this;
      httpUrl(
          paramsObj)
          .then((res) => {
            console.log(res);
            that.conditionList = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    goTsa(path,id) {
      if(id){
        let newID=TsaConvertId(id, path);
        window.open(process.env.VUE_APP_TSA+'/'+path+'/'+newID+'.html')
      }else{
        window.open(process.env.VUE_APP_TSA+'/'+path+'.html')
      }
    },
    onSearch() {
      window.open(
          process.env.VUE_APP_TSA +"/" +`wiki.html?swd=${encodeURIComponent(this.keywords)}`
      );
    },
    gobuy() {
      this.zheShow = false;
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = process.env.VUE_APP_ACCOUNTUI + "/userAccount/buy?sourceType=952310";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    
    playVideo(n) {
      let filePath;
      if(n==1){
          filePath ='https://tsa-public-pro.oss-cn-beijing.aliyuncs.com/gallery/0/840dd94524a1437e8d6db96d1f425610.mp4'
      }else{
        if(this.$i18n.locale==='en'){
            filePath = 'https://evidence-public.oss-cn-beijing.aliyuncs.com/ipr/3%E6%9C%887%E6%97%A5.mp4';
        }else{
            filePath = 'https://evidence-public.oss-cn-beijing.aliyuncs.com/ipr/%E5%90%88%E5%B9%B6.mp4';
        }
      }
      
      this.playerOptions.sources.push({
        src: filePath,
        type: "video/mp4",
      });
      this.isShowVideo = true;
      //let url = process.env.VUE_APP_TSA + "/playVideo?videoPath=" + filePath;
      //this.$nextTick(() => {
        //this.videoPath = url;
        // QRCode.toDataURL(url, {
        //   width: 100,
        //   height: 100,
        //   margin: 0,
        // }).then((url) => {
        //     this.qrcode = url;
        //   }).catch((err) => {
        //     console.error(err);
        //   });
      //});
    },
    closeVideo() {
      this.playerOptions.sources.shift();
      this.isShowVideo = false;
      this.shareActive = false;
    },
    copy() {
      var clipboard = new this.Clipboard(".copybtn");
      var that = this;
      clipboard.on("success", (e) => {
        // console.log('复制成功')
        // 释放内存
        that.$message({
          message: "复制成功",
          type: "success",
        });
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        console.log("该浏览器不支持自动复制");
        that.$message.error("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    onBannerBeforeChange(from, to) {
      console.log(from, to);
    },

    // 新版首页
    workClick(item, index) {
      if (this.workActive !== index) {
        this.percentage = 0;
        this.workActive = index;
      }
    },
    solutionClick(item, index) {
      this.solutionActive = index;
    },

    prevBtn() {
      this.$refs.solutionSwiper.swiper.slidePrev();
    },
    nextBtn() {
      this.$refs.solutionSwiper.swiper.slideNext();
    },
    initWork() {
      this.workActive = 0;
    },
    addressClick(index) {
      let url =
          index === 0 ? 'https://www.tsa.cn/news/detail/4235' :
              index === 1 ? 'https://www.tsa.cn/news/detail/4122' :
                  index === 2 ? 'https://www.tsa.cn/honor' : ''
      hrefOpen(url)
    },
    onTsaDialog() {
      console.log(this.$refs.tsaDialog);
      this.$refs.tsaDialog.initDialog()
    },
    mouseOver(data) {

      clearInterval(timer);
    },
    mouseLeave(data) {
      timer = setInterval(() => {
        if (this.percentage < 100) {
          this.percentage = this.percentage + 2;
        }
      }, 200);
    }
  },
};
</script>
<style src="@/assets/css/home.less" lang="less" scoped></style>
<style lang="less" scoped>
.wrap {
  padding-top: 90px;
  .baseBreak {
    -webkit-line-clamp: 1;
    -moz-line-clamp: 1;
    -ms-line-clamp: 1;
    -o-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
p{
  margin:0;
}
.headerNav {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

.zhe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  .contentImage {
    position: relative;

    .temClose {
      display: block;
      width: 48px;
      height: 48px;
      margin: 20px auto 0;
    }

    .close {
      position: absolute;
      top: -30px;
      right: -30px;

      i {
        cursor: pointer;
        color: #fff;
        font-size: 30px;
      }
    }

    img {
      width: 100%;
      cursor: pointer;
    }
  }
}
</style>
<style>
.precedents .el-input__icon {
  line-height: 35px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
