export default {
    applyTime: {
        steps: {
            title1: 'Complete information and add files',
            title2: 'Add applicant and right statements',
            title3: 'Confirm information, generate the certificate'
        },
        fileType: {
            title1: 'Business Secret',
            title2: 'Industrial Project',
            title3: 'Software Project',
            title4: 'Hardware Project',
            title5: 'Scientific Payoff',
            title6: 'Data Asset',
            title7: 'Logo Design',
            title8: 'Patent',
            title9: 'Writings',
            title10: 'Audiovisual Work',
            title11: 'Photographic Work',
            title12: 'Art Work',
            title13: 'Musical Work',
            title14: 'Design Work',
            title15: 'Teaching Materials',
            title17:'AIGC',
            title16: 'Other',
        },
        ProTypes: {
            title1: 'Protected Types',
        },
        AutModes: {
            title1: 'Authentication Modes',
            title2: 'Stored Authentication',
            title3: 'Non-storage Authentication',
            title4: 'Files stored in cloud，can be downloaded and viewed at any time',
            title5: 'Files are not uploaded, which will be authenticated by UniTrust TimeStamp to protect your privacy and secrets.',
        },
        AddFile: {
            title1: 'Add Files',
            title2: 'Add or move files （folders） here',
            title3: 'Remarks',
            title4: '(Optional) Please fill in  project name, label,  keywords, etc.',
            title5: 'We provide APIs for various intellectual property platforms and enterprises.',
            title6: 'contact us',
        },
        Next: {
            title1: 'Next',
            title2: 'Confirm',
            title3: 'Continue Editing',
            title4: 'Back'
        },
        Table: {
            title1: 'No',
            title2: 'File Name',
            title3: 'File types',
            title4: 'Non-storage',
            title5: 'Stored',
            title6: 'Operation',
            title7: 'Delete',
            title8: 'Timestamp cost',
        },
        ApplyInfo: {
            title1: 'Applicant Information',
            title2: 'Applicant Name',
            title3: 'ID Type',
            title4: 'ID Number',
            title5: 'Authenticated by real-name',
            title6: 'Applicant’s Statement',
            title7: 'The content here is the applicant\'s self-reported statement. The applicant assumes full responsibility for the authenticity of the self-reported statement. The information will be displayed on the UniTrust TimeStamp Authentication Certificate.  Beijing United Trust Technology Service Co., Ltd. will not review the authenticity of the applicant\'s self-reported statement information and will not assume relevant legal responsibilities.',
            title8: 'Statement: Beijing United Trust Technology Service Co., Ltd. will not review the authenticity of the information stated by the applicant and will not bear relevant legal liabilities',
            title9: 'Statement Templates',
            title10: '上一步',
            title11: 'Applicant’s information and statement',
            title12: 'Document information',
            title13:'Documents protected',
            title14:'Go for real-name authentication',
        }
    },
    applyResult:{
        breadcrumb1:'Apply for timestamp',
        breadcrumb2:'Result of the application',
        pdfInfo:{
            title1:"Apply for certificate successfully",
            title2:"Your ownership certificate and digital asset certificate",
            title3:"Download",
            title4:"View details",
            title5:"Reapply",
        },
        SpeciaTips:{
            title1:'1.The data/file cannot be modified or changed in any form except for the file name after applying for the timestamp, otherwise it cannot pass the verification. ' +
                'If you need to view or modify, you need to back up the original file and make it on the copy.',
            title2:'2. The UniTrust TimeStamp authentication certificate is an electronic certificate with legal effect issued by the Unitrust Timestamp Service Center, which is used to prove that the content of the electronic data file has remained intact and unchanged since the time of applying for the time stamp; ' +
                'It is the ownership certificate and digital asset certificate of the applicant\'s possession of the data.',
            title3:'3.The UniTrust TimeStamp certificate includes a trusted timestamp (*.tsa) and the UniTrust TimeStamp Authentication Certificate (.pdf),which can be used separately or in combination as required.  The UniTrust TimeStamp(*.tsa) is a token for verification, and is an encrypted file that cannot be opened for viewing;The UniTrust TimeStamp authentication certificate(*.pdf) is a certificate that can be viewed and records the relevant information when the data file applies for authentication.',
            title4:'Special tips'
        },
        externalLink:{
            link1:'Share',
            link2:'Electronic evidence',
            link3:'Rights defender APP',
            link4:'Find lawyer',
            link5:'Monitoring service',
        }
    },
    common:{
        footer:'Copyright: Beijing UniTrust Technology Service Co.,  &nbsp;&nbsp;京ICP备 No. 13046264-2'
    }
}