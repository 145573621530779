import Vue from 'vue'
import 'ant-design-vue/dist/antd.css';
import {
    Row,
    Col,
    Dropdown,
    Menu,
    Tooltip,
    Select,
    Modal,
    Icon,
    FormModel,
    Radio,
    Button,
    Divider,
    DatePicker,
    Input,
    InputNumber,
    Carousel
} from 'ant-design-vue';

Vue.use(Row)
Vue.use(Col)
Vue.use(Dropdown)
Vue.use(Menu)
Vue.use(Tooltip)
Vue.use(Select)
Vue.use(Modal)
Vue.use(Icon)
Vue.use(FormModel)
Vue.use(Radio)
Vue.use(Button)
Vue.use(Divider)
Vue.use(DatePicker)
Vue.use(Input)
Vue.use(InputNumber).use(Carousel)