export default {
    haveRecharged:'I have recharged. Please update this page.',
    haveRechargedBtn:'Update',
    applyTimeToast:{
        selectText:'Please select file protected types.',
        uploadText:'Please upload the verified files.',
        kbLeftText:'The file',
        kbRightText:'s size is 0kb and cannot be verified. It has been removed from the list for you.',
        fileNameText:'The file name length is allowed to be 100 characters at most. Please modify it.',
        emptyText:'The file name length is allowed to be 100 characters at most. Please modify it.',
        zishuText:"Please fill in the applicant's self-description.",
        copyText:'Copy succeeded.',
        applicantText:'The length of the applicant\'s information should be less than 50 characters.',
        applicantNameText:'Please fill in the applicant’s name.',
        certificateText:'Please select certificate type.',
        idText:'Please fill in the ID number.',
        numberIncorrText:'The format of certificate number is incorrect.',
    },
    dialogApplyTimeText:{
        selectDia:{
            info:'Please select the file/folder you want to apply for:',
            info1:'Set Protected Types',
            btn1:'Select File',
            btn2:'Select Folder',
            tip:'Tips: Files are authenticated using UniTrust TimeStamp without uploading the original to protect your privacy and secret, which cannot be modified in any form after application. Please keep them properly by yourself, otherwise they cannot be verified.',
        },
        certification:{
            info1:'Update certification status:',
            info2:'I have completed certification.',
            btn1:'Confirm',
        },
        templates:{
            title: 'Statement Templates',
            title1: 'I am the natural person/organization that owns the work/document:',
            title2: 'I share the work/document with others:',
            title3: 'I apply for others. The work/document belongs to my client and I am his agent:',
            input1: 'The work/document is authenticated by UniTrust TimeStamp. The copyright (or other rights) belongs to (name of the obligee whose ID number is ()). No unit or individual is allowed to use this work in any way without authorization.',
            input2: 'The work/document is authenticated by UniTrust TimeStamp. The copyright (or other rights) belongs to (name of the obligee and ***). No unit or individual is allowed to use this work in any way without authorization.',
            input3: 'The work/document is authenticated by UniTrust TimeStamp. The applicant applies for it as an agent, and the copyright belongs to (obligee information).',
            bot1: 'Statement: The applicant is fully responsible for the content and authenticity of the statement, which will display on the UniTrust TimeStamp Authentication Certificate. It does not mean that Beijing UniTrust Technology Service Co., Ltd. recognizes its authenticity and does not guarantee any purpose of proof',
        },
        confirmCancel:{
            Confirm:'Confirm',
            Cancel:'Cancel',
        }
    },
    applySteps:{
        applicantStepsText: 'Enter the applicant name，which will be displayed on the certificate',
        selectStepsText: 'Options',
        idNumberStepsText: 'Enter the ID Number',
        backText:'Back',
        idCard1:'ID card',
        idCard2:'Officers Card',
        idCard3:'Passport',
        idCard4:'Soldier Card',
        idCard5:'Business License',
        idCard6:'Unified Organization Code Certificate',
        idCard7:'Unified Social Credit Code Certificate',
        idCard8:'Taiwanese Syndrome Sard',
        idCard9:'Hong Kong and Macao Pass Card',

    },
    timestampText:{
        timestamp:'(timestamp)',
        tipsText:'Tips',
        rechargeText:'Recharge',
        rechargedText:'I have recharged',
        costText1:'The file consumes ',
        costText2:'File storage ',
        costText3:'free',
        costText4:'File authentication ',
        consumesText:'consumes ',
        totalText:'Total number:',
        billingRules:'Tips:',
        billingRules1:'The authentication and storage of files both require the consumption of timestamps. Please refer to the ',
        billingRules2:'billing rules ',
        billingRules3:'for the consumption standard of timestamps;',
        billingRules4:'Timestamps will be automatically deducted when the storage of stored authentication files expires. If your timestamps account balance is insufficient, timestamps will be automatically deducted when the balance is recharged.'
    },
    balanceText:{
        rechargeText:'Your main account balance is insufficient. Please contact your main account to recharge.',
        allocateText:'Your stamp number has reached the upper limit. Please contact the main account to allocate.',
        beforeUseText:'Your balance is insufficient. Please recharge before use.',
        normalUseText:'The account is normal.',
        consumeTextLeft1:'This application needs to consume',
    },
    uploadingText:{
        Applying:'Applying for UniTrust TimeStamp authentication',
        title1:'Unauthenticated',
        title2:'Authenticating',
        title3:'Successful',
        title4:'Failed',
        title5:'Authenticated',
        title6:'Failed',
        title7:'Uploading',
        title8:'Unuploaded',
        title9:'Uploading',
        title10:'Successful',
        title11:'Failed',
        title12:'Uploaded',
    },
    browserText:{
        info1:'Files that have not been successfully authenticated after closing will not continue to be authenticated. Are you sure to return for modification?',
        info2:'Files that have not been successfully uploaded after closing will not continue to be uploaded. Are you sure to return for modification?',
        info3:'The current browser does not support drag-function. Please replace Google, Firefox or other advanced browsers before using this function.',
        info4:'Only files can be dragged. Please click the "Select Folder" button for folders.',
        info5:'The ID number you entered is illegal.',
        info6:'The browser does not support the function of automatic copying.',
        info7:'Service is abnormal . Please contact the customer service.',
        info8:'I have completed the authentication.',
        info9:'Update the authenticated status.',
        info10:'The current file will be authenticated successfully. Please handle it later.',
        info11:'The current file will be uploaded successfully. Please handle it later.',
    }
}