import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang'
import TsaUI from 'tsa-ui';
import 'tsa-ui/lib/theme-chalk/index.css';
import {set, get } from '@/utils/localStorage'
import { hrefOpen } from '@/utils/filter'
import '@/icons/index.js'
import Clipboard from 'clipboard';
import less from 'less'
Vue.use(less)
import '@/utils/permission';
import VideoPlayer from 'vue-video-player'
import MetaInfo from 'vue-meta-info'
//导航要求与官网统一，需要引入ant
import './config/antd'
import './assets/css/common.less'
import '@/assets/css/iconfont.css'

require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
Vue.use(MetaInfo)
Vue.use(TsaUI)
Vue.config.productionTip = false
Vue.prototype.Clipboard = Clipboard;
Vue.prototype.$http = process.env.VUE_APP_BASEURL + '/';
Vue.prototype.$baseURL = process.env.VUE_APP_APIURL + '/';
Vue.prototype.$setlocalStorage = set;
Vue.prototype.$getlocalStorage = get;
Vue.prototype.$hrefOpen = hrefOpen;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    mounted() {
        document.dispatchEvent(new Event('render-event'))
    }
}).$mount('#app')