export default {
    haveRecharged:'我已充值完成，请刷新页面。',
    haveRechargedBtn:'刷新页面',
    applyTimeToast: {
        selectText: '请先选择文件保护类型。',
        uploadText: '请上传要固化的文件。',
        kbLeftText: '文件。 ',
        kbRightText: '大小为0kb,无法确权,已为您从列表删除。',
        fileNameText: '文件名最多支持100字符,请修改',
        emptyText: '文件名不支持为空',
        zishuText: '请填写申请人自述声明',
        copyText: '复制成功',
        applicantText: '申请人信息需少于50个字',
        applicantNameText: '请填写申请人名称',
        certificateText: '请选择证件类型',
        idText: '请填写证件号码',
        numberIncorrText: '证件号码格式不正确',
    },
    dialogApplyTimeText: {
        selectDia: {
            info: '请选择待申请的文件或文件夹',
            info1: '设置文件类型',
            btn1: '选择文件',
            btn2: '选择文件夹',
            tip:'提示：仅对文件进行可信时间戳认证，不会上传原文件，保护您的隐私和秘密；申请后文件不能有任何形式的修改，请自行妥善保管，否则无法通过验证',
        },
        certification: {
            info1: 'Update certification status:',
            info2: 'I have completed certification.',
            btn1: 'Confirm',
        },
        templates: {
            title: '自述声明模板',
            title1: '我是拥有该文件的自然人/组织：',
            title2: '我和其他人共同拥有该文件：',
            title3: '替其他人申请，该文件归我的客户所有，但我是他的代理人：',
            input1: '本作品/文件由联合信任可信时间戳认证,版权（或其他权利）属于（权利人名称）所有，身份证号码（），未经授权,任何单位及个人禁止以任何方式使用本作品。',
            input2: '本作品/文件由联合信任可信时间戳认证，版权（或其他权利）属于（权利人名称及XXX）共同所有，未经授权，任何单位及个人禁止以任何方式使用本作品。',
            input3: '本作品/文件由联合信任可信时间戳认证，申请人作为代理人代为申请，权利属于（权利人信息）所有。',
            bot1: '声明：申请人对文件内容自述并对真实性承担全部责任，在《可信时间戳认证证书》上展示不代表北京联合信任技术服务有限公司认可其真实性，不担保任何证明目的。',
        },
        confirmCancel: {
            Confirm: '确定',
            Cancel: '取消',
        }
    },
    applySteps: {
        applicantStepsText: '请输入申请人名称，用于证书显示',
        idNumberStepsText: '请输入证件号',
        selectStepsText: '请选择',
        backText: '返回',
        idCard1: '身份证',
        idCard2: '军官证',
        idCard3: '护照',
        idCard4: '士兵证',
        idCard5: '营业执照',
        idCard6: '企业组织结构代码证',
        idCard7: '统一社会信用代码证',
        idCard8: '台胞证',
        idCard9: '港澳通行证',
    },
    timestampText:{
        timestamp:'个',
        tipsText:'提示',
        rechargeText:'去充值',
        rechargedText:'我已充值',
        costText1:'本文件共消耗',
        costText2:'“文件存储”',
        costText3:'免费',
        costText4:'“文件认证”需消耗',
        consumesText:'需消耗',
        totalText:'个文件',
        billingRules:'提示：',
        billingRules1:'文件认证及存储均需要消耗时间戳，具体标准请参见',
        billingRules2:'计费规则',
        billingRules3:'',
        billingRules4:'存证认证的文件将在存储到期后自动扣除时间戳，如果您的时间戳账户余额不足，系统将在余额充足时扣除时间戳。'

    },
    balanceText:{
        rechargeText:'您的主账号余额不足，请联系主账号充值',
        allocateText:'您的使用个数已达上限，请联系主账号分配',
        beforeUseText:'您的余额不足，请充值后再使用',
        normalUseText:'账户正常',
        consumeTextLeft1:'本次申请需要消耗时间戳',
    },
    uploadingText:{
        Applying:'正在申请可信时间戳认证',
        title1:'待认证',
        title2:'正在认证',
        title3:'认证成功',
        title4:'固化失败',
        title5:'已认证成功',
        title6:'认证失败',
        title7:'正在存证',
        title8:'待上传',
        title9:'正在上传',
        title10:'存证成功',
        title11:'存证失败',
        title12:'已上传成功',
    },
    browserText:{
       info1:'关闭后“未认证成功”的文件将不再继续认证，是否确认返回修改',
       info2:'关闭后“未上传成功”的文件将不再继续上传，是否确认返回修改',
       info3:'当前浏览器不支持拖拽功能，请更换谷歌，火狐等高级浏览器后再使用该功能。',
       info4:'拖动仅支持文件，文件夹请点击“选择文件夹”按钮。',
       info5:'证件号输入不合法',
       info6:'该浏览器不支持自动复制',
       info7:'服务异常，请联系客服',
       info8:'我已认证完成',
       info9:'更新认证状态',
       info10:'当前文件即将认证成功，请稍后处理',
       info11:'当前文件即将上传成功，请稍后处理',
    }
}