/*
 * @Author: webzhangle 759728395@qq.com
 * @Date: 2024-07-02 09:54:14
 * @LastEditors: webzhangle 759728395@qq.com
 * @LastEditTime: 2024-07-02 13:54:44
 * @FilePath: /opus-copyright-ui/src/components/Header/config/navContent.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const navContent={
    topNavBar:[
        {
            name: '关于我们',
            link: process.env.VUE_APP_TSAPLUS+'/about.html',
            target: '_blank',
        },
        {
            name: '网站导航',
            link: process.env.VUE_APP_TSAPLUS,
            target: '_blank',
        },
        {
            name: '网站地图',
            link: process.env.VUE_APP_TSAPLUS,
            target: '_blank',
        },
        {
            name: 'XML地图',
            link: process.env.VUE_APP_TSAPLUS,
            target: '_blank',
        },
    ],
    bigNavBar:[
        {
            name: '首页',
            link: process.env.VUE_APP_TSAPLUS,
            target: '_self',
        },
        {
            name: '权利卫士APP',
            link: process.env.VUE_APP_TSAPLUS+'/quanliweishi.html',
            target: '_self',
        },
        {
            name: '电子取证',
            link: process.env.VUE_APP_EV,
            target: '_self',
        },
        {
            name: '知识产权保护',
            link: process.env.VUE_APP_COPYRIGHT,
            target: '_self',
            currentNav:true
        },
        {
            name: '电子签约',
            link: process.env.VUE_APP_SIGN,
            target: '_blank',
            nofollow: true
        },
        {
            name: '邮件认证',
            link: process.env.VUE_APP_EMAIL,
            target: '_self',
        },
        {
            name: 'AIGC-PAS',
            link: process.env.VUE_APP_AIGC,
            target: '_self',
        },
    ],
    samllNavBar:[
        {
            name: '知产鉴定',
            link: process.env.VUE_APP_CAPITAL,
            target: '_blank',
        },
        {
            name: '可信时间戳验证中心',
            link: process.env.VUE_APP_VERIFY,
            target: '_blank',
        },
        // {
        //     name: '知识库',
        //     link: process.env.VUE_APP_TSAPLUS+'/wiki.html',
        //     target: '_blank',
        // },
        // {
        //     name: '行业方案',
        //     link: process.env.VUE_APP_TSAPLUS+'/industry.html',
        //     target: '_blank',
        // },
        // {
        //     name: '典型案例',
        //     link: process.env.VUE_APP_TSAPLUS+'/case.html',
        //     target: '_blank',
        // },
    ],
}