/**
 * Parse the time to string
 * @param {(number)} time
 * @returns {string}
 */
export function formatDate(value) {
  if (!value) {
    return ''
  }
  let date = new Date(value)
  let y = date.getFullYear()
  let MM = date.getMonth() + 1
  MM = MM < 10 ? '0' + MM : MM
  let d = date.getDate()
  d = d < 10 ? '0' + d : d
  let h = date.getHours()
  h = h < 10 ? '0' + h : h
  let m = date.getMinutes()
  m = m < 10 ? '0' + m : m
  let s = date.getSeconds()
  s = s < 10 ? '0' + s : s
  return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
  // return y + '-' + MM + '-' + d
}

export function formatYMD(value) {
  if (!value) {
    return ''
  }
  let date = new Date(value)
  let y = date.getFullYear()
  let MM = date.getMonth() + 1
  MM = MM < 10 ? '0' + MM : MM
  let d = date.getDate()
  d = d < 10 ? '0' + d : d
  let h = date.getHours()
  h = h < 10 ? '0' + h : h
  let m = date.getMinutes()
  m = m < 10 ? '0' + m : m
  let s = date.getSeconds()
  s = s < 10 ? '0' + s : s
  return y + '-' + MM + '-' + d
  // return y + '-' + MM + '-' + d
}

/**
 * deepClone=>
 * @param {(object)}
 * @returns {object}
 */

export function deepClone(obj) {
  var target = {}
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === 'object') {
        target[key] = deepClone(obj[key])
      } else {
        target[key] = obj[key]
      }
    }
  }
  return target
}

/**
 * mobileFilter=>手机号中间隐藏
 * @param {(Number)}
 * @returns {String}
 */
export function mobileFilter(val) {
  if (val) {
    let reg = /^(.{3}).*(.{4})$/
    return val.replace(reg, '$1****$2') || '-'
  }
}

/**
 * codeFilter=>
 * @param {(Number)}
 * @returns {String}
 */
export function codeFilter(val) {
  console.log(val)
  if (val.length > 5) {
    let star = ''
    for (var i = 0; i < val.length - 2; i++) {
      star = star + '*'
    }
    return (
      val.substring(0, 1) + star + val.substring(val.length - 1, val.length)
    )
  }
}

/**
 * ArrayFiflter=>数组过滤
 * @param {(Array,Number)}
 * @returns {Array}
 */
export function group(array, subGroupLength) {
  let index = 0
  let newArray = []
  while (index < array.length) {
    newArray.push(array.slice(index, (index += subGroupLength)))
  }
  return newArray
}

/**
 * nameFilter=>
 * @param {(Number||String)}
 * @returns {String}
 */
export function processName(str) {
  if (null != str && str != undefined) {
    let star = ''
    if (str.length <= 2) {
      return '*' + str.substring(1, 2)
    } else if (str.length === 3) {
      return '*' + str.substring(1, 3)
    } else {
      for (var i = 0; i < str.length - 2; i++) {
        star = '*' + star
      }
      return (
        str.substring(0, 2) + star + str.substring(str.length - 2, str.length)
      )
    }
  }
}

/**
 * UrlSearch=>
 * @param {(window.url)}
 * @returns {String||Number}
 */
export function GetUrlParam(paraName) {
  var url = document.location.toString()
  var arrObj = url.split('?')

  if (arrObj.length > 1) {
    var arrPara = arrObj[1].split('&')
    var arr

    for (var i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split('=')

      if (arr != null && arr[0] == paraName) {
        return arr[1]
      }
    }
    return ''
  } else {
    return ''
  }
}

//删除给定对象中值为空的键值对
export function filterParams(obj) {
  var _newPar = {}
  for (var key in obj) {
    if (
      (obj[key] === 0 || obj[key] === false || obj[key]) &&
      obj[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '' &&
      (obj[key] !== null || obj[key] !== NaN || obj[key] !== undefined)
    ) {
      _newPar[key] = obj[key]
    }
  }
  return _newPar
}

//类型数字转文字
export function sourceType(typeNum) {
  switch (typeNum) {
    case 975260:
      return '用户中心'
      break
    case 955210:
      return '时间戳'
      break
    case 925220:
      return '壹签'
      break
    case 955740:
      return '电子证据'
      break
    case 955255:
      return 'RA'
      break
    case 995280:
      return '经销商前台'
      break
    case 915290:
      return '经销商后台'
      break
    case 952310:
      return '知识产权'
      break
    case 954210:
      return '权利卫士'
      break
    case 975281:
      return '维权平台'
      break
    case 800001:
      return '天平阳光'
      break
    case 801001:
      return '百度'
      break
  }
}

//将给定数组，拆分成若干个数组的集合
export function splitArray(array, num) {
  let index = 0
  let newArray = []
  while (index < array.length) {
    newArray.push(array.slice(index, (index += num)))
  }
  return newArray
}

//整理数组对象，账户概览页饼图专用
export function regroupData(array) {
  let newArray = []
  let obj
  for (let i = 0; i < array.length; i++) {
    obj = array[i]
    obj.name = sourceType(array[i].sourceType)
    obj.value = array[i].tsCount
    newArray.push(obj)
  }
  return newArray
}

//计算数组对象中某个字段的总和，账户概览页饼图专用
export function numTotal(array) {
  let total = 0
  for (let i = 0; i < array.length; i++) {
    total += array[i].tsCount
  }
  return total
}

//根据时间戳计算时间差
export function diffTime(startDate, endDate) {
  var diff = endDate - startDate //时间差的毫秒数
  //计算出相差天数
  var days = Math.floor(diff / (24 * 3600 * 1000))

  //计算出小时数
  var leave1 = diff % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
  var hours = Math.floor(leave1 / (3600 * 1000))
  //计算相差分钟数
  var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
  var minutes = Math.floor(leave2 / (60 * 1000))

  //计算相差秒数
  var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
  var seconds = Math.round(leave3 / 1000)

  var returnStr = seconds + '秒'
  if (minutes > 0) {
    returnStr = minutes + '分' + returnStr
  }
  if (hours > 0) {
    returnStr = hours + '小时' + returnStr
  }
  if (days > 0) {
    returnStr = days + '天' + returnStr
  }
  //console.log((days * 24 + hours) + '：' + minutes + '：' + seconds)
  let time = {
    hours: days * 24 + hours,
    minutes,
    seconds,
  }
  return time
}

//根据给定字段，在给定对象数组中去重，保留最先找到的。
export function uniqueArr(arr, val) {
    let map = new Map();
    return arr.filter(item => !map.has(item[val]) && map.set(item[val], 1))
}
//根据给定字段，去重（2字段）
export function uniqueArrFile(arr,key1,key2) {
    const cache = [];
    for (const v of arr) {
        // 检查缓存中是否已经存在
        if (cache.find(c => c[key1] === v[key1] && c[key2] === v[key2])) {
            // 已经存在说明以前记录过，现在这个就是多余的，直接忽略
            continue;
        }
        // 不存在就说明以前没遇到过，把它记录下来
        cache.push(v);
    }
    // 记录结果就是过滤后的结果
    return cache;
}

export function hrefOpen(url) {
  var link = document.createElement('a')
  link.target = '_blank'
  link.href = url
  link.id = 'href'
  document.body.appendChild(link)
  link.click()
}

//文件类型数字转文字
export function fileType(typeNum) {
  switch (typeNum) {
    case 101:
      return '文字作品'
      break
    case 102:
      return '音乐作品'
      break
    case 103:
      return '音像'
      break
    case 104:
      return '美术作品'
      break
    case 105:
      return '设计作品'
      break
    case 106:
      return '雕塑'
      break
    case 107:
      return '工程图纸'
      break
    case 108:
      return '软件项目'
      break
    case 109:
      return '商业秘密'
      break
    case 110:
      return '摄影作品'
      break
    case 111:
      return '产品设计图'
      break
    case 112:
      return '视听作品'
      break
    case 113:
      return '汇编作品'
      break
    case 114:
      return '产品原型图'
      break
    case 115:
      return '产品流程图'
      break
    case 116:
      return 'UI设计图'
      break
    case 117:
      return '需求说明文档'
      break
    case 118:
      return '测试用例'
      break
    case 119:
      return '工业项目'
      break
    case 120:
      return '硬件项目'
      break
    case 121:
      return '科研成果'
      break
    case 122:
      return '数据资产'
      break
    case 123:
      return '商标设计'
      break
    case 124:
      return '专利文档'
      break
    case 125:
      return '讲义课件'
      break
    case 999:
      return '其它'
      break
  }
}

export function fileTypeEn(typeNum) {
  switch (typeNum) {
    case 101:
      return 'Writings'
      break
    case 102:
      return 'Musical Work'
      break
    case 103:
      return 'Audiovisual Work'
      break
    case 104:
      return 'Art Work'
      break
    case 105:
      return 'Design Work'
      break
    case 106:
      return 'Sculpture Work'
      break
    case 107:
      return 'Engineering Drawing'
      break
    case 108:
      return 'Software Project'
      break
    case 109:
      return 'Business Secret'
      break
    case 110:
      return 'Photographic Work'
      break
    case 111:
      return 'Product Design Drawing'
      break
    case 112:
      return 'Audiovisual Work'
      break
    case 113:
      return 'Compiled Work'
      break
    case 114:
      return 'Product Prototype Drawing'
      break
    case 115:
      return 'Product “Flow Chart'
      break
    case 116:
      return 'Design Drawing'
      break
    case 117:
      return 'Requirement Description'
      break
    case 118:
      return 'Test “Case'
      break
    case 119:
      return 'Industrial Project'
      break
    case 120:
      return 'Hardware Project'
      break
    case 121:
      return 'Scientific Payoff'
      break
    case 122:
      return 'Data Asset'
      break
    case 123:
      return 'Logo Design'
      break
    case 124:
      return 'Patent'
      break
    case 125:
      return 'Teaching Materials'
      break
    case 999:
      return 'Other'
      break
  }
}

// 分享日期过滤
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
  }
  const time_str = format.replace(/{(y|m|d)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

//判断一个数组是否包含另一个数组
export function isInclude (array1, array2) {
  return array2.every(val => array1.includes(val));
};

//生成uid
export const generateUUID = () => {
  let d = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
          let r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c === "x" ? r : (r & 0x7) | 0x8).toString(16);
      }
  );
  return uuid;
};

//删除cookie
export function delCookie() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie =name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
    if (cookies.length > 0) {
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            var domain = location.host.substr(location.host.indexOf("."));
            document.cookie =name +"=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=" +domain;
        }
    }
}

//将文件byte转对应大小
export function fileSize(value) {
  let size=value
  let num = 1024.00; //byte
  if (!size)
      return "";
  if (size < num)
      return size + "B";
  if (size < Math.pow(num, 2))
      return (size / num).toFixed(2) + "K"; //kb
  if (size < Math.pow(num, 3))
      return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
  if (size < Math.pow(num, 4))
      return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
  return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
}