<template>
    <div class="layout">
        <div class="layout-container">
            <!-- 侧边栏 -->
            <Sidebar />
            <!-- 容器 -->
            <div class="layout-content">
                <!-- 头部 -->
                <div class="layout-header">
                    <TsaTopMenu v-if="flag" ref="TsaTopMenu" :routeMap="routeMap" :navList="topMenuNavList" :messageInfo="messageInfo" :userInfo="userInfoMap">
                        <div class="activities" slot="leftBox">
                            <a :href="dealerUrl" target="_blank">
                                <img src="@/assets/images/activities.png" alt="">
                            </a>
                        </div>
                        <div class="changeLang" slot="slotBox">
                            <a :href="lawyerUrl" class="lawyer" target="_blank">
                                <i class="el-icon-ali-tsa-user"></i>
                                <span>{{$t('header.lawer')}}</span>
                            </a>
                            <template v-if="$route.path!=='/time/Aigc' && $route.path!=='/time/ApiAccess' && $route.path!=='/time/AigcResult'">
                                <span @click="changeLang" v-if="$getlocalStorage('language')==='en'">
                                    <img src="@/assets/images/header/zh.png" alt=""><b>中文</b>
                                </span>
                                <span @click="changeLang" v-else>
                                    <img src="@/assets/images/header/en.png" alt=""><b>English</b>
                                </span>
                            </template>
                        </div>
                    </TsaTopMenu>
                </div>
                <!-- 内容 -->
                <div class="layout-main">
                    <TsaFixedContactCustomer />
                    <router-view slot="content"></router-view>
                </div>
            </div>
        </div>
        <div class="zhe" v-if="show">
            <div class="reward" @click="closePop">
                <span><img src="@/assets/images/close2.png" alt=""></span>
                <img v-if="$i18n.locale == 'en'" src="@/assets/images/rewardEn.png" alt="">
                <img v-else src="@/assets/images/reward.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from "@/views/layout/Sidebar.vue";
import { unreadCount } from "@/api/accountService";
import { findBalance,logout } from "@/api/service";
export default {
    components: {
        Sidebar,
    },
    data() {
        return {
            // bubble:true,
            flag:true,
            show:false,
            noticeList:[],
            messageInfo:{},
            lawyerUrl:process.env.VUE_APP_LAWCASE+'?sourceType=952310',
            dealerUrl:process.env.VUE_APP_DEALER,
        };
    },
    watch:{
        '$route'(to,from) {
            this.flag = false;
            this.$nextTick(()=>{
                this.flag = true;
            })
        }
    },
    computed: {
        userInfoMap(){
            let userInfo=this.$store.state.userInfo.me
            return {
                userName:userInfo.loginName,
                children:[
                    {
                        name:this.$t('layout'),
                        onClick:()=>{
                            if(this.$getlocalStorage('queryData')?.partnerType==1){
                                logout().then(()=>{
                                    localStorage.removeItem('queryData');
                                    localStorage.removeItem('popups');
                                    window.location.href = process.env.VUE_APP_CAPITAL;
                                }).catch((err)=>{
                                    console.log(err)
                                })
                            }else{
                                localStorage.removeItem('queryData')
                                localStorage.removeItem('popups');
                                if (process.env.NODE_ENV === 'development') {
                                    window.location.href = '/api/api/logout'
                                } else {
                                    window.location.href = '/opus/api/logout'
                                }
                            }
                        }
                    }
                ]
            }
        },
        topMenuNavList(){
            if( this.$i18n.locale==='en' || this.$getlocalStorage('language')==='en'){
                return [
                    {
                        title:'Instructions',
                        target: 'blank',
                        link: process.env.VUE_APP_TSAPLUS +'/baike/156.html',
                    },
                    {
                        title:'Recharge',
                        target: 'blank',
                        link: process.env.VUE_APP_ACCOUNTUI +'/userAccount/buy?clientId=952310&partnerType=0',
                    },
                    {
                        title:'Sub-account management',
                        target: 'blank',
                        link: process.env.VUE_APP_ACCOUNTUI +'/userCenter/userInfo',
                    },
                    {
                        title:'User Center',
                        target: 'blank',
                        link: process.env.VUE_APP_ACCOUNTUI +'/userCenter/userInfo',
                    },
                ]
            }else{
                return [
                    {
                        title:'使用指引',
                        target: 'blank',
                        link: process.env.VUE_APP_TSAPLUS +'/baike/156.html',
                    },
                    {
                        title:'立即充值',
                        target: 'blank',
                        link: process.env.VUE_APP_ACCOUNTUI +'/userAccount/buy?clientId=952310&partnerType=0',
                    },
                    {
                        title:'子账号管理',
                        target: 'blank',
                        link: process.env.VUE_APP_ACCOUNTUI +'/subAccount/subList',
                    },
                    {
                        title:'用户中心',
                        target: 'blank',
                        link: process.env.VUE_APP_ACCOUNTUI +'/userCenter/userInfo',
                    }
                ]
            }
        },
        routeMap(){
            return {
                title: this.$i18n.locale==='zh' ? this.$route.meta.title : this.$route.meta.titleEn
            }
        }
    },
    async created() {
        if(this.$store.state.userInfo.userInfo.popupsStatus==1 && !this.$getlocalStorage('popups')){
            this.show=true;
        }
        let balance = await findBalance(0);
        this.$store.commit('balance/setBalance', balance);
        let {sourceTypeUnreadList,totalUnreadCount}=await unreadCount();
        this.messageInfo={
            sourceTypeUnreadList,
            totalUnreadCount,
            messageUrl:process.env.VUE_APP_ACCOUNTUI+'/messageCenter/history',
            messageUrlParams:{
                clientId:'952310',
                partnerType:this.$getlocalStorage('queryData')?.partnerType==1? 1:0
            }
        }
    },

    mounted() {
        // document.addEventListener('click',(e)=>{
        //     if(!this.$refs.bubble?.contains(e.target)){
        //         this.bubble = false;
        //     }
        // })
    },

    methods: {
        closePop(){
            this.show=false;
            this.$setlocalStorage('popups',true)
        },
        changeLang(){
            if(this.$i18n.locale=='zh'){
                this.$i18n.locale='en'
                this.$setlocalStorage('language','en')
            }else{
                this.$i18n.locale='zh'
                this.$setlocalStorage('language','zh')
            }
        },
    },
};
</script>
<style lang='less' scoped>
.layout{
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow-x:auto;
    background: linear-gradient(142deg,#e7eeff,#ebf5ff);
    .layout-container{
        display: flex;
        width: 100%;
        .layout-content{
            display: flex;
            flex: 1;
            flex-direction: column;
            .layout-header{
                .activities{
                    padding-left: 21px;
                    a{
                        display: block;
                        img{
                            width: 235px;
                            height: 34px;   
                        }
                    }
                }
                .changeLang{
                    .lawyer{
                        font-size: 14px;
                        margin-right:20px;
                        i{
                            display: inline-block;
                            vertical-align: -2px;
                            font-size: 22px;
                            color: #333;
                        }
                        span{
                            color: #333;
                        }
                        &:hover{
                            color: #1F5EFF;
                            i,span{
                                color: #1F5EFF;
                            }
                        }
                    }
                    span{
                        cursor: pointer;
                        display: inline-block;
                        padding-right:20px;
                        img{
                            margin-top: -2px;
                            margin-right: 5px;
                        }
                    }
                }
            }
            .layout-main{
                flex: 1;
                overflow-y: auto;
                box-sizing: border-box;
                padding: 20px 50px 20px 0;
                .wrap{
                    width: 100%;
                    box-sizing: border-box;
                    min-height: 100%;
                    background: #fff;
                    border-radius: 20px;
                }
            }
        }
    }
}
.main{
    display: flex;
    .el-header{
        padding-top: 30px;
    }
}
.bubble{
    width: 337px;
    height: 105px;
    position: fixed;
    top:270px;
    left: 130px;
    z-index: 10000;
    background:url('../../assets/images/qp.png');
    .bubble_cnt{
        width:100%;
        box-sizing: border-box;
        color: #fff;
        position: relative;
        padding: 20px 0 0 40px;
        font-size: 14px;
        .qp_close{
            font-size: 25px;
            position: absolute;
            right:-25px;
            top:-8px;
            color:rgba(0, 0, 0, 0.5);
            cursor: pointer;
        }
        h4{
            color:#FFC629;
            margin-bottom: 16px;
        }
    }
}
.zhe{
    display: flex;
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .3);
    justify-content: center;
    align-items: center;
    .reward{
        cursor: pointer;
        position: relative;
        width:698px;
        height: 500px;
        span{
            position: absolute;
            top:0;
            right: 0;
        }
    }
}
@media screen and (max-width:1450px) {
    .layout{
        .layout-container{
            .layout-content{
                .layout-header{
                    .activities{
                        display: none;
                    }
                }
            }
        }
    }
}
</style>

