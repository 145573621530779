import request from "./tsa.js";
import qs from 'qs'


export const questionAnswerSearch = data =>
    request({
        url: `/questionAnswer/search`,
        method: "get",
        params: data,
    });

export const guideSearch = data =>
    request({
        url: `/guide/search`,
        method: "get",
        params: data,
    });

//栏目对应案例
export const precedent = params =>
    request({
        url: `/precedent/search`,
        method: "get",
        params
    });

//常见问题
export const questionAnswer = params =>
    request({
        url: `/questionAnswer/search`,
        method: "get",
        params
    });

//获取时间戳
export const getCurrentTime = params =>
    request({
        url: `/time/getCurrentTime`,
        method: "get"
    });

// 判例数
export const precedentStatistics = params =>
    request({
        url: `/precedentStatistics/searchOne`,
        method: "get"
    });

// 案例场景
export const precedentSearchByCondition = params =>
    request({
        url: `/precedent/searchByCondition`,
        method: "get",
        params
    });

// 短信
export const sendSmsCode = data =>
    request({
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'},
        url: `/captcha/sendSmsCode`,
        method: "post",
        data:qs.stringify(data)
    });

    
// 钉钉
export const sendTools = data =>
request({
    url: `/sendTools`,
    method: "post",
    data
});

export const sendDingDing2= data =>
request({
    Headers: {
        "content-type": "application/json;charset=UTF-8"
    },
    url: `/sendTools`,
    method: "post",
    data
});