const state = {
    axiosPromiseCancel: [],
}
const mutations = {
    //将请求依次添加到数组中
    setAxiosPromiseCancelArr(state, cancel) {
        state.axiosPromiseCancel.push(cancel)
    },
    //取消数组中的所有请求，并初始化[]
    clearAxiosPromiseCancelArr(state) {
        if(state.axiosPromiseCancel.length!==0){
            state.axiosPromiseCancel.forEach(e => {
                e && e.f()
            });
            state.axiosPromiseCancel=[];
        }
    },
    // 取消当前数组中重复的请求
    clearCommonAxiosPromiseCancelArr(state,index) {
        state.axiosPromiseCancel[index].f()
        state.axiosPromiseCancel.splice(index,1)
    }
}
const actions = {

}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};