import request from "./index";
import qs from 'qs'

export const me = () =>
    request({
        method: 'get',
        url: '/me?hidden=true',
        stopLoading: true
    });

export const user = () =>
    request({
        method: 'get',
        url: '/user',
        stopLoading: true
    });

export const logout = () =>
    request({
        method: 'get',
        url: '/partner/logout',
        stopLoading: true
    });


//查询申请人信息
export const opusUserInfo = data =>
    request({
        url: `/opusUserInfo`,
        method: "get",
        stopLoading: true
    });

//保存或更新申请人信息
export const opusUpdateUserInfo = data =>
    request({
        url: `/opusUserInfo`,
        method: "post",
        data: qs.stringify(data),
        headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    });

//批量固化（无源文件）(老版本)
// export const opusApply = data =>
//     request({
//         url: `/opus/apply`,
//         method: "post",
//         headers: { 'content-type': "application/json;charset=UTF-8" },
//         data: JSON.stringify(data),
//         stopLoading: true
//     });

//批量固化（无源文件）(新版本)
export const opusApply = data =>
    request({
        url: `/opus/apply/single`,
        method: "post",
        headers: { 'content-type': "application/json;charset=UTF-8" },
        data: JSON.stringify(data),
        stopLoading: true
    });

//批量固化（有源文件）
export const opusApplySource = data =>
    request({
        url: `/opus/apply/source`,
        method: "post",
        headers: { 'content-type': "application/json;charset=UTF-8" },
        data: JSON.stringify(data),
        stopLoading: true
    });

//查询固化记录
export const findOpusInfo = serialNo =>
    request({
        url: `/opus/findOpusInfo/${serialNo}`,
        method: "get",
        stopLoading: true
    });

//查询固化记录
export const findApiApplyResult = serialNo =>
    request({
        url: `/opus/findApplyResult/${serialNo}`,
        method: "get",
        stopLoading: true
    });

//查询当前剩余次数
export const findBalance = number =>
    request({
        url: `/order/balance/${number}`,
        method: "get",
        stopLoading: true
    });

//下载多条记录
export const downloadMultiFile = data =>
    request({
        url: `/opus/download/multi?${qs.stringify(data,{ indices: false })}`,
        method: "get",
        responseType: 'arraybuffer',
    });

//下载单条记录
export const downloadFile = serialNo =>
    request({
        url: `/opus/download/single/${serialNo}`,
        method: "get",
    });

//以保护文件列表
export const opusList = data =>
    request({
        url: `/opus`,
        method: "post",
        params: data
    });

//补交源文件，获取上传地址
export const supplement = data =>
    request({
        url: `/opus/supplement`,
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        data: qs.stringify(data),
        stopLoading: true,
    });

//2.0原件计费补交源文件，获取上传地址
export const supplementSts = data =>
    request({
        url: `/opus/supplement/sts`,
        headers: {
            'content-type': "application/json;charset=UTF-8"
        },
        method: "post",
        data,
        stopLoading: true,
    });
//查询补交文件是否上传成功
export const complete = data =>
    request({
        url: `/opus/complete?${qs.stringify(data)}`,
        method: "PUT",
        stopLoading: true
    });

// 查询当前用户下所有子账号
export const findChild = data =>
    request({
        url: `/opusUserInfo/authorizationAccount`,
        method: "get",
    });


//*********************2.0**************************    
//更新备注
export const updateOpus = data =>
    request({
        url: `/opus/update`,
        method: "PUT",
        stopLoading: false,
        data
    });


// 获取 oss 相关参数 
export const generateStsUploadSignMessage = data =>
    request({
        url: `/opus/generateStsUploadSignMessage`,
        method: "post",
        stopLoading: true,
        headers: {
            'content-type': "application/json;charset=UTF-8"
        },
        data
    });

// 查询原件状态
export const completeStatus = (data) =>
    request({
        url: `/opus/complete?${qs.stringify(data)}`,
        method: "PUT",
        stopLoading: true,
    });

// 查询原件状态
export const lengthCount = data =>
    request({
        url: `/opus/lengthCount`,
        method: "post",
        data,
        stopLoading: true,
    });


// 批量备注
export const multiUpdate = data =>
    request({
        url: `/opus/multi/update`,
        method: "PUT",
        data,
    });

// 原件删除
export const deleteOpusSourceFile = data =>
    request({
        url: `/opus/deleteOpusSourceFile?serialNo=${data.serialNo}`,
        method: "DELETE",
        data
    });

// 获取账单列表
export const getBill = data =>
    request({
        url: `/bill`,
        method: "PUT",
        data,
    });

// 传email为发送账单，不传为下载账单
export const billDown = data =>
    request({
        url: `/bill/down`,
        method: "PUT",
        data,
    });

// 取消sts上传,返还扣费
export const cancelStsUpload = data =>
    request({
        url: `/opus/cancelStsUpload`,
        method: "post",
        data,
        stopLoading: true,
    });

//下载的时候先缴纳欠费才能去下载
export const payArrearageMoney = data =>
    request({
        url: `/opus/payArrearageMoney`,
        method: "post",
        data,
    });


//获取当前子账号的权限
// /**
//      * 知识产权&软著保护原文件删除权限
//      */
// IPR_FILE_DELETE(1),
// /**
//  * 知识产权&软著保护分享权限
//  */
// IPR_SHARE(2),

// /**
//  * 知识产权&软著保护申请人
//  */
// IPR_APPLY(3),
// /**
//  * 知识产权&软著访问
//  */
// IPR_ACCESS(4),

//******返回值permissionValue******
// /**
//      * 可以.
//      */
// ENABLE((byte) 0),

// /**
//  * 不可以.
//  */
//DISABLE((byte) 1);
export const getPermission = params =>
    request({
        url: `/permission`,
        method: "get",
        params
    });

//批量删除原件
export const deleteFileMulti = data =>
    request({
        url: `/opus/deleteOpusSourceFile/multi`,
        method: "delete",
        data,
    });

// AIGC

export const getStsInfo = data =>
    request({
        url: `/aigc`,
        method: "post",
        stopLoading: true,
        headers: {
            'content-type': "application/json;charset=UTF-8"
        },
        data
    });

// 取消sts上传,返还扣费
export const cancelAigcUpload = data =>
    request({
        url: `/aigc/cancelStsUpload`,
        method: "post",
        data,
        stopLoading: true,
    });