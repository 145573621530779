const state = {
    balance: '',
}
const mutations = {
    setBalance(state, model) {
        state.balance = model
    },
}
const actions = {

}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};