export default {
    applyTime: {
        steps: {
            title1: '文件添加及信息填写',
            title2: '申请人添加及权利声明',
            title3: '确定申请，生成认证证书'
        },
        fileType:{
            title1: '商业秘密',
            title2: '工业项目',
            title3: '软件项目',
            title4: '硬件项目',
            title5: '科研成果',
            title6: '数据资产',
            title7: '商标设计',
            title8: '专利文档',
            title9: '文字作品',
            title10: '视听作品',
            title11: '摄影作品',
            title12: '美术作品',
            title13: '音乐作品',
            title14: '设计作品',
            title15: '讲义课件',
            title17:'AIGC',
            title16: '其它',
        },
        ProTypes:{
            title1: '保护类型',
        },
        AutModes:{
            title1: '认证方式',
            title2: '存证',
            title3: '脱敏认证',
            title4: '文件上传到平台存储，可随时下载查阅',
            title5: '不上传文件，仅对文件进行可信时间戳认证，保护隐私和秘密',
        },
        AddFile:{
            title1:'选择文件',
            title2:'点击选择文件（夹）到这里',
            title3:'备注',
            title4:'（选填）请填写本次申请文件的项目名称、标签、关键词等，便于后续查找使用。',
            title5:'平台开放API,为各类知识产权平台和企业提供接口服务。',
            title6:'联系我们',
        },
        Next:{
            title1:'下一步',
            title2:'确定申请',
            title3:'返回修改',
            title4:'上一步',
        },
        Table:{
            title1:'序号',
            title2:'文件名称',
            title3:'类型',
            title4:'脱敏认证',
            title5:'存证',
            title6:'操作',
            title7:'删除',
            title8:'预计消耗时间戳',
        },
        ApplyInfo:{
            title1:'申请人信息',
            title2:'申请人',
            title3:'证件类型',
            title4:'证件号',
            title5:'已实名',
            title6:'申请人自述声明',
            title7:'此处填写内容为申请人的自述声明，申请人对该自述声明信息的真实性承担全部责任，该信息将在《可信时间戳认证证书》上展示，北京联合信任技术服务有限公司不对申请人自述声明信息进行真实性审查、不承担相关法律责任。',
            title8:'声明：北京联合信任技术服务有限公司不对申请人自述声明信息进行真实性审查、不承担相关法律责任。',
            title9:'自述声明模板',
            title10:'上一步',
            title11:'申请人信息及自述声明',
            title12:'文件信息',
            title13:'Documents protected',
            title14:'去实名',

        }
    },
    applyResult:{
        breadcrumb1:'申请时间戳',
        breadcrumb2:'申请结果',
        pdfInfo:{
            title1:"申请成功",
            title2:"您的权属证明和数字资产凭证",
            title3:"下载可信时间戳<sup>®</sup>证书",
            title4:"查看认证详情",
            title5:"继续申请认证",
        },
        SpeciaTips:{
            title1:'1、数据/文件在申请时间戳后除文件名外不能有任何形式的修改和变化，否则无法通过可信时间戳<sup>®</sup>验证。 如需查看或修改需将原始文件备份并在复制件上进行。',
            title2:'2、可信时间戳<sup>®</sup>证书是由联合信任时间戳服务中心签发的具有法律效力的电子凭证，用于证明电子数据/文件自申请时间戳时起，内容保持完整、未被更改；是申请人拥有（占有）该数据/文件的权属证明和数字资产凭证。',
            title3:'3、可信时间戳<sup>®</sup>证书包括可信时间戳®（*.tsa）和可信时间戳<sup>®</sup>认证证书（*.pdf），可根据需要单独或合并使用。其中可信时间戳<sup>®</sup>（*.tsa）是用于验证的token，是一个不能打开查看的加密文件；可信时间戳<sup>®</sup>认证证书（*.pdf）是一个可以查看的证书，记载了数据/文件申请认证时的相关信息。',
            title4:'特别提示'
        },
        externalLink:{
            link1:'分享好友',
            link2:'电子取证',
            link3:'权利卫士下载',
            link4:'找律师',
            link5:'监测服务平台',
        }
    },
    common:{
        footer:'版权所有：北京联合信任技术服务有限公司 &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; 京ICP备13046264号-2'
    }
}