export default {
    layout:'Sign Out',
    message:'Message',
    sidebar:{
        logo:'IPR Protection Platform',
        accountBalance:'Main account balance',
        balance:'Timestamps',
        ceiling:'Current account limit',
        alreadyUse:'The current account has been used',
        gobuy:'Buy',
        rules:'Billing rules',
    },
    table:{
        noData:'No data available',
    },
    customer:{
        title:'',
        phone:'Our phone',
        wechat:'Customer WeChat',
        workTime:'Working hours: From Monday to Friday',
    },
    protectFile:{
        backText:'Back',
        breadcrumb1:'Apply for timestamp',
        breadcrumb2:'My protected files',
        breadcrumb3:'File details',
        fileSearch:'Search by files',
        chooseFilePlaceholder:'Please select files',
        chooseFileBtn:'Select files',
        clearFileBtn:'Clear',
        otherSearch:'Other search types',
        applyDate:'Application date',
        nameOrRemarkPlaceholder:'File names or remarks',
        storeTypePlaceholder:'Authentication Modes',
        desensitization:'Non-storage',
        saveFile:'Stored',
        fileTypePlaceholder:'File Types',
        applyAccountPlaceholder:"Account number",
        searchBtn:'Search',
        resetBtn:'Clear',
        emailVer:'The mailbox format is incorrect',
        emailEnter:'Enter your Email',
        updateSuccess:'Update successfully!',
        updateError:'Update failed!',
        downloadError:'Download failed!',
        varSlide:'Please verify the slider first.',
        sendEmailSuccess:'It has been sent to the mailbox. Please check it.',
        sendEmailError:'Failed to send mailbox',
        downloadTips:'The original is only allowed to be downloaded within the validity period. And expired or desensitized documents will be automatically filtered.',
        deleteChildMsg:'You have no permission to delete. Please contact the main account to delete it.',
        deleteSuccess:'Delete successfully！',
        selectBalance:'Abnormal balance',
        downTXT:'Please copy the download link below to Xunlei (which only supports Windows system. Please create a new download task in Xunlei and paste the address manually, otherwise it may not be recognized correctly) or other download tools for batch download. The download link is valid within one day, please download as soon as possible.',
        txtFileName:'Batch download address',
        maximum:'A maximum of 170 items can be selected.',
        tableHeader:{
            lable1:'File Name',
            lable2:'Time for issuance',
            lable3:'Account Number',
            lable4:'File Types',
            lable5:'Authentication Modes',
            lable6:'Remarks (labels)',
            lable7:'Operation',
            lable8:'Store expiration time',
        },
        tableChooseHeader:{
            chooseCount:'',
            unit:'items selected',
            downCertificate:'Download certificate',
            downOriginal:'Download original',
            deleteOriginal:'Delete original',
            batchRemarks:'Remarks',
            generateBills:'Generate bill'
        },
        tableOperate:{
            btn1:'View details',
            btn2:'Share',
            btn3:'Download certificate',
            btn4:'Download original',
            fileExpired:'The original has expired. Please renew it in time.',
            fileWillExpired:'The original will expire in less than one month.'
        },
        remarksDiaBox:{
            title:'Bath Marking',
            des:'Batch mark the selected files’s comment content',
            placeholder:'Please fill in the project name, label, keywords, etc. of this applicated document for the convenience of subsequent use.'
        },
        billDiaBox:{
            title:'Tips',
            choose:'',
            des:' files have been selected. Click “Confirm” to generate the bill.',
        },
        emialDiaBox:{
            title:'Send bill',
            email:'Mailbox',
            placeholder:'Please enter the receiving bill mailbox',
            slide:'Slide to verify'
        },
        downNoEnoughDiaBox:{
            title:'Tips',
            desParents:'The file still has unpaid storage charges, which can be downloaded only after payment.',
            desChild:'The file still has unpaid storage charges, which can be downloaded only after payment. Please contact your main account to recharge.',
            node1:'The storage has expired for',
            node2:'(day). It is estimated to consume',
            node3:'(timestamp) this time.'
        },
        downNoEnoughChildDiaBox:{
            desParents:'The file still has unpaid storage charges.Your timestamps have been used up. Please contact the main account for re-allocation.',
            node1:'The storage has expired for ',
            node2:'(day). It is estimated to consume ',
            node3:'(timestamp) this time.',
        },
        downEnoughDiaBox:{
            desParents:'The file still has unpaid storage charges, which can be downloaded only after payment.',
            node1:'The storage has expired for ',
            node2:'(day). It is estimated to consume ',
            node3:'(timestamp) this time.',
        },
        updateBalanceDiaBox:{
            des:'I have recharged successfully',
        },
        deleteDiaBox:{
            des:'After deletion, the original cannot be viewed and downloaded at any time. If you upload it again, the storage timestamp will be charged again. Are you sure to delete it?',
            tip:'Tips: The selected file contains non-storage authentication type. The original is only allowed to be downloaded and be automatically filtered.'
        },
        expiredType:{
            lable:'Storage expiration status',
            title1:'Unexpired (more than one month)',
            title2:'Expiring soon (less than one month)',
            title3:'Expired',
        },
        expiredTypeText:{
            title1:'Expiring soon',
            title2:'Expired',
        }

    },
    fileDetail:{
        certificate:'UniTrust Timestamp Authentication Certificate',
        certificateNum:'Certificate Number:',
        authenticationCode:'Timestamp authentication code:',
        issuedTime:'Timestamp issued time:',
        copy:'Copy',
        original:'Original document',
        dueDate:'Storage due date',
        expired:'The storage has expired',
        expiresDate:'Storage expiration',
        upOriginal:'Upload the original',
        noPreview:'Preview is not supported temporarily',
        applyInformation:'Applicant Information',
        applicant:'Applicant：',
        applicationAccount:'Application Account：',
        iDType:'ID Type：',
        idNum:'ID Number：',
        fileName:'File Name：',
        fileType:'File Type：',
        fileSize:'File Size：',
        statemen:'Applicant’s Statement：',
        remarks:'Remarks：',
        remarksPlaceholder:'Please enter the remarks',
        upfileLoading:'The file is uploading. Please wait patiently',
        fileInconsistent:'The file currently uploaded is inconsistent with the original, please upload again.',
        balanceEnoughDiaBox:{
            node1:'The file size is',
            node2:'which needs to consume',
            node3:'(timestamp).',
        },
        balanceNoEnoughDiaBox:{
            des1:'Timestamp balance is insufficient.',
            des2:'Timestamp balance is insufficient. Please contact your main account to recharge.',
        },
        balanceNoEnoughChildDiaBox:{
            des:'Your timestamps have been used up. Please contact the main account for re-allocation.',
        },
        uploadSuccess:'Uploaded successfully',
        deleteFail:'Delete failed!'
    },
    diaBtn:{
        confirm:'Confirm',
        cancel:'Cancel',
        recharge:'Go to recharge',
        know:'Confirm',
        download:'Continue downloading',
        upload:'Continue uploading',
        certification:'Go for authentication'
    },
    shareModal:{
        shareToast1:'Please upload the original before sharing',
        shareToast2:'Real-name authentication must be completed before sharing files',
        address:{
            title:'Share file',
            wxScan:'Share by Wechat scanning',
            shareLink:'Sharing link:',
            copyShareLink:'Copy link',
            copyShareFont:'Copy sharing',
            evidencePoster:'Evidence poster:',
            certificateSharing:'Certificate sharing:',
            download:'download',
            accessCode:'Access code: ',
            noAccessCode:'No access code',
            copy:'Copy',
            validityPeriod:'Validity until: ',
            longTerm:'Long term valid',
            stopeShare:'Stop sharing',
            lookNum:'Maximum viewing times allowed: ',
            confine:'unlimited',
            timesNum:'',
            downloadNum:'Maximum download times allowed: ',
            noDownload:'Not downloadable',
            downloadedNum:'downloaded times: ',
            close:'Close',
            shareCopywriting:{
                node1:'UniTrust TimeStamp user',
                node2:' Share evidence with you;',
                node3:',The access link is: ',
                node4:',accessCode: ',
            },
            stopShareSuccessful:'Stop sharing successfully!',
            syntheticPosters:'Composing posters!',
        },
        info:{
            title:'Share file',
            ts:'You can stop sharing at any time. After stopping, the sharing link will become invalid, and you need to reset it to share again.',
            validityPeriod:'Validity: ',
            placeholderPeriod:'Please select the valid period',
            space:'valid within',
            effectiveDays:'(day)',
            placeholderTime:'Please select the time',
            accessCode:{
                radio1:'No access code',
                radio2:'Customize',
                radio3:'Random',
            },
            placeholderAccessCode:'Enter 6-digit access code, only numbers or letters are supported',
            varAccessCode:'Please enter the access code',
            permission:{
                lable:'Authority: ',
                radio1:'only view',
                radio2:'view and download',
            },
            varPermission:'Please select the permission',
            placeholderLookCountLimit:'Please select the number of times to view',
            placeholderLookCountLimit2:'Please enter the number of times to view',
            placeholderDownloadCountLimit:'Please select the number of times to download',
            placeholderDownloadCountLimit2:'Please enter the number of times to download',
            varDownloadCountLimit:'Each evidence can be downloaded up to 10 times',
            downCeiling:'Each evidence can be downloaded up to 10 times',
            downloaded:'Downloaded times: ',
            generateShare:'Set sharing',
            longTerm:'Long term valid',
            customDate:'Custom due date',
            customTimes:'Custom times',
            NoRestriction:'unlimited',
            shareSuccessful:'Share successfully! '
        },
    }
}