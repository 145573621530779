<template>
    <div class="modal">
        <el-dialog
            title="提交接入申请"
            :visible.sync="visibleOne"
            :close-on-click-modal="false"
            :before-close="closeOne"
            class="myDialog"
        >
            <el-row type="flex" justify="start">
                <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4" class="dialogInfo">申请目的：</el-col>
                <el-col :xs="18" :sm="20" :md="20" :lg="20" :xl="20" class="chooseList">
                    <el-button
                        v-for="(item, index) in btnList"
                        :key="index"
                        :class="(index === checkIndex || applyId === item.applyId)?'btnInfo checked':'btnInfo'"
                        @click="changeListBtn(item, index)"
                    >
                        {{ item.name }}
                    </el-button>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="(visibleOne = false,resetForm(),$emit('onClose'))">取 消</el-button>
                <el-button type="primary" @click="applyDialogOk">下一步</el-button>
            </span>
        </el-dialog>
        
        <el-dialog
            title="提交接入申请"
            :visible.sync="visibleSec"
            :close-on-click-modal="false"
            :before-close="closeSec"
            class="myDialog"
        >
            <el-row type="flex" justify="center">
                <el-col :span="24">
                    <el-form
                        :rules="rules"
                        ref="form"
                        :model="form"
                    >
                        <el-form-item label="您的身份" prop="style">
                            <el-radio-group v-model="form.style">
                                <el-radio  label="1" style="margin-right:30px">个人</el-radio >
                                <el-radio  label="0">企业</el-radio >
                            </el-radio-group>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="prevOne">上一步</el-button> -->
                <el-button type="primary" @click="applyDialogOkSec">下一步</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="提交接入申请"
            :visible.sync="visibleThird"
            :close-on-click-modal="false"
            :before-close="closeThird"
            class="myDialog"
        >
            <el-row class="form2">
                <p class="centerP">很快，我们的专业顾问会与您联系。</p>
                <el-col :span="24">
                    <el-form
                        :rules="rules"
                        ref="form2"
                        :model="form2"
                        class="subform"
                    >
                        <el-form-item label=" " label-width="20px" prop="enterpriseName" v-if="form.style == '0'">
                            <el-input placeholder="请输入企业名称" v-model="form2.enterpriseName">
                            </el-input>
                        </el-form-item>
                        <el-form-item label=" " label-width="20px" prop="name">
                            <el-input placeholder="请输入联系人姓名" v-model="form2.name">
                            </el-input>
                        </el-form-item>
                        <el-form-item label=" " label-width="20px" prop="phone">
                            <el-input placeholder="请输入联系人手机号" v-model="form2.phone">
                            </el-input>
                        </el-form-item>
                        <el-form-item label=" " label-width="20px" prop="verify2">
                            <el-input
                                placeholder="请输入联系人手机号"
                                v-model="form2.verify2"
                                v-show="false"
                            >
                            </el-input>
                            <Verification @verification="verification" ref="Verification"/>
                        </el-form-item>
                        <div class="yzm-item">
                            <el-form-item
                                label=" "
                                label-width="20px"
                                prop="captchaCode"
                            >
                                <el-input
                                    v-model="form2.captchaCode"
                                    placeholder="短信验证码"
                                ></el-input>
                            </el-form-item>
                            <span
                                :disabled="disabled"
                                @click="getVer"
                                >{{ yzmtext }}</span
                            >
                        </div>
                        <el-form-item label=" " label-width="20px" prop="num">
                            <el-input placeholder="使用量" v-model="form2.num">
                            </el-input>
                        </el-form-item>
                        <el-form-item label=" " label-width="20px" prop="description">
                            <el-input type="textarea" @input="fontNumber" placeholder="请输入需求描述（不多于200字）" :maxLength="200" :rows="4" v-model="form2.description"/>
                            <span style="float:right;margin-bottom:-34px">{{ fontNum }}/200</span>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="backSec">上一步</el-button>
                <el-button type="primary" :loading="btnLoading" @click="applyDialogOkThird">提交</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import Verification from "@/components/Verification/dockingV";
var interval=null;
import {sendSmsCode,sendDingDing2} from "@/api/tsaService";
export default {
    data() {
        const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        let validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号"));
            } else {
                if (!reg.test(value)) {
                    callback(new Error("请输入正确手机号"));
                } else {
                    callback();
                }
            }
        };
        let verifyPass = (rule, value, callback) => {
            if (!this.verify) {
                callback(new Error("请滑块验证"));
            } else {
                callback();
            }
        };
        let numPass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入使用量"));
            } else {
                if (!/^[1-9]\d*$/.test(value)) {
                callback(new Error("请输入正整数"));
            } else {
                callback();
            }
            }
            
        };

        return {
            visibleOne:false,
            visibleSec:false,
            visibleThird:false,
            btnList: [
                {name: "知识产权", applyId: 998081},
                {name: "邮件认证", applyId: 998049},
                {name: "电子合同", applyId: 998113},
                {name: "项目招标", applyId: 998145},
                {name: "区块链平台", applyId: 998177},
                {name: "其他", applyId: 998209}
            ],
            form:{
                style:''
            },
            form2: {
                enterpriseName:"",
                phone: "",
                verify2: "",
                captchaCode: "",
                description: "",
                name: "",
                num:''
            },
            rules: {
                style: [
                    {
                        required: true,
                        message: "请选择身份",
                        trigger: "change"
                    }
                ],
                enterpriseName: [
                    {
                        required: true,
                        message: "请输入企业名称",
                        trigger: "blur"
                    }
                ],
                name: [
                    {
                        required: true,
                        message: "请输入联系人姓名",
                        trigger: "blur"
                    }
                ],
                phone: [
                    {
                        required: true,
                        trigger: "blur",
                        validator: validatePass
                    }
                ],
                num:[
                    {
                        required: true,
                        trigger: "blur",
                        validator: numPass
                    },
                ],
                description: [
                    {
                        required: true,
                        message: "请输入需求描述",
                        trigger: "blur"
                    }
                ],
                captchaCode: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "change"
                    }
                ],
                verify2: [
                    {
                        required: true,
                        trigger: "change",
                        validator: verifyPass
                    }
                ]
            },
            checkIndex: "",
            applyId: "998081",
            yzmtext: "获取验证码",
            verify:false,
            currentTime: 60,
            disabled: false,
            fontNum:0,
            btnLoading:false,
        };
    },

    props: {
        show: {
            type: Boolean,
            default: () => false,
        },
    },

    components: {Verification},

    computed: {},

    watch: {
        show(val){
            this.visibleSec=val;
        },
    },

    created() {},

    mounted() {},

    methods: {
        fontNumber(e) {
            this.fontNum = e.length;
        },
        applyDialogOk() {
            if (this.applyId === "") {
                this.$message.warning("请选择申请目的！");
                return false;
            } else {
                this.visibleOne = false;
                this.visibleSec = true;
            }
        },
        changeListBtn(item, index) {
            this.checkIndex = index;
            this.applyId = item.applyId;
        },
        prevOne(){
            this.visibleSec = false;
            this.visibleOne = true;
        },
        applyDialogOkSec(e) {
            e.preventDefault();
            let that = this;
            this.$refs.form.validate(valid => {
                if (valid) {
                    that.visibleThird = true;
                    that.visibleSec = false;
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        applyDialogOkThird(e) {
            e.preventDefault();
            let that = this;
            this.$refs.form2.validate(async valid => {
                if (valid) {
                    this.btnLoading = true;
                    let {phone, enterpriseName, name, captchaCode, description,num} = that.form2;
                    let {style} = that.form;
                    let obj={
                        phone,
                        applyId: this.applyId,
                        enterpriseName,
                        name,
                        style,
                        captchaCode,
                        description,
                        from: 0,
                        num
                    }
                    try{
                        let res = await sendDingDing2(obj);
                        if (res.code === 200) {
                            that.form2 = {
                                name: "",
                                phone: "",
                                enterpriseName: "",
                                verify2: "",
                                captchaCode: "",
                                description: '',
                                num:''
                            };
                            that.$message.success("申请成功！");
                            that.$refs["form"].resetFields();
                            that.$refs["form2"].resetFields();
                            that.$refs.Verification.resetNvc();
                            that.resetForm();
                            this.$emit('onClose');
                        } else {
                            this.$message.warning(res.data.message);
                        }
                    }catch (error) {
                        this.$message.warning(error.message);
                    }
                    that.btnLoading = false;
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        backSec() {
            this.visibleThird = false;
            this.visibleSec = true;
            this.$refs.Verification.resetNvc();
            clearInterval(interval);
            this.$refs["form2"].resetFields();
        },
        async verification(data) {
            let that = this;
            if (data) {
                this.verify=data.verify;
                this.$refs["form2"].clearValidate(["verify2"]);
                let { phone } = that.form2;
                if (!phone) {
                    that.$message.warning("手机号不能为空");
                    that.verify=false;
                    that.$refs.Verification.resetNvc();
                    return false;
                }
                let reg = /^1[3456789]\d{9}$/;
                if (!reg.test(phone)) {
                    that.$message.warning("手机号格式不正确");
                    that.verify=false;
                    that.$refs.Verification.resetNvc();
                    return false;
                }
                that.getCode();
                try {
                    let res = await sendSmsCode({
                        nvc: data.nvc,
                        phone: that.form2.phone
                    });
                    that.$message.success(res.message);
                    that.verify = true;
                    that.form2.verify2 = "213";
                    that.$refs["form2"].clearValidate(["verify2"]);
                } catch (error) {
                    that.$message.warning(error.message);
                    that.$refs.Verification.resetNvc();
                    clearInterval(interval);
                    that.yzmtext = "获取验证码";
                    that.disabled = false;
                    that.verify = false;
                }
            }
        },
        getVer() {
            let {phone} = this.form2;
            let that = this;
            if (!phone) {
                that.$message.warning("手机号不能为空");
                return false;
            }
            let reg = /^1[3456789]\d{9}$/;
            if (!reg.test(phone)) {
                that.$message.warning("手机号格式不正确");
                return false;
            }
            if (!this.verify) {
                that.$message.warning("请先进行滑块验证");
                return false;
            }
            setTimeout(()=>{
                that.$refs.Verification.alicode();
            },0)
        },
        getCode() {
            var that = this;
            var currentTime = that.currentTime;
            that.disabled = true;
            interval = setInterval(function() {
                currentTime--;
                that.yzmtext = "(" + currentTime + "s)重新获取";
                if (currentTime <= 0) {
                clearInterval(interval);
                that.currentTime = 60;
                that.yzmtext = "获取验证码";
                that.disabled = false;
                }
            }, 1000);
        },
        closeOne(done){
            this.resetForm();
            this.$emit('onClose');
            done&&done();
        },
        closeSec(done){
            this.$refs["form"].resetFields();
            this.resetForm();
            this.$emit('onClose');
            done&&done();
        },
        closeThird(done){
            this.$refs["form"].resetFields();
            this.$refs["form2"].resetFields();
            this.resetForm();
            this.$emit('onClose');
            done&&done();
        },
        //置空钉钉推送相关字段
        resetForm(){
            this.form.style='';
            this.monitorOne=false;
            this.visibleOne = false;
            this.visibleSec = false;
            this.visibleThird = false;
            this.disabled = false;
            this.yzmtext = "获取验证码";
            this.currentTime=60;
            this.verify = false;
            this.checkIndex = "";
            this.applyId = "998081";
            clearInterval(interval);
            this.form2.enterpriseName = "";
            this.form2.captchaCode = "";
        },
    },
};
</script>
<style lang='less' scoped>
.yzm-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    .el-form-item {
        width: 330px;
        margin-bottom: 0px;
    }
    span {
        width: 148px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        background: #fff;
        color: #3f7df7;
        cursor: pointer;
        border: 1px solid #3f7df7;
        font-size: 14px;
        border-radius: 3px;
        &:hover {
            background: #3f7df7;
            color: #fff;
        }
        &[disabled] {
            color: #fff;
            background: #bcbcbc;
            pointer-events: none;
            cursor: inherit;
            border-color: #bcbcbc;
        }
    }
}
/deep/.form2 .el-form-item__label{
    height: 40px;
    padding:0;
    &::after{
        display: none;
    }
}
.myDialog{
    /deep/.el-dialog{
        max-width:530px;
    }
    /deep/.el-dialog__header{
        padding:16px 24px;
        border-bottom: 1px solid #e8e8e8;
        .el-dialog__title{
            font-size: 16px;
            line-height: 22px;
            color:#111;
        }
    }
    /deep/.el-dialog__footer{
        padding: 10px 16px;
        border-top: 1px solid #e8e8e8;
    }
    /deep/.el-dialog__body{
        padding: 24px;
        .btnInfo {
            margin-bottom: 20px;
            width: 98px;
            height: 36px;
            border: 1px solid #d0d0d0;
            border-radius: 18px;
            line-height: 36px;
            padding: 0;
            margin-left: 0;
            margin-right: 15px;
        }
        .checked {
            background: #e8efff;
            border: 1px solid #3758ff;
            border-radius: 18px;
            color: #3758ff;
        }
        .centerP {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-bottom: 30px;
            text-align: center;
        }
        .btnCode {
            padding: 9px 24px;
            margin-right: 0px;
            font-size: 12px;
        }
        .el-input__inner{
            height: 36px;
            line-height: 36px;
        }
    }
}

@media (max-width: 750px){
    .myDialog{
        /deep/.el-dialog{
            width: 96%;
        }
    }
}
</style>