<template>
    <div :class="fix?'header header-fix':'header'">
        <div class="top">
            <div class="top-left">
                <div class="standard-time">
                    {{ time }} <b>{{ utc }}</b>
                </div>
            </div>
            <div class="top-center">
                <a href="javascript:;" class="login" @click="onLogin" v-if="!userInfo.loginName">
                    {{ $t('header.login') }}
                </a>
                <div class="user-info" v-else>
                    <dl>
                        <dt><img src="./images/avatar.png" alt="头像"></dt>
                        <dd class="user-name">{{ userInfo.loginName }}</dd>
                    </dl>
                    <ul class="drop-down">
                        <li v-for="item in userMenu" :key="item.name">
                            <a href="javascript:;" v-if="item.isMes" @click="goMessage">
                                {{ item.name }}
                                <b class="point" v-if="messageInfo.showRead"></b>
                            </a>
                            <a :href="item.link" :target="item.target" v-else>
                                {{ item.name }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="top-right" style="width:315px">
                <img class="phone" src="./images/400phone.png" alt="客服">
                <div class="customer">
                    <img src="./images/qrcode_icon.png" alt="客服">
                    <div class="bubbles">
                        <img class="qr-icon" src="https://evidence-public.oss-cn-beijing.aliyuncs.com/customerImages/2.png" alt="客服">
                        <p>扫码添加客服微信</p>
                    </div>
                </div>
                <a :href="item.link" :title="item.name" :target="item.target" v-for="item in navLink.topNavBar" :key="item.name">
                    {{item.name}}
                </a>
                <!-- <a :href="VUE_APP_TSAPLUS+'/about.html'" title="关于我们" target="_blank">关于我们</a>
                <a href="#" title="网站导航" target="_blank">网站导航</a>
                <a href="#" title="网站地图" target="_blank">网站地图</a>
                <a href="#" title="XML地图" target="_blank">XML地图</a> -->
            </div>
        </div>
        <div class="bot">
            <div class="nav-bar">
                <h1 class="logo">
                    <a href="#" title="可信时间戳知识产权保护平台">可信时间戳知识产权保护平台</a>
                </h1>
                <ul class="big-nav">
                    <template v-for="item in navLink.bigNavBar">
                        <li 
                            v-if="item.currentNav"
                            :key="item.name" 
                            :class="item.currentNav?'active':''" 
                            :id="item.currentNav?'activeNav':''"
                        >
                            <a 
                                :href="item.link" :title="item.name" :target="item.target" v-html="item.name"
                                :class="$i18n.locale==='en'?'en-font':''"
                            >
                            </a>
                        </li>
                        <li 
                            v-else
                            :key="item.name+'1'" 
                        >
                            <a 
                                v-if="item.nofollow"
                                rel="nofollow"
                                :href="item.link" :title="item.name" :target="item.target" v-html="item.name"
                                :class="$i18n.locale==='en'?'en-font':''"
                            >
                            </a>
                            <a 
                                v-else
                                :href="item.link" :title="item.name" :target="item.target" v-html="item.name"
                                :class="$i18n.locale==='en'?'en-font':''"
                            >
                            </a>
                        </li>
                    </template>
                    <div class="nav-hump" id="nav-hump"></div>
                </ul>
                <ul :class="$i18n.locale==='en'?'small-nav small-nav-en':'small-nav'">
                    <li class="white">
                        <strong @click="changeLang" v-if="$i18n.locale==='en'"><img src="./images/zh-icon1.png"></strong>
                        <strong @click="changeLang" v-else><img src="./images/en-icon1.png"></strong>
                    </li>
                    <li class="blue">
                        <strong @click="changeLang" v-if="$i18n.locale==='en'"><img src="./images/zh-icon2.png"></strong>
                        <strong @click="changeLang" v-else><img src="./images/en-icon2.png"></strong>
                    </li>
                    <li v-show="$i18n.locale==='zh'">
                        <a :href="navLink.samllNavBar[0].link" :title="navLink.samllNavBar[0].name" :target="navLink.samllNavBar[0].target">
                            <b></b>
                            {{ navLink.samllNavBar[0].name }}
                        </a>
                    </li>
                    <li>
                        <a :href="navLink.samllNavBar[1].link" :title="navLink.samllNavBar[1].name" :target="navLink.samllNavBar[1].target">
                            <b></b>
                            {{ navLink.samllNavBar[1].name }}
                        </a>
                    </li>

                    <!-- <li>
                        <a :href="navLink.samllNavBar[1].link" :title="navLink.samllNavBar[0].name" :target="navLink.samllNavBar[0].target">
                            <b></b>
                            {{ navLink.samllNavBar[0].name }}
                        </a>
                    </li> -->
                    <!-- <li v-for="item in navLink.samllNavBar.slice(1,4)" :key="item.name">
                        <a :href="item.link" :target="item.target" :title="item.name">{{ item.name }}</a>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { navContent } from './config/navContent'
import moment from "moment";
export default {
    name: 'tsa-header',
    props: {
        currentTime:{
            type: Number,
            default:() => {
                return null
            },
        },
        navContent: {
            type: Object,
            default: () => {
                return {}
            }
        },
        userInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },
        userMenu:{
            type: Array,
            default: () => {
                return []
            }
        },
        messageInfo:{
            type: Object,
            default: () => {
                return {
                    showRead:false,
                    link:process.env.VUE_APP_ACCOUNTUI+'/messageCenter/history?clientId=700012'
                }
            }
        }
    },
    data() {
        return {
            fix:false,
            winScroll:'',
            time: "国家授时中心标准时间",
            utc: "",
            timer: null,
            deviceType:'big'
        };
    },
    components: {},
    watch: {
        currentTime: {
            handler(newValue) {
                if(newValue){
                    this.currentTime = newValue;
                    clearInterval(this.timer);
                    this.timer = null;
                    this.initTime();
                }
            },
            immediate: true
        }
    },
    computed: {
        VUE_APP_TSAPLUS(){
            return process.env.VUE_APP_TSAPLUS
        },
        navLink:{
            get(){
                if(JSON.stringify(this.navContent) == "{}"){
                    return navContent;
                }else{
                    return this.navContent;
                }
            },
			set(value){} 
        } 
    },
    created() {
    },
    mounted() {
        clearInterval(this.timer)
        this.timer = null;
        this.getNavBg();
        this.resize();
        window.removeEventListener('scroll', this.scroll);
        window.addEventListener("scroll", this.scroll);
        window.addEventListener('resize', this.getNavBg);
        window.addEventListener('resize', this.resize)
    },
    deactivated () {
        clearInterval(this.timer);
        this.timer = null;
        window.removeEventListener('scroll', this.scroll)
    },
    beforeDestroy () {
        clearInterval(this.timer)
        this.timer = null
        window.removeEventListener('scroll', this.scroll)
    },
    methods: {
        initTime() {
            var ntpDate = null,
                nowDate = null,
                td = null;
            let _this = this;
            initTime();

            function initTime() {
                ntpDate = _this.currentTime;
                nowDate = new Date().getTime();
                td = ntpDate - nowDate;
                nowDate = nowDate + td;
            }

            function getDate() {
                if (ntpDate == null) {
                    return;
                } else {
                    var now = new Date().getTime() + (td ? td : 0);
                    if (Math.abs(parseInt(now) - parseInt(nowDate)) > 1000) {
                        ntpDate = null;
                        _this.$emit('get-time')
                        initTime();
                    } else {
                        nowDate = now;
                        var time1 = moment(now).format("YYYY-MM-DD HH:mm:ss:SSS");
                        if (window['__PRERENDER_INJECTED']) {
                            return
                        }
                        _this.time = `国家授时中心标准时间${time1}`;
                        _this.utc = `(UTC${clientTimeZone()})`;
                    }
                }
            }

            function clientTimeZone() {
                var munites = new Date().getTimezoneOffset();
                var hour = parseInt(munites / 60);
                var munite = munites % 60;
                var prefix = "-";
                if (hour < 0 || munite < 0) {
                prefix = "+";
                hour = -hour;
                if (munite < 0) {
                    munite = -munite;
                }
                }
                hour = hour + "";
                munite = munite + "";
                if (hour.length == 1) {
                hour = "0" + hour;
                }
                if (munite.length == 1) {
                munite = "0" + munite;
                }
                return prefix + hour + ":" + munite;
            }
            Date.prototype.format = function(fmt) {
                var o = {
                "M+": this.getMonth() + 1, //月份
                "d+": this.getDate(), //日
                "h+": this.getHours(), //小时
                "m+": this.getMinutes(), //分
                "s+": this.getSeconds(), //秒
                "q+": Math.floor((this.getMonth() + 3) / 3), //季度
                S: this.getMilliseconds() //毫秒
                };
                if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(
                    RegExp.$1,
                    (this.getFullYear() + "").substr(4 - RegExp.$1.length)
                );
                }
                for (var k in o) {
                if (new RegExp("(" + k + ")").test(fmt)) {
                    fmt = fmt.replace(
                    RegExp.$1,
                    RegExp.$1.length == 1
                        ? o[k]
                        : ("00" + o[k]).substr(("" + o[k]).length)
                    );
                }
                }
                return fmt;
            };
            this.timer = setInterval(() => getDate(), 1);
        },
        scroll(){
            // 改变header颜色
            let winScroll =document.body.scrollTop || document.documentElement.scrollTop;
            if (winScroll > 90) {
                this.fix = true;
            } else {
                this.fix = false;
            }
        },
        getNavBg(){
            this.$nextTick(()=>{
                let item = document.getElementById("activeNav");
                let list = item.parentNode.childNodes;	
                let navIndex = [].indexOf.call(list,item);
                let X=item.offsetLeft;
                let Width=item.offsetWidth;
                let navHump=document.getElementById("nav-hump");
                let clientWidth=document.body.clientWidth || document.documentElement.clientWidth
                if (clientWidth > 1680) {
                    if(navIndex==0){//left减掉的值是css的宽度/2。
                        navHump.setAttribute('style','width:268px;left:'+(X+Width/2-134)+'px')
                    }else if(navIndex==1 || navIndex==3){
                        navHump.setAttribute('style','width:388px;left:'+(X+Width/2-194)+'px')
                    }else if(navIndex==2 || navIndex==4 || navIndex==5){
                        navHump.setAttribute('style','width:324px;left:'+(X+Width/2-162)+'px')
                    }else{
                        navHump.setAttribute('style','width:342px;left:'+(X+Width/2-171)+'px')
                    }
                } else {
                    if(navIndex==0){
                        navHump.setAttribute('style','width:234px;left:'+(X+Width/2-117)+'px')
                    }else if(navIndex==1 || navIndex==3){
                        navHump.setAttribute('style','width:334px;left:'+(X+Width/2-167)+'px')
                    }else if(navIndex==2 || navIndex==4 || navIndex==5){
                        navHump.setAttribute('style','width:282px;left:'+(X+Width/2-141)+'px')
                    }else{
                        navHump.setAttribute('style','width:298px;left:'+(X+Width/2-149)+'px')
                    }
                }
            })
        },
        goMessage(){
            if(this.messageInfo.showRead){
                window.location.href=this.messageInfo.link
            }else{
                window.open(this.messageInfo.link);
            }
        },
        onLogin () {
            console.log('aaa')
            this.$emit('on-onLogin')
        },
        changeLang(){
            if(this.$i18n.locale=='zh'){
                this.$i18n.locale='en'
                this.$setlocalStorage('language','en')
            }else{
                this.$i18n.locale='zh'
                this.$setlocalStorage('language','zh')
            }
        },
        resize () {
            if (document.body.clientWidth > 1680 || document.documentElement.clientWidth>1680) {
                this.deviceType = 'big'
            } else {
                this.deviceType = 'small'
            }
        },
    }
};
</script>

<style src="./css/header.less" lang="less" scoped></style>
