<template>
    <el-carousel
        :autoplay="true"
        :interval="5000"
        ref="varousel"
        class="banner"
        height="450px"
        arrow="never"
        trigger="click"
    >
        <el-carousel-item v-for="(item, index) in bannerInfo" :key="index">
            <el-row
                class="bgCommon"
                type="flex"
                justify="space-between"
                :style="{
                    backgroundImage: 'url(' + bannerInfo[index].img + ')',
                }"
            >
                <div class="bannerMain">
                    <el-col :span="24">
                        <div class="bannerTitle" v-html="item.title"></div>
                        <div class="bannerText" v-if="item.key==0">
                            <p v-html="item.text1"></p>
                            <p v-html="item.text2"></p>
                            <div :class="$i18n.locale==='zh'?'point':'point pointEn'">
                                <b></b>
                                <b></b>
                                <b></b>
                            </div>
                            <div :class="$i18n.locale==='zh'?'slogan':'sloganEn slogan'">
                                <span :style="$i18n.locale==='zh'?'margin-right:15px':''">{{ item.slogan1 }}</span>
                                <span>{{ item.slogan2 }}</span>
                            </div>
                        </div>
                        <div class="bannerText" v-else>
                            <p v-html="item.text1"></p>
                            <p v-html="item.text2"></p>
                            <p v-html="item.text3"></p>
                            <p v-html="item.text4"></p>
                        </div>
                        <div class="bannerBtn" @click="onLogin">
                            {{ item.btnInfo }}
                        </div>
                    </el-col>
                </div>

                <div class="bannerBot"></div>
            </el-row>
        </el-carousel-item>
    </el-carousel>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
      return {

      };
  },
  computed: {
      bannerInfo(){
          return [
               {
                  key:0,
                  title: this.$t("banner.sec0.title"),
                  text1: this.$t("banner.sec0.text1"),
                  text2: this.$t("banner.sec0.text2"),
                  text3: this.$t("banner.sec0.text3"),
                  text4: this.$t("banner.sec0.text4"),
                  slogan1:this.$t("banner.sec0.slogan1"),
                  slogan2:this.$t("banner.sec0.slogan2"),
                  btnInfo: this.$t("banner.sec0.btnInfo"),
                  img: require("@/assets/images/newIndex/banner0.jpg"),
              },
              {
                  title: this.$t("banner.sec1.title"),
                  text1: this.$t("banner.sec1.text1"),
                  text2: this.$t("banner.sec1.text2"),
                  text3: this.$t("banner.sec1.text3"),
                  text4: this.$t("banner.sec1.text4"),
                  btnInfo: this.$t("banner.sec1.btnInfo"),
                  img: require("@/assets/images/newIndex/banner2.png"),
              },
              {
                  title: this.$t("banner.sec2.title"),
                  text1: this.$t("banner.sec2.text1"),
                  text2: this.$t("banner.sec2.text2"),
                  text3: this.$t("banner.sec2.text3"),
                  btnInfo: this.$t("banner.sec2.btnInfo"),
                  img: require("@/assets/images/newIndex/banner1.png"),
              },
          ]
      }
  },
  created() {},
  mounted() {},
  methods: {
      onLogin() {
          this.$emit("onLogin");
      },
  },
};
</script>

<style scoped lang="less">
.banner {
    width: 100%;
    height: 450px;
    background: linear-gradient(
        270deg,
        rgba(26, 40, 86, 0.1) 0%,
        rgba(26, 40, 86, 0.2) 38%,
        rgba(26, 40, 86, 0.5) 76%,
        rgba(26, 40, 86, 0.3) 100%
    );
    position: relative;

    /deep/.bgCommon {
        width: 100%;
        height: 450px;
        background-size: cover;
        background: linear-gradient(
            270deg,
            rgba(26, 40, 86, 0.1) 0%,
            rgba(26, 40, 86, 0.2) 38%,
            rgba(26, 40, 86, 0.5) 76%,
            rgba(26, 40, 86, 0.3) 100%
        );
        background-repeat: no-repeat;
    }

    /deep/ .el-carousel__indicators {
        padding-bottom: 15px;
    }
    /deep/ .el-carousel__indicators > li {
        margin-right: 10px;
    }

    /deep/ .el-carousel__indicators li button {
        width: 32px;
        height: 4px;
    }

    /deep/ .el-carousel__indicators li.is-active button {
        width: 32px;
        height: 4px;
        background-color: #fff;
    }

    // /deep/ .ant-carousel .slick-dots-bottom {
    //   bottom: 32px;
    // }

    .bannerMain {
        width: 1200px !important;
        padding: 104px 32px 0 0px;
        color: #ffffff;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .bannerTitle {
            font-size: 32px;
            font-weight: 500;
        }

        .bannerText {
            font-size: 16px;
            font-weight: 400;
            margin: 16px 0 24px;

            p {
                margin-bottom: 4px;
            }
            .point{
                padding-left: 20px;
                display: flex;
                align-items: center;
                width: 290px;
                justify-content: left;
                margin:15px 0;
                text-align: center;
                b{
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    margin-right: 5px;
                    border-radius: 4px;
                    background: #fff;
                    &:last-child{
                        margin:0
                    }
                }
            }
            .pointEn{
                width:560px;
            }
            .slogan{
                text-align: center;
                width: 520px;
                padding-top: 10px;
                margin-top: 5px;
                font-size: 20px;
                border-top:1px solid #fff;
            }
            .sloganEn{
                width: auto;
            }
        }

        .bannerBtn {
            width: 129px;
            height: 46px;
            background: #3f7df7;
            border-radius: 4px;
            line-height: 46px;
            text-align: center;
            cursor: pointer;
        }
    }
}
</style>