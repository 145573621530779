export default {
    layout:'退出',
    message:'消息',
    sidebar:{
        logo:'可信时间戳<sup>®</sup>知识产权保护',
        accountBalance:'主账号余额',
        balance:'时间戳个数',
        ceiling:'当前账号上限',
        alreadyUse:'当前账号已使用',
        gobuy:'购买时间戳',
        rules:'计费规则',
    },
    customer:{
        title:'联系客服',
        phone:'电话咨询',
        wechat:'客服微信',
        workTime:'工作时间：周一至周五',

    },
    table:{
        noData:'暂无数据',
    },
    protectFile:{
        backText:'返回',
        breadcrumb1:'申请时间戳',
        breadcrumb2:'已保护文件',
        breadcrumb3:'文件详情',
        fileSearch:'按文件搜索',
        chooseFilePlaceholder:'请选择文件',
        chooseFileBtn:'选择文件',
        clearFileBtn:'清空文件',
        otherSearch:'按其他搜索',
        applyDate:'申请日期',
        nameOrRemarkPlaceholder:'文件名称或备注（标签）',
        storeTypePlaceholder:'认证方式',
        desensitization:'脱敏认证',
        saveFile:'存证',
        fileTypePlaceholder:'文件类型',
        applyAccountPlaceholder:"申请人账号",
        searchBtn:'搜索',
        resetBtn:'重置',
        emailVer:'邮箱格式不正确',
        emailEnter:'请输入邮箱',
        updateSuccess:'更新成功！',
        updateError:'更新失败！',
        downloadError:'下载失败!',
        varSlide:'请先进行滑块验证',
        sendEmailSuccess:'已发送到邮箱，请查收',
        sendEmailError:'发送邮箱失败',
        downloadTips:'仅支持有效期内原件下载（已到期/脱敏认证的文件自动过滤）',
        deleteChildMsg:'暂无权限删除，请联系主账号处理',
        deleteSuccess:'删除成功！',
        selectBalance:'查询余额异常',
        downTXT:'请将下面的下载链接复制到迅雷（仅支持Windows系统，请在迅雷中新建下载任务并手动粘贴地址，否则可能无法正确识别）或其他下载工具中进行批量下载，下载链接在一日内有效，请尽快下载',
        txtFileName:'批量下载地址',
        maximum:'最多选择170条',
        tableHeader:{
            lable1:'文件名称',
            lable2:'签发时间',
            lable3:'申请账号',
            lable4:'文件类型',
            lable5:'认证方式',
            lable6:'备注（标签）',
            lable7:'操作',
            lable8:'存储到期时间',
        },
        tableChooseHeader:{
            chooseCount:'已选择',
            unit:'项',
            downCertificate:'下载证书',
            downOriginal:'下载原件',
            deleteOriginal:'删除原件',
            batchRemarks:'备注',
            generateBills:'生成账单'
        },
        tableOperate:{
            btn1:'查看详情',
            btn2:'分享',
            btn3:'下载证书',
            btn4:'下载原件',
            fileExpired:'存证的原件已过期，请及时续费',
            fileWillExpired:'存证的原件即将过期，请及时续费'
        },
        remarksDiaBox:{
            title:'批量标记',
            des:'批量标记 选中文件的备注内容',
            placeholder:'请填写本次申请文件的项目名称、标签、关键词等，便于后续查找使用。'
        },
        billDiaBox:{
            title:'提示',
            choose:'已选中',
            des:'个文件，点击确定生成账单',
        },
        emialDiaBox:{
            title:'发送账单',
            email:'邮箱',
            placeholder:'请输入接收账单邮箱',
            slide:'滑动验证'
        },
        downNoEnoughDiaBox:{
            title:'提示',
            desParents:'该文件尚有未支付的存储费，需支付后方可下载',
            desChild:'该文件尚有未支付的存储费，需支付后方可下载，请联系主账号充值',
            node1:'存储已超期',
            node2:'天，本次预计消耗时间戳',
            node3:'个'
        },
        downNoEnoughChildDiaBox:{
            desParents:'该文件尚有未支付的存储费，您的时间戳已用完，请联系主账号再次分配',
            node1:'存储已超期',
            node2:'天，本次预计消耗时间戳',
            node3:'个',
        },
        downEnoughDiaBox:{
            desParents:'该文件尚有未支付的存储费，需支付后方可下载',
            node1:'存储已超期',
            node2:'天，本次预计消耗时间戳',
            node3:'个',
        },
        updateBalanceDiaBox:{
            des:'我已充值成功',
        },
        deleteDiaBox:{
            des:'删除后原件将无法随时查看和下载，再次上传将重新收取存储时间戳，确定删除么？',
            tip:'注：所选的文件包含脱敏认证类型，不支持删除原件，将自动过滤。'
        },
        expiredType:{
            lable:'存储到期状态',
            title1:'尚未到期（到期时间超过一个月）',
            title2:'即将到期（到期时间不足一个月）',
            title3:'已过期',
        },
        expiredTypeText:{
            title1:'即将到期',
            title2:'已过期',
        }
    },
    fileDetail:{
        certificate:'可信时间戳认证证书',
        certificateNum:'证书编号：',
        authenticationCode:'时间戳认证码：',
        issuedTime:'时间戳签发时间：',
        copy:'复制',
        original:'文件原件',
        dueDate:'存储到期日',
        expired:'存储已过期',
        expiresDate:'存储到期',
        upOriginal:'上传原件',
        noPreview:'暂不支持预览',
        applyInformation:'申请资料',
        applicant:'申请人：',
        applicationAccount:'申请账号：',
        iDType:'证件类型：',
        idNum:'证件号：',
        fileName:'文件名称：',
        fileType:'文件类型：',
        fileSize:'文件大小：',
        statemen:'申请人文件自述：',
        remarks:'备注：',
        remarksPlaceholder:'请输入备注',
        upfileLoading:'正在上传文件，请耐心等待',
        fileInconsistent:'当前上传的文件与原件不一致，请重新上传',
        balanceEnoughDiaBox:{
            node1:'本文件大小为',
            node2:'，存储需消耗可信时间戳',
            node3:'个',
        },
        balanceNoEnoughDiaBox:{
            des1:'时间戳余额不足',
            des2:'时间戳余额不足，请联系主账号充值',
        },
        balanceNoEnoughChildDiaBox:{
            des:'您的时间戳已用完，请联系主账号再次分配',
        },
        uploadSuccess:'上传成功',
        deleteFail:'删除失败！'
    },
    diaBtn:{
        confirm:'确定',
        cancel:'取消',
        recharge:'立即充值',
        know:'我知道了',
        download:'继续下载',
        upload:'继续上传',
        certification:'去认证'
    },
    shareModal:{
        shareToast1:'请先上传原件再进行分享',
        shareToast2:'分享文件须先完成实名认证',
        address:{
            title:'分享文件',
            wxScan:'微信扫码分享',
            shareLink:'分享链接：',
            copyShareLink:'复制分享链接',
            copyShareFont:'复制分享文案',
            evidencePoster:'证据海报：',
            certificateSharing:'证书分享：',
            download:'下载',
            accessCode:'访问码：',
            noAccessCode:'无需访问码',
            copy:'复制',
            validityPeriod:'有效期至：',
            longTerm:'长期有效',
            stopeShare:'停止分享',
            lookNum:'可查看次数：',
            confine:'不限',
            timesNum:'次',
            downloadNum:'可下载次数：',
            noDownload:'不可下载',
            downloadedNum:'已下载次数：',
            close:'关闭',
            shareCopywriting:{
                node1:'#可信时间戳 用户',
                node2:'与您分享证据；',
                node3:'，访问链接为：',
                node4:'，访问码：',
            },
            stopShareSuccessful:'停止分享成功！',
            syntheticPosters:'正在合成海报！',
        },
        info:{
            title:'分享证据',
            ts:'您可随时停止分享，停止后分享链接失效，再次分享需重新设置。',
            validityPeriod:'有效期:',
            placeholderPeriod:'请选择有效期',
            space:'',
            effectiveDays:'天内有效',
            placeholderTime:'请选择时间',
            accessCode:{
                radio1:'不需要访问码',
                radio2:'自定义',
                radio3:'随机生成',
            },
            placeholderAccessCode:'输入6位访问码，仅支持数字及字母',
            varAccessCode:'请选择访问码',
            permission:{
                lable:'权限:',
                radio1:'仅查看',
                radio2:'查看及下载',
            },
            varPermission:'请选择权限',
            placeholderLookCountLimit:'请选择可查看次数',
            placeholderLookCountLimit2:'输入可查看次数',
            placeholderDownloadCountLimit:'请选择可下载次数',
            placeholderDownloadCountLimit2:'请输入可下载次数',
            varDownloadCountLimit:'每个证据可下载上限为10次，请重新输入',
            downCeiling:'每个证据可下载上限为10次',
            downloaded:'已下载：',
            generateShare:'生成分享',
            longTerm:'长期有效',
            customDate:'自定义到期日',
            customTimes:'自定义次数',
            NoRestriction:'不限',
            shareSuccessful:'分享成功！'
        },
    }
}