const state = {
  IMAGE: ['png', 'gif', 'jpg', 'jpeg'],
  VIDEO: ["mp4", "3gp", "ogg", "webm"],
  AUDIO: ["ogg", "mp3", "wav"],
  shareFileType: '',
}
const mutations = {
  SET_SHAREFILETYPE(state, shareFileType) {
    state.shareFileType = shareFileType
  },
}
const actions = {
  SET_SHAREFILETYPE({ state, commit }, name) {
    let type = name.substring(name.lastIndexOf('.') + 1)
    if (state.IMAGE.includes(type)) {
      commit('SET_SHAREFILETYPE', 'IMAGE')
    } else if (state.VIDEO.includes(type)) {
      commit('SET_SHAREFILETYPE', 'VIDEO')
    } else if (state.AUDIO.includes(type)) {
      commit('SET_SHAREFILETYPE', 'AUDIO')
    } else {
      commit('SET_SHAREFILETYPE', 'OTHER')
    }
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
